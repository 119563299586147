import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";

const ToastifyMessage = ({
  message,
  position = "bottom-center",
  hideProgressBar = true,
  toastId = "",
  status = "success",
}) => {
  const className =
    status == "success" ? "bg-success text-white" : "bg-danger text-white";
  return (
    <React.Fragment>
      {toast(message, {
        position: position,
        hideProgressBar: hideProgressBar,
        className: className,
        progress: undefined,
        toastId: toastId,
      })}
      <ToastContainer autoClose={5000} limit={1} />
    </React.Fragment>
  );
};
ToastifyMessage.propTypes = {
  message: PropTypes.string.isRequired,
  position: PropTypes.string,
  hideProgressBar: PropTypes.bool,
  toastId: PropTypes.string,
  status: PropTypes.string,
};
export default ToastifyMessage;
