import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import IframeModal from "./IframeModal";
import { useDispatch, useSelector } from "react-redux";
import { getFileManager, getOfflineList } from "../../store/actions";
import LoadingBar from "../../Components/Common/LoadingBar";
import "./style.scss";
import { META_TITLE } from "../../helpers/constants";

const FileManager = () => {
  document.title = `Downloads || ${META_TITLE}`;
  const [openModal, setOpenModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const {
    filemanager = [],
    isFileManagerSuccess,
    isFileManagerLoading,
  } = useSelector((state) => ({
    filemanager: state.FileManager?.fileManager.data,
    isFileManagerSuccess: state.FileManager?.fileManager.success,
    isFileManagerLoading: state.FileManager?.fileManager.loading,
    isFileManagerHasError: state.FileManager?.fileManager.error,
  }));
  const onDownloadButtonClick = (url) => {
    setOpenModal(true);
    setIframeUrl(url);
  };
  const onCloseOfDownloadModalClick = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    !isFileManagerSuccess && dispatch(getFileManager());
  }, [isFileManagerSuccess]);
  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const filteredData =
    filemanager.length >= 0
      ? filemanager?.filter((item) =>
          Object.keys(item).some((key) =>
            String(item[key]).toLowerCase().includes(filter.toLowerCase()),
          ),
        )
      : [];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Downloads List" pageTitle="Downloads" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={3}>
                      <div className="download_wrapper">
                        <div className="download_filter">
                          <label>
                            <i></i>
                            <input
                              type="search"
                              value={filter}
                              onChange={handleChange}
                              placeholder="Type a keyword..."
                              aria-controls="DataTables_Table_2"
                            />
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2">
                    {isFileManagerLoading && <LoadingBar />}
                    {isFileManagerSuccess && (
                      <>
                        <Col
                          sm={2}
                          className="border p-3 text-center mb-1 me-1"
                        >
                          <p>{`Download Offline List`}</p>
                          <Button
                            color="primary"
                            onClick={() =>
                              dispatch(
                                getOfflineList("Offline-Donor-Finder.zip"),
                              )
                            }
                          >
                            Download{" "}
                          </Button>
                        </Col>
                        {filteredData.map((singleFile, index) => (
                          <Col
                            sm={2}
                            className="border p-3 text-center mb-1 me-1"
                            key={index}
                          >
                            <p>{`${singleFile?.title}`}</p>
                            <Button
                              color="primary"
                              onClick={() =>
                                onDownloadButtonClick(
                                  `${singleFile?.permalink}`,
                                )
                              }
                            >
                              Download{" "}
                            </Button>
                          </Col>
                        ))}
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {openModal && iframeUrl != "" && (
          <IframeModal
            show={openModal}
            onCloseClick={onCloseOfDownloadModalClick}
            srcContent={iframeUrl}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default FileManager;
