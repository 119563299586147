import React, { forwardRef } from "react";
import "./print.scss";
import { Col, Container, Row, Table } from "reactstrap";
import { formatINR } from "../helpers/utility";
import PropTypes from "prop-types";
import { isEmpty, size, countBy, uniq } from "lodash";
import { META_TITLE } from "../helpers/constants";

const BookEntryPaymentStatus = forwardRef((props, ref) => {
  document.title = `Book Entry Payment Status || ${META_TITLE}`;
  const { dataToPrint, requestedData } = props;
  const { donorsAsPerBook, year, newDonors } = dataToPrint;
  const multipleAmountFormating = (paidAmount) => {
    return paidAmount.map((item) => formatINR(item));
  };
  const renderTable = (data) => (
    <Table className="table mb-0 table-bordered page-break">
      <thead>
        <tr className="bg-dark text-white">
          <td className="text-start fw-bold" style={{ width: "100px" }}>
            பதிவு எண்
          </td>
          <td className="text-start fw-bold">முகவரி</td>
          <td className="text-start fw-bold">நிலை</td>
        </tr>
      </thead>
      <tbody>
        {data?.map(
          (
            {
              donorId,
              donorPrintNameInBook_ta,
              donorPhone,
              featuredLocality_ta,
              isPaid,
              pincode,
              paidProducts,
              productsListedInBook,
              paidType,
              paidAmount,
              paidForYears = [],
            },
            key,
          ) => (
            <tr key={`${key}`}>
              <td className="text-start">
                {`${donorId}`}
                <br />
                {requestedData?.donor_type === "product" &&
                  featuredLocality_ta != "" &&
                  `(${featuredLocality_ta})`}
              </td>
              <td className="text-start">
                <p className="my-1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        donorPrintNameInBook_ta +
                        (pincode > 0 ? ", " + pincode : "") +
                        (donorPhone && donorPhone.trim() !== ""
                          ? ", செல்:- " + donorPhone
                          : ""),
                    }}
                  ></span>
                </p>
                <p className="my-1">
                  <span>
                    <strong className="fw-bold">
                      பணம் செலுத்திய பொருட்கள்:
                    </strong>{" "}
                    {`${!isEmpty(paidProducts) ? paidProducts : "--"}`} /&nbsp;
                    {`${
                      !isEmpty(paidAmount)
                        ? multipleAmountFormating(paidAmount)
                        : "--"
                    }`}{" "}
                    /&nbsp;
                    {`${!isEmpty(paidType) ? uniq(paidType) : "--"}`}
                  </span>
                  <br />
                  <span>
                    <strong className="fw-bold">
                      புத்தகத்தில் பட்டியலிட்டுள்ள பொருட்கள்:
                    </strong>{" "}
                    {`${productsListedInBook}`}
                  </span>
                  <br />
                  <span>
                    <strong className="fw-bold">செலுத்தப்பட்ட ஆண்டுகள்:</strong>{" "}
                    {`${isEmpty(paidForYears) ? "--" : uniq(paidForYears)}`}
                  </span>
                </p>
              </td>
              <td className="text-center">
                {isPaid ? (
                  <div className="circle success">
                    <i className="ri-check-double-line"></i>
                  </div>
                ) : (
                  <div className="circle danger">
                    <i className="ri-close-fill"></i>
                  </div>
                )}
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
  return (
    <React.Fragment>
      <div ref={ref}>
        <Container fluid>
          <Row className="gy-5">
            <Col lg={12}>
              <h3 className="my-2">சுருக்கம்</h3>
              <Table className="table my-2 table-bordered page-break">
                <tbody>
                  <tr className="bg-dark text-white fw-bold text-center">
                    <td>ஆண்டு</td>
                    <td>
                      புத்தகத்தில் பட்டியலிடப்பட்டுள்ள மொத்த நன்கொடையாளர்கள்
                    </td>
                    <td>பணம் செலுத்திய நன்கொடையாளர்கள்</td>
                    <td>பணம் செலுத்தாத நன்கொடையாளர்கள்</td>
                    <td>புதிதாக பணம் செலுத்திய நன்கொடையாளர்கள்</td>
                  </tr>
                  <tr className="text-center">
                    <td>{year}</td>
                    <td>{size(donorsAsPerBook)}</td>
                    <td>{countBy(donorsAsPerBook, "isPaid")?.true || 0}</td>
                    <td>{countBy(donorsAsPerBook, "isPaid")?.false || 0}</td>
                    <td>{size(newDonors)}</td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="text-muted">
                      {`${JSON.stringify(requestedData)}`}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3 className="my-2">நன்கொடையாளர்கள் தொகை செலுத்திய விவரங்கள்</h3>
              {renderTable(donorsAsPerBook)}
              <h3 className="my-2">புதிதாக பணம் செலுத்திய நன்கொடையாளர்கள்</h3>
              {renderTable(newDonors)}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
});
BookEntryPaymentStatus.displayName = "BookEntryPaymentStatus";

BookEntryPaymentStatus.propTypes = {
  dataToPrint: PropTypes.object.isRequired,
  isLocalityRequired: PropTypes.bool.isRequired,
  requestedData: PropTypes.object.isRequired,
};

export default BookEntryPaymentStatus;
