import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, Table, Button, Badge } from "reactstrap";
import { getDonorReceipts } from "../../store/actions";
import { first, map, sum, isEmpty, size, filter } from "lodash";
import SingleReceipt from "../../Prints/SingleReceipt";
import LoadingBar from "../../Components/Common/LoadingBar";
import moment from "moment";
import PropTypes from "prop-types";

const UserReceipts = ({
  openModal,
  donorid,
  postalAddress,
  billingAddress,
  openViewModalOnClickOnClose,
}) => {
  const dispatch = useDispatch();
  const SingleReceiptRef = useRef();
  const [dataToPrint, setDataToPrint] = useState({});
  const [readyForPrint, setReadyForPrint] = useState(false);
  const { donorReceipts, isDonorReceiptsLoading } = useSelector((state) => ({
    donorReceipts: state.Donors.donorReceipts.data,
    isDonorReceiptsLoading: state.Donors.donorReceipts.loading,
    isDonorReceiptsApiSucccess: state.Donors.donorReceipts.success,
  }));

  useEffect(() => {
    openModal && dispatch(getDonorReceipts(donorid));
  }, [donorid, openModal]);

  useEffect(() => {
    !isEmpty(dataToPrint) && setReadyForPrint(true);
  }, [dataToPrint]);

  const printReceiptOnclick = (
    invoiceNumber,
    products,
    totalAmount,
    invoiceDate,
  ) => {
    const dataToPrint = {
      invoiceNumber,
      products,
      donorid,
      billingAddress,
      totalAmount,
      invoiceDate,
    };
    setDataToPrint(dataToPrint);
    setTimeout(() => {
      handlePrint();
    }, 0);
  };
  const handlePrint = useReactToPrint({
    content: () => SingleReceiptRef.current,
  });
  const renderReceipts = () => {
    let rid = 0;
    return (
      <>
        {Object.entries(donorReceipts).map(([objectKey, items]) => {
          rid++;
          const invoiceDate = first(items)["invoiceDate"];
          const isInvoiceReversed = first(items)["isInvoiceReversed"];
          const totalAmount = sum(
            map(items, (item) => {
              return item.invoiceType == "Rupees" ? item.invoiceAmount : 0;
            }),
          );
          const productsCount = size(
            filter(items, (item) => item.invoiceType == "Products"),
          );
          return (
            <tr key={`${rid}`}>
              <td>{`${rid}`}</td>
              <td>
                {`${objectKey}`}{" "}
                {isInvoiceReversed == "Y" && (
                  <Badge color="danger" pill>
                    {" "}
                    Cancelled{" "}
                  </Badge>
                )}
              </td>
              <td>
                &#x20B9;{" "}
                {`${totalAmount.toFixed(2)}${
                  productsCount != 0
                    ? " + " + productsCount + " Product(s)"
                    : ""
                }`}
              </td>
              <td>{`${moment(invoiceDate).format("lll")}`}</td>
              <td>
                <Button
                  color="primary"
                  className="bg-gradient"
                  disabled={isInvoiceReversed == "Y"}
                  onClick={() => {
                    printReceiptOnclick(
                      objectKey,
                      items,
                      totalAmount,
                      invoiceDate,
                    );
                  }}
                >
                  {" "}
                  Print{" "}
                </Button>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={openModal} toggle={openViewModalOnClickOnClose}>
        <ModalHeader id="userReceipts" toggle={openViewModalOnClickOnClose}>
          Donor Receipts
        </ModalHeader>
        {!isDonorReceiptsLoading && (
          <>
            <div className="modal-body">
              <p className="pb-2">
                <strong>Donor ID:</strong> {donorid},{" "}
                <strong>Donor Address:</strong>{" "}
                <span
                  className="text-capitalize"
                  dangerouslySetInnerHTML={{ __html: postalAddress }}
                ></span>
              </p>
              <div className="table-responsive">
                <Table className="table align-middle table-nowrap table-striped-columns mb-0">
                  <thead className="table-light">
                    <tr>
                      <td>#</td>
                      <td>Receipt Number</td>
                      <td>Amount</td>
                      <td>Date</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>{renderReceipts()}</tbody>
                </Table>
              </div>
            </div>
            <div className="modal-footer">
              <Link
                to="#"
                className="btn btn-danger fw-medium"
                onClick={openViewModalOnClickOnClose}
              >
                <i className="ri-close-line me-1 align-middle"></i> Close
              </Link>
            </div>
            <div style={{ display: "none" }}>
              {readyForPrint && (
                <SingleReceipt
                  ref={SingleReceiptRef}
                  dataToPrint={dataToPrint}
                />
              )}
            </div>
          </>
        )}
        {isDonorReceiptsLoading && (
          <div className="modal-body">
            <LoadingBar />
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

UserReceipts.propTypes = {
  openModal: PropTypes.bool.isRequired,
  donorid: PropTypes.any,
  postalAddress: PropTypes.string.isRequired,
  billingAddress: PropTypes.string.isRequired,
  openViewModalOnClickOnClose: PropTypes.func.isRequired,
};

export default UserReceipts;
