import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { includes } from "lodash";
import { getLoggedUserRole } from "../../helpers/utility";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { DASHBOARD_LINKS } from "./DashBoardLinks";
import { META_TITLE } from "../../helpers/constants";
import { getPreferences } from "../../store/actions";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  document.title = `Dashboard || ${META_TITLE}`;
  const { userProfile } = useProfile();
  const [userLoggedIn] = useState(getLoggedUserRole());
  const dispatch = useDispatch();

  const greetingsMessage = () => {
    const hour = new Date().getHours();
    const welcomeTypes = ["Good Morning", "Good Afternoon", "Good Evening"];
    let welcomeText = "";
    if (hour < 12) welcomeText = welcomeTypes[0];
    else if (hour < 18) welcomeText = welcomeTypes[1];
    else welcomeText = welcomeTypes[2];
    return welcomeText;
  };
  useEffect(() => {
    dispatch(getPreferences("preferences"));
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12} className="mt-xs-3 mb-4">
              <h4 className="fs-16 mb-1">
                {greetingsMessage()}, {userProfile?.user.name}!
              </h4>
              <p className="text-muted mb-0">
                Please choose your option from the provided list below and
                proceed accordingly.
              </p>
            </Col>
            {DASHBOARD_LINKS?.map(
              ({ name, link, role = 0, description }, key) => {
                if (includes(role, userLoggedIn)) {
                  return (
                    <Col md={6} xl={3} key={key + 1} className="mb-3">
                      <div className="card-animate card h-100">
                        <div className="card-body">
                          <div className="d-flex align-items-end justify-content-between">
                            <div className="w-100">
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                {`${name}`}
                              </h4>
                              <p>{`${description}`}</p>
                              <Link
                                to={`${link}`}
                                className="bg-gradient w-100 btn btn-primary"
                              >
                                Go to {`${name}`} Page
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }
              },
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
