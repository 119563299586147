import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createUser, createUserReset } from "../../store/actions";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import Select from "react-select";
import PropTypes from "prop-types";

const UserForm = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [roles] = useState([
    { label: "User", value: 0 },
    { label: "Admin", value: 1 },
  ]);
  const [statuses] = useState([
    { label: "In-Active", value: 0 },
    { label: "Active", value: 1 },
  ]);
  const userFormInit = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role: 0,
      status: 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter Name of the user"),
      email: Yup.string().email().required("Please enter donor slug id"),
      password: Yup.string().required("Password is required"),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match",
      ),
      role: Yup.string().required("Please enter donor edtiror id"),
      status: Yup.string().required("Please enter donor edtiror id"),
    }),
    onSubmit: (values) => {
      dispatch(createUser(values));
    },
  });

  const {
    createUserData = [],
    isCreateUserSuccess,
    isCreateUserLoading,
    isCreateUserError,
  } = useSelector((state) => ({
    createUserData: state.profile?.createUser.data,
    isCreateUserSuccess: state.profile?.createUser.success,
    isCreateUserLoading: state.profile?.createUser.loading,
    isCreateUserError: state.profile?.createUser.error,
  }));

  useEffect(() => {
    return () => {
      userFormInit.resetForm();
      dispatch(createUserReset());
    };
  }, []);

  useEffect(() => {
    isCreateUserSuccess &&
      setTimeout(() => {
        userFormInit.resetForm();
        onClose();
      }, 3000);
  }, [isCreateUserSuccess]);

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={isOpen}>
        <ModalHeader id="newUser">New User</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            userFormInit.handleSubmit();
            return false;
          }}
          action="#"
        >
          <div className="modal-body">
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="name" className="form-label">
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter name"
                    autoComplete="off"
                    id="name"
                    name="name"
                    onChange={userFormInit.handleChange}
                    onBlur={userFormInit.handleBlur}
                    value={userFormInit.values.name || ""}
                    invalid={
                      userFormInit.touched.name && userFormInit.errors.name
                    }
                  />
                  {userFormInit.touched.name && userFormInit.errors.name ? (
                    <FormFeedback type="invalid">
                      {userFormInit.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="email" className="form-label">
                    Email <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    autoComplete="off"
                    id="email"
                    name="email"
                    onChange={userFormInit.handleChange}
                    onBlur={userFormInit.handleBlur}
                    value={userFormInit.values.email || ""}
                    invalid={
                      userFormInit.touched.email && userFormInit.errors.email
                    }
                  />
                  {userFormInit.touched.email && userFormInit.errors.email ? (
                    <FormFeedback type="invalid">
                      {userFormInit.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="password" className="form-label">
                    Password <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    autoComplete="off"
                    id="password"
                    name="password"
                    onChange={userFormInit.handleChange}
                    onBlur={userFormInit.handleBlur}
                    value={userFormInit.values.password || ""}
                    invalid={
                      userFormInit.touched.password &&
                      userFormInit.errors.password
                    }
                  />
                  {userFormInit.touched.password &&
                  userFormInit.errors.password ? (
                    <FormFeedback type="invalid">
                      {userFormInit.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="password_confirmation" className="form-label">
                    Confirm Password <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="password"
                    className="form-control"
                    placeholder="Enter confirm password"
                    autoComplete="off"
                    id="password_confirmation"
                    name="password_confirmation"
                    onChange={userFormInit.handleChange}
                    onBlur={userFormInit.handleBlur}
                    value={userFormInit.values.password_confirmation || ""}
                    invalid={
                      userFormInit.touched.password_confirmation &&
                      userFormInit.errors.password_confirmation
                    }
                  />
                  {userFormInit.touched.password_confirmation &&
                  userFormInit.errors.password_confirmation ? (
                    <FormFeedback type="invalid">
                      {userFormInit.errors.password_confirmation}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <Label htmlFor="role" className="form-label">
                    User Role <span className="text-danger">*</span>
                  </Label>

                  <Select
                    classNamePrefix="myOwnSelect"
                    value={roles.filter(
                      (option) => userFormInit.values.role === option.value,
                    )}
                    id="role"
                    name="role"
                    className={
                      userFormInit?.touched?.role && userFormInit?.errors?.role
                        ? "is-invalid"
                        : ""
                    }
                    onChange={(option) => {
                      userFormInit.setFieldValue("role", option.value);
                    }}
                    options={roles}
                    placeholder={"Select a Role"}
                  />
                  {userFormInit.touched.role && userFormInit.errors.role ? (
                    <FormFeedback type="invalid" className="d-block">
                      {userFormInit.errors.role}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <Label htmlFor="status" className="form-label">
                    Status <span className="text-danger">*</span>
                  </Label>
                  <Select
                    classNamePrefix="myOwnSelect"
                    value={statuses.filter(
                      (option) => userFormInit.values.status === option.value,
                    )}
                    id="status"
                    name="status"
                    className={
                      userFormInit?.touched?.status &&
                      userFormInit?.errors?.status
                        ? "is-invalid"
                        : ""
                    }
                    onChange={(option) => {
                      userFormInit.setFieldValue("status", option.value);
                    }}
                    options={statuses}
                    placeholder={"Select a Status"}
                  />
                  {userFormInit.touched.status && userFormInit.errors.status ? (
                    <FormFeedback type="invalid" className="d-block">
                      {userFormInit.errors.status}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => {
                userFormInit.resetForm();
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={isCreateUserLoading}
            >
              Add
            </Button>
          </div>
        </Form>
        {isCreateUserSuccess && (
          <ToastifyMessage message={`${createUserData?.message}`} />
        )}
        {isCreateUserError && (
          <ToastifyMessage
            status=""
            message={`Error: ${Object.values(createUserData?.message)}`}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

UserForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UserForm;
