import {
  GET_DONORS_DATA,
  GET_DONORS_DATA_SUCCESS,
  GET_DONORS_DATA_FAILED,
  RESET_DONORS_DATA,
  GET_SINGLE_DONOR,
  GET_SINGLE_DONOR_SUCCESS,
  GET_SINGLE_DONOR_FAILED,
  RESET_SINGLE_DONOR_DATA,
  GET_DONOR_RECEIPTS,
  GET_DONOR_RECEIPTS_SUCCESS,
  GET_DONOR_RECEIPTS_FAILED,
  ADD_DONOR,
  ADD_DONOR_SUCCESS,
  ADD_DONOR_FAILED,
  UPDATE_DONOR,
  UPDATE_DONOR_SUCCESS,
  UPDATE_DONOR_FAILED,
  UPDATE_DONOR_RESET,
  BOOK_ADDRESS_DONOR,
  BOOK_ADDRESS_DONOR_SUCCESS,
  BOOK_ADDRESS_DONOR_FAILED,
  BOOK_ADDRESS_DONOR_RESET,
  UPDATE_BOOK_ADDRESS_DONOR,
  UPDATE_BOOK_ADDRESS_DONOR_SUCCESS,
  UPDATE_BOOK_ADDRESS_DONOR_FAILED,
  UPDATE_BOOK_ADDRESS_DONOR_RESET,
  GENERATE_RECEIPTS,
  GENERATE_RECEIPTS_SUCCESS,
  GENERATE_RECEIPTS_FAILED,
  GENERATE_RECEIPTS_RESET,
  INVOICE_LISTS,
  INVOICE_LISTS_SUCCESS,
  INVOICE_LISTS_FAILED,
  INVOICE_LISTS_RESET,
  CANCEL_RECEIPTS,
  CANCEL_RECEIPTS_SUCCESS,
  CANCEL_RECEIPTS_FAILED,
  CANCEL_RECEIPTS_RESET,
  DELETE_DONOR,
  DELETE_DONOR_SUCCESS,
  DELETE_DONOR_FAILED,
  DELETE_DONOR_RESET,
  GET_INVOICE_RAW,
  GET_INVOICE_RAW_SUCCESS,
  GET_INVOICE_RAW_FAILED,
  GET_INVOICE_RAW_RESET,
  GET_INVOICE_NUMBER,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICE_NUMBER_FAILED,
  GET_INVOICE_NUMBER_RESET,
  GET_OFFLINE_LIST,
  GET_OFFLINE_LIST_SUCCESS,
  GET_OFFLINE_LIST_FAILED,
  GET_OFFLINE_LIST_RESET,
  GET_REJECT_LIST,
  GET_REJECT_LIST_SUCCESS,
  GET_REJECT_LIST_FAILED,
  GET_REJECT_LIST_RESET,
  GET_ONETIME_DONORS_LIST,
  GET_ONETIME_DONORS_LIST_SUCCESS,
  GET_ONETIME_DONORS_LIST_FAILED,
  GET_ONETIME_DONORS_LIST_RESET,
} from "./actionTypes";

const initialState = {
  donors: [],
  donor: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  donorReceipts: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  createDonor: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  updateDonor: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  printPostalDonors: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  bookPrepareDonors: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  updateBookPrepare: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  createReceipts: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  invoiceList: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  cancelInvoice: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  deleteDonor: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  invoiceRaw: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  invoiceSingle: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  offlineZip: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  rejectList: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  oneTimeDonoraList: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  loading: false,
  success: false,
  error: false,
};

const Donors = (state = initialState, action) => {
  switch (action.type) {
    case GET_DONORS_DATA:
      state = {
        ...state,
        loading: true,
        donors: [],
        success: false,
      };
      break;
    case GET_DONORS_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        donors: action.payload,
        success: true,
      };
      break;
    case GET_DONORS_DATA_FAILED:
      state = {
        ...state,
        donors: [],
        loading: false,
        error: true,
      };
      break;
    case RESET_DONORS_DATA:
      state = {
        ...state,
        success: false,
        error: false,
      };
      break;
    case GET_SINGLE_DONOR:
      state = {
        ...state,
        donor: {
          data: [],
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_SINGLE_DONOR_SUCCESS:
      state = {
        ...state,
        donor: {
          data: action.payload[0],
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_SINGLE_DONOR_FAILED:
      state = {
        ...state,
        donor: {
          loading: false,
          success: false,
          error: true,
        },
      };
      break;
    case RESET_SINGLE_DONOR_DATA:
      state = {
        ...state,
        donor: {
          data: [],
          loading: false,
          success: false,
          error: false,
        },
      };
      break;

    case GET_DONOR_RECEIPTS:
      state = {
        ...state,
        donorReceipts: {
          data: [],
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_DONOR_RECEIPTS_SUCCESS:
      state = {
        ...state,
        donorReceipts: {
          data: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_DONOR_RECEIPTS_FAILED:
      state = {
        ...state,
        donorReceipts: {
          loading: false,
          success: false,
          error: true,
        },
      };
      break;
    case ADD_DONOR:
      state = {
        ...state,
        createDonor: {
          data: action.data,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case ADD_DONOR_SUCCESS:
      state = {
        ...state,
        createDonor: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case ADD_DONOR_FAILED:
      state = {
        ...state,
        createDonor: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case UPDATE_DONOR:
      state = {
        ...state,
        updateDonor: {
          data: action.data,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case UPDATE_DONOR_SUCCESS:
      state = {
        ...state,
        updateDonor: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case UPDATE_DONOR_FAILED:
      state = {
        ...state,
        updateDonor: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case UPDATE_DONOR_RESET:
      state = {
        ...state,
        updateDonor: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case BOOK_ADDRESS_DONOR:
      state = {
        ...state,
        bookPrepareDonors: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case BOOK_ADDRESS_DONOR_SUCCESS:
      state = {
        ...state,
        bookPrepareDonors: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case BOOK_ADDRESS_DONOR_FAILED:
      state = {
        ...state,
        bookPrepareDonors: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case BOOK_ADDRESS_DONOR_RESET:
      state = {
        ...state,
        bookPrepareDonors: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case UPDATE_BOOK_ADDRESS_DONOR:
      state = {
        ...state,
        updateBookPrepare: {
          data: action.data,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case UPDATE_BOOK_ADDRESS_DONOR_SUCCESS:
      state = {
        ...state,
        updateBookPrepare: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case UPDATE_BOOK_ADDRESS_DONOR_FAILED:
      state = {
        ...state,
        updateBookPrepare: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case UPDATE_BOOK_ADDRESS_DONOR_RESET:
      state = {
        ...state,
        updateBookPrepare: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GENERATE_RECEIPTS:
      state = {
        ...state,
        createReceipts: {
          data: action.payload,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GENERATE_RECEIPTS_SUCCESS:
      state = {
        ...state,
        createReceipts: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GENERATE_RECEIPTS_FAILED:
      state = {
        ...state,
        createReceipts: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case GENERATE_RECEIPTS_RESET:
      state = {
        ...state,
        createReceipts: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case INVOICE_LISTS:
      state = {
        ...state,
        invoiceList: {
          data: [],
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case INVOICE_LISTS_SUCCESS:
      state = {
        ...state,
        invoiceList: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case INVOICE_LISTS_FAILED:
      state = {
        ...state,
        invoiceList: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case INVOICE_LISTS_RESET:
      state = {
        ...state,
        invoiceList: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case CANCEL_RECEIPTS:
      state = {
        ...state,
        cancelInvoice: {
          data: action.invoiceNumber,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case CANCEL_RECEIPTS_SUCCESS:
      state = {
        ...state,
        cancelInvoice: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case CANCEL_RECEIPTS_FAILED:
      state = {
        ...state,
        cancelInvoice: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case CANCEL_RECEIPTS_RESET:
      state = {
        ...state,
        cancelInvoice: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case DELETE_DONOR:
      state = {
        ...state,
        deleteDonor: {
          data: action.donorId,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case DELETE_DONOR_SUCCESS:
      state = {
        ...state,
        deleteDonor: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case DELETE_DONOR_FAILED:
      state = {
        ...state,
        deleteDonor: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case DELETE_DONOR_RESET:
      state = {
        ...state,
        deleteDonor: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;

    case GET_INVOICE_RAW:
      state = {
        ...state,
        invoiceRaw: {
          data: action.donorId,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_INVOICE_RAW_SUCCESS:
      state = {
        ...state,
        invoiceRaw: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_INVOICE_RAW_FAILED:
      state = {
        ...state,
        invoiceRaw: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case GET_INVOICE_RAW_RESET:
      state = {
        ...state,
        invoiceRaw: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GET_INVOICE_NUMBER:
      state = {
        ...state,
        invoiceSingle: {
          data: action.donorId,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_INVOICE_NUMBER_SUCCESS:
      state = {
        ...state,
        invoiceSingle: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_INVOICE_NUMBER_FAILED:
      state = {
        ...state,
        invoiceSingle: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case GET_INVOICE_NUMBER_RESET:
      state = {
        ...state,
        invoiceSingle: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GET_OFFLINE_LIST:
      state = {
        ...state,
        offlineZip: {
          data: action.donorId,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_OFFLINE_LIST_SUCCESS:
      state = {
        ...state,
        offlineZip: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_OFFLINE_LIST_FAILED:
      state = {
        ...state,
        offlineZip: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case GET_OFFLINE_LIST_RESET:
      state = {
        ...state,
        offlineZip: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GET_REJECT_LIST:
      state = {
        ...state,
        rejectList: {
          data: [],
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_REJECT_LIST_SUCCESS:
      state = {
        ...state,
        rejectList: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_REJECT_LIST_FAILED:
      state = {
        ...state,
        rejectList: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case GET_REJECT_LIST_RESET:
      state = {
        ...state,
        rejectList: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GET_ONETIME_DONORS_LIST:
      state = {
        ...state,
        oneTimeDonoraList: {
          data: [],
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_ONETIME_DONORS_LIST_SUCCESS:
      state = {
        ...state,
        oneTimeDonoraList: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_ONETIME_DONORS_LIST_FAILED:
      state = {
        ...state,
        oneTimeDonoraList: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case GET_ONETIME_DONORS_LIST_RESET:
      state = {
        ...state,
        oneTimeDonoraList: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Donors;
