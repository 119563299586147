import React, { forwardRef } from "react";
import hill from "../assets/images/kondarangi_hills_logo.png";
import shiva from "../assets/images/lord_shiva.png";
import { formatINR } from "../helpers/utility";

import "./print.scss";
import { Col, Container, Row, Table } from "reactstrap";
import PropTypes from "prop-types";

const ReportPrint = forwardRef((props, ref) => {
  const {
    dataToPrint: { total, data },
    dataOptions: { donors, year, product, product_type },
  } = props;
  return (
    <React.Fragment>
      <div ref={ref}>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Table
                className="table mb-0 table-borderless"
                style={{ border: "1px solid #000" }}
              >
                <tbody>
                  <tr>
                    <td className="text-center">
                      <img src={hill} />
                    </td>
                    <td className="text-center">
                      <div className="mb-3">உ</div>
                      <div className="fw-bold">
                        கொண்டறங்கி கீரனூர் <br /> அருள்மிகு மல்லிகார்ஜுன சுவாமி
                        திருக்கோவில்
                      </div>
                      <div className="mt-3 text-underline">
                        {year}க்கான அறிக்கை
                      </div>
                    </td>
                    <td className="text-center">
                      <img src={shiva} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <Table className="table table-bordered border-dark">
                        <tbody>
                          <tr className="bg-dark text-white">
                            <td>S.No.</td>
                            <td>Donor Name</td>
                            <td>Reg.Id</td>
                            <td>Product</td>
                            {/* <td>Date</td> */}
                            <td>Amount</td>
                          </tr>
                          {data?.map(
                            (
                              {
                                donorId,
                                donorName_ta,
                                productName_ta,
                                phone,
                                amount = 0,
                                quantity,
                                remarks,
                              },
                              key,
                            ) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>
                                  {`${donorName_ta}, ${phone}`} {`${remarks}`}
                                </td>
                                <td>{`${donorId}`}</td>
                                <td style={{ width: "100px" }}>
                                  {`${productName_ta}`} {`${quantity}`}
                                </td>
                                {/* <td>{`${moment(addedDate).format("lll")}`}</td> */}
                                <td>{`${formatINR(amount)}`}</td>
                              </tr>
                            ),
                          )}
                          <tr className="bg-dark">
                            <td colSpan={6} className="text-end">
                              <span className="text-white">{`Donors: ${donors}, Year: ${year}, Products: ${product}, Type: ${product_type}`}</span>{" "}
                              <h4>
                                <span className="text-white">
                                  {formatINR(total)}
                                </span>
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
});
ReportPrint.displayName = "ReportPrint";

ReportPrint.propTypes = {
  dataToPrint: PropTypes.array.isRequired,
  dataOptions: PropTypes.any.isRequired,
};

export default ReportPrint;
