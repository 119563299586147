import {
  GET_USERS_DATA,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_FAILED,
  RESET_USERS_DATA,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAILED,
  CREATE_NEW_USER_RESET,
  DO_ACTION,
  DO_ACTION_SUCCESS,
  DO_ACTION_FAILED,
  DO_ACTION_RESET,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: {},
  users: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  createUser: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  doAction: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_DATA:
      state = {
        ...state,
        users: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GET_USERS_DATA_SUCCESS:
      state = {
        ...state,
        users: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_USERS_DATA_FAILED:
      state = {
        ...state,
        users: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case RESET_USERS_DATA:
      state = {
        ...state,
        users: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case CREATE_NEW_USER:
      state = {
        ...state,
        createUser: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case CREATE_NEW_USER_SUCCESS:
      state = {
        ...state,
        createUser: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case CREATE_NEW_USER_FAILED:
      state = {
        ...state,
        createUser: {
          loading: false,
          success: false,
          error: true,
          data: action.failed?.data,
        },
      };
      break;
    case CREATE_NEW_USER_RESET:
      state = {
        ...state,
        createUser: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case DO_ACTION:
      state = {
        ...state,
        doAction: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case DO_ACTION_SUCCESS:
      state = {
        ...state,
        doAction: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case DO_ACTION_FAILED:
      state = {
        ...state,
        doAction: {
          loading: false,
          success: false,
          error: true,
          data: action.failed?.data,
        },
      };
      break;
    case DO_ACTION_RESET:
      state = {
        ...state,
        doAction: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
