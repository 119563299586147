import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUser, resetLoginFlag } from "../../store/actions";

import logoLight from "../../assets/images/logo_black_white.png";
import { META_TITLE } from "../../helpers/constants";

const Login = (props) => {
  const dispatch = useDispatch();
  const [userLogin] = useState([]);

  const { isLoginLoading } = useSelector((state) => ({
    isLoginLoading: state.Login.loading,
  }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter a valid email address"),
      password: Yup.string()
        .required("Please enter a password")
        .min(6, "Password must be at least 6 characters long"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });

  const { error } = useSelector((state) => ({
    error: state.Login.error?.data?.error,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetLoginFlag());
    }, 3000);
  }, [dispatch, error]);

  document.title = `Login || ${META_TITLE}`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-4 text-white">
                <div>
                  <img src={logoLight} alt="" />
                </div>
                <p className="mt-3 fs-15 fw-medium text-muted">
                  Dashboard for Mallikarjuna Swamy Temple, Kondarangi Keeranur
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="text-center mt-2">
                <p className="text-muted">
                  Sign in to continue to Adhi Shiva WebApp.
                </p>
              </div>
              {error && error ? <Alert color="danger"> {error} </Alert> : null}
              <div className="p-2 mt-4">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <div className="mb-3">
                    <Label htmlFor="email" className="form-label">
                      Email
                    </Label>
                    <Input
                      name="email"
                      className="form-control p-3"
                      placeholder="Enter email"
                      type="email"
                      autoComplete="off"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label" htmlFor="password-input">
                      Password
                    </Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type="password"
                        className="form-control p-3"
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-4">
                    <Button
                      color="primary"
                      className="w-100 p-2 fs-5"
                      type="submit"
                      disabled={
                        isLoginLoading ||
                        !(validation.isValid && validation.dirty)
                      }
                    >
                      {!isLoginLoading && "Sign In"}
                      {isLoginLoading && (
                        <>
                          <Spinner
                            size="sm"
                            type="grow"
                            className="flex-shrink-0 me-2"
                            role="status"
                          >
                            {" "}
                            Loading...{" "}
                          </Spinner>
                          Logging in...
                        </>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          className="position-absolute g-0"
          style={{ bottom: 0, overflow: "hidden", width: "97%" }}
        >
          <p className="mb-0 text-muted text-footer text-center pb-3">
            &copy; {new Date().getFullYear()} Adhi Shiva WebApp, Mallikarjuna
            Swamy Temple, Kondarangi Keeranur. Develop &amp; Maintain by
            Vijayakumar Selvaraj. Build Version{" "}
            {`${process.env.REACT_APP_VERSION}`}.{" "}
            <span
              className="badge border-info bg-info text-white"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_DOCUMENTATION_PATH}`,
                  "_blank",
                )
              }
            >
              Documenation
            </span>
          </p>
        </Container>
      </ParticlesAuth>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.any,
};

export default withRouter(Login);
