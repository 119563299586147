export const GET_DONOR_TYPES = "GET_DONOR_TYPES";
export const GET_DONOR_TYPES_SUCCESS = "GET_DONOR_TYPES_SUCCESS";
export const DONOR_TYPES_FAILED = "DONOR_TYPES_FAILED";

export const CREATE_DONOR_TYPES = "CREATE_DONOR_TYPES";
export const CREATE_DONOR_TYPES_SUCCESS = "CREATE_DONOR_TYPES_SUCCESS";
export const CREATE_TYPES_FAILED = "CREATE_TYPES_FAILED";

export const UPDATE_DONOR_TYPES = "UPDATE_DONOR_TYPES";
export const UPDATE_DONOR_TYPES_SUCCESS = "UPDATE_DONOR_TYPES_SUCCESS";
export const UPDATE_TYPES_FAILED = "UPDATE_TYPES_FAILED";
