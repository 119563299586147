import React, { useRef, useEffect, useState } from "react";
import $ from "jquery";
import ReactDOM from "react-dom/client";
import "./grid.scss";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

const Grid = ({
  columns,
  data,
  id,
  sortOrder = [0, "asc"],
  pageLength = 50,
  isLoading = false,
  ordering = true,
  columnDefs,
  buttons = "",
  enableServerSidePagination = false,
  paginateCallBack = () => {},
  enableServeSideSearch = enableServerSidePagination,
  serverSideSearchCallBack = () => {},
  defaultSearchValue = "",
}) => {
  const tableGridRef = useRef();
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  $.DataTable = require("datatables.net");
  useEffect(() => {
    const table = $(tableGridRef.current).DataTable({
      order: [sortOrder],
      columns,
      data: enableServerSidePagination ? data?.data : data,
      ordering,
      columnDefs,
      pageLength: pageLength,
      processing: isLoading,
      deferRender: true,
      bPaginate: !enableServerSidePagination,
      bInfo: !enableServerSidePagination,
      searching: !enableServeSideSearch,
      language: {
        search: "<i></i>",
        searchPlaceholder: "Type a keyword...",
      },
      initComplete: () => {
        if (!enableServeSideSearch && buttons !== "") {
          const $topBar = $("<div class='topBar me-3'>");
          $("div.dataTables_filter").prepend($topBar);
          ReactDOM.createRoot($topBar[0]).render(buttons);
        }
      },
    });
    return () => table.destroy();
  }, []);

  const passBackPageNumber = (rawUrl) => {
    if (rawUrl === null) return;
    const url = new URL(rawUrl);
    const searchParams = new URLSearchParams(url.search);
    paginateCallBack(searchParams.get("page"));
  };
  const passBackSearchCallBack = () => {
    serverSideSearchCallBack(searchValue);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <>
      {enableServeSideSearch && (
        <div className="dataTable_table_wrapper">
          <div className="dataTables_filter">
            <div className="row g-0">
              <div className="col-sm">{buttons}</div>
              <div className="col-sm">
                <div className="row">
                  <div className="col-sm">
                    <label className="w-100">
                      <i></i>
                      <input
                        type="search"
                        className=""
                        placeholder="Type a keyword & search..."
                        onChange={handleSearchChange}
                        value={searchValue}
                      />
                    </label>
                  </div>
                  <div className="col-sm-2">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={() => passBackSearchCallBack()}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="table-responsive dataTable_table_wrapper pb-2">
        <table
          id={id}
          ref={tableGridRef}
          className="table table-bordered table-hover"
        ></table>
        {enableServerSidePagination && (
          <div className="row g-0">
            <div className="col-sm">
              <div className="text-muted">
                Showing {data?.from} to {data?.to} of {data?.total} entries
              </div>
            </div>
            <div className="col-sm-auto">
              <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                {data?.links?.map(({ active, label, url }, key) => (
                  <li
                    className={`page-item ${url == null ? "disabled" : ""}`}
                    key={`${key}`}
                    style={{ cursor: url == null ? "default" : "pointer" }}
                    onClick={() => passBackPageNumber(url)}
                  >
                    <span
                      className={`page-link ${active ? "active" : ""}`}
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

Grid.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  id: PropTypes.string,
  sortOrder: PropTypes.array,
  pageLength: PropTypes.number,
  isLoading: PropTypes.bool,
  ordering: PropTypes.bool,
  columnDefs: PropTypes.array.isRequired,
  buttons: PropTypes.object,
};

export default Grid;
