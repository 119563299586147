import { isEmpty, map, uniq, filter } from "lodash";
import { useProfile } from "../Components/Hooks/UserHooks";
import moment from "moment";
import labels from "../locales/en.json";

import { SOFTWARE_STARTED_YEAR, FESTIVAL_COUNT_NUMBER } from "./constants";
export const getCachedLocalityForDropDown = (
  array = [],
  pick = "state",
  state = "",
  district = "",
  taluk = "",
) => {
  if (isEmpty(array)) return [];
  let out = [];
  switch (pick) {
    case "state": {
      out = map(uniq(map(array, pick)), (value) => ({ value, label: value }));
      break;
    }
    case "district": {
      out = map(uniq(map(filter(array, { state: state }), pick)), (value) => ({
        value,
        label: value,
      }));
      break;
    }
    case "taluk": {
      out = map(
        uniq(map(filter(array, { state: state, district: district }), pick)),
        (value) => ({ value, label: value }),
      );
      break;
    }
    case "locality": {
      let output = [];
      out = map(
        filter(array, { state: state, district: district, taluk: taluk }),
        (datum) => {
          const { id, village, postoffice, pincode } = datum;
          const label = `${village}, (Post Office: ${postoffice}), ${pincode}`;
          output.push({ value: id, label: label });
        },
      );
      out = output;
      break;
    }
  }
  return out;
};
export const getRawLocalityForDropDown = (
  array = [],
  pick = "state",
  state = "",
  district = "",
  taluk = "",
) => {
  if (isEmpty(array)) return [];
  let out = [];
  switch (pick) {
    case "state":
    case "district":
    case "taluk": {
      out = map(array, (value) => ({ value, label: value }));
      break;
    }
    case "locality": {
      let output = [];
      out = map(
        filter(array, { state: state, district: district, taluk: taluk }),
        (datum) => {
          const { id, village, postoffice, pincode } = datum;
          const label = `${village}, (Post Office: ${postoffice}), ${pincode}`;
          output.push({ value: id, label: label });
        },
      );
      out = output;
      break;
    }
  }
  return out;
};

export const prepareOptionsWithyears = (
  donor_type = "",
  extraFields = [],
  ignoreDefault = false,
) => {
  let array = !ignoreDefault
    ? [{ label: `All years ${donor_type} donors`, value: "all" }]
    : [];
  const currentYear = new Date().getFullYear();
  for (let year = SOFTWARE_STARTED_YEAR; year <= currentYear; year++) {
    let obj = {};
    obj.value = year;
    obj.label = `${year} ${donor_type} donors`;
    array.push(obj);
  }
  return [...array, ...extraFields];
};

export const formatINR = (amount) => {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
  return formatter.format(amount);
};

export const isAdminUser = () => {
  const { userProfile } = useProfile();
  return userProfile?.user?.user_role == 1;
};

export const getLoggedUserRole = () => {
  const { userProfile } = useProfile();
  return userProfile?.user?.user_role || 0;
};

export const festivalCount = (date) => {
  const getYear = moment(date, "YYYY/MM/DD").year();
  const numberToAdd = getYear - SOFTWARE_STARTED_YEAR;
  return FESTIVAL_COUNT_NUMBER + numberToAdd;
};

export const preparePostalAddress = ({
  donorName_en = "",
  donorAddress_en = "",
  village = "",
  post_office = "",
  pincode = "",
  taluk = "",
  district = "",
  state = "",
  donorPhone = "",
}) => {
  const branchOfficePostalNames = ["H.O", "S.O", "B.O"];
  const poles = ["East", "West", "North", "South"];
  const mobile =
    donorPhone && donorPhone.trim() !== "" ? `, Mobile: ${donorPhone}` : "";
  const doorNumbers = donorAddress_en != null ? `, ${donorAddress_en}` : "";
  const address = `${removeWordsFromArray(
    village,
    poles,
  )}, ${removeWordsFromArray(taluk, poles)}, ${district}, ${state}`;
  return `${donorName_en}${doorNumbers}, ${removeDuplicateWords(
    address,
  )},  <strong>PO:</strong> ${removeWordsFromArray(
    post_office,
    branchOfficePostalNames,
  )}, Pincode: ${pincode}${mobile}`;
};

function removeWordsFromArray(inputString, wordsArray) {
  const wordsToRemove = new Set(wordsArray.map((word) => word.toLowerCase()));
  const result = inputString
    .split(" ")
    .filter((word) => !wordsToRemove.has(word.toLowerCase()))
    .join(" ");

  return result;
}

function removeDuplicateWords(inputString) {
  const words = inputString.split(/\s+/);
  const uniqueWords = new Set();
  const resultArray = words.filter((word) => {
    const lowerCasedWord = word.toLowerCase();
    if (!uniqueWords.has(lowerCasedWord)) {
      uniqueWords.add(lowerCasedWord);
      return true;
    }
    return false;
  });
  return resultArray.join(" ");
}

export const isEligibleToPaySandhaThisYear = ({
  isSandhaPaidforCurrentYear = 0,
  SANDHA_ENABLE_BETWEEN_DATES = "",
  donorType = "",
}) => {
  const splitDates = SANDHA_ENABLE_BETWEEN_DATES.split("TO");
  const startDate = moment(splitDates[0], "DD-MM-YYYY").format("YYYY-MM-DD");
  const endDate = moment(splitDates[1], "DD-MM-YYYY").format("YYYY-MM-DD");
  const receiptsSandhaCount =
    isSandhaPaidforCurrentYear != null ? isSandhaPaidforCurrentYear : 0;
  return (
    moment().isBetween(startDate, endDate) &&
    receiptsSandhaCount == 0 &&
    donorType.includes("sandha")
  );
};

export const isEligibleToPayDonateThisYear = ({ donorType = "" }) => {
  return donorType.includes("donate");
};

export const isDonateAllowed = ({ SANDHA_ENABLE_BETWEEN_DATES = "" }) => {
  const splitDates = SANDHA_ENABLE_BETWEEN_DATES.split("TO");
  const startDate = moment(splitDates[0], "DD-MM-YYYY").format("YYYY-MM-DD");
  const endDate = moment(splitDates[1], "DD-MM-YYYY").format("YYYY-MM-DD");
  return moment().isBetween(startDate, endDate) || false;
};

export const disableDonorEdit = (mode = "") => {
  return !isAdminUser() && mode === "EDIT";
};

export const capitalizeFirstLetter = (str) => {
  return str
    .replace(/(^\w|[\s\W]\w)/g, function (match) {
      return match.toUpperCase();
    })
    .replace(/(?<=^\w|[\s\W]\w)\w/g, function (match) {
      return match.toLowerCase();
    });
};

export const usersRoleName = (roleNo = 0) => {
  const roles = {
    0: "Data Entry Operator",
    1: "Administrator",
    2: "Press",
    3: "Data Entry Operator",
    null: "Unknown",
  };
  return roles[roleNo === null ? 3 : roleNo];
};

export const label = (key = "default") => {
  return labels[key] || key;
};

export const createQueryString = (params) => {
  return Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join("&");
};
