import React from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import LoadingBar from "../../Components/Common/LoadingBar";
import { META_TITLE } from "../../helpers/constants";

const InSightsLoader = () => {
  document.title = `Loading || ${META_TITLE}`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <div className="col-md-6 col-xl-3">
                    <CardHeader className="card-animate card bg-primary">
                      <CardBody>
                        <LoadingBar />
                      </CardBody>
                    </CardHeader>
                  </div>
                  <div className="col-md-6 col-xl-3">
                    <CardHeader className="card-animate card">
                      <CardBody>
                        <LoadingBar />
                      </CardBody>
                    </CardHeader>
                  </div>
                  <div className="col-md-6 col-xl-3">
                    <CardHeader className="card-animate card">
                      <CardBody>
                        <LoadingBar />
                      </CardBody>
                    </CardHeader>
                  </div>
                </Row>
                <Row>
                  <Col xl={3}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          User Based Collection
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <LoadingBar />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={9}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Recent Receipts
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <LoadingBar />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InSightsLoader;
