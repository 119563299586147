import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_RESET,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_RESET,
} from "./actionTypes";

export const getProducts = (year) => {
  return {
    type: GET_PRODUCTS,
    year,
  };
};

export const getProductsSuccess = (products) => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: products,
  };
};

export const getProductsFailed = (error) => {
  return {
    type: GET_PRODUCTS_FAILED,
    error,
  };
};

export const createProduct = (dataToPass) => {
  return {
    type: CREATE_PRODUCT,
    dataToPass,
  };
};

export const createProductSuccess = (data) => {
  return {
    type: CREATE_PRODUCT_SUCCESS,
    data,
  };
};

export const createProductFailed = (error) => {
  return {
    type: CREATE_PRODUCT_FAILED,
    error,
  };
};

export const createProductReset = () => {
  return {
    type: CREATE_PRODUCT_RESET,
  };
};

export const updateProduct = (dataToPass) => {
  return {
    type: UPDATE_PRODUCT,
    dataToPass,
  };
};

export const updateProductSuccess = (data) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    data,
  };
};

export const updateProductFailed = (error) => {
  return {
    type: UPDATE_PRODUCT_FAILED,
    error,
  };
};

export const updateProductReset = () => {
  return {
    type: UPDATE_PRODUCT_RESET,
  };
};
