import {
  GET_FILEMANAGER,
  FILEMANAGER_SUCCESS,
  FILEMANAGER_FAILED,
} from "./actionTypes";

export const getFileManager = () => {
  return {
    type: GET_FILEMANAGER,
  };
};

export const getFileManagerSuccess = (data) => {
  return {
    type: FILEMANAGER_SUCCESS,
    data,
  };
};

export const getFileManagerFailed = (failed) => {
  return {
    type: FILEMANAGER_FAILED,
    failed,
  };
};
