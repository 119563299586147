export const GET_USERS_DATA = "GET_USERS_DATA";
export const GET_USERS_DATA_SUCCESS = "GET_USERS_DATA_SUCCESS";
export const GET_USERS_DATA_FAILED = "GET_USERS_DATA_FAILED";
export const RESET_USERS_DATA = "RESET_USERS_DATA";

export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_FAILED = "CREATE_NEW_USER_FAILED";
export const CREATE_NEW_USER_RESET = "CREATE_NEW_USER_RESET";

export const DO_ACTION = "DO_ACTION";
export const DO_ACTION_SUCCESS = "DO_ACTION_SUCCESS";
export const DO_ACTION_FAILED = "DO_ACTION_FAILED";
export const DO_ACTION_RESET = "DO_ACTION_RESET";
