import React, { forwardRef } from "react";
import "./print.scss";
import { Col, Container, Row, Table } from "reactstrap";
import moment from "moment";
import {
  formatINR,
  festivalCount,
  capitalizeFirstLetter,
} from "../helpers/utility";
import PropTypes from "prop-types";
import { META_TITLE } from "../helpers/constants";

const SingleReceipt = forwardRef((props, ref) => {
  document.title = `Receipt Print || ${META_TITLE}`;
  const { dataToPrint } = props;
  const {
    donorid,
    invoiceNumber,
    billingAddress,
    products,
    totalAmount,
    invoiceDate,
    isVerificationRequired = 0,
    billingAddress_en,
  } = { ...dataToPrint };
  return (
    <React.Fragment>
      <div ref={ref}>
        <Container fluid>
          <Row className="gy-5">
            <Col lg={12}>
              <Table className="table mb-0 mt-5 table-borderless">
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      className="text-center"
                      style={{ fontWeight: "bolder" }}
                    >
                      ஸ்ரீ கைலாய ஆஸ்ரமத்தின் {`${festivalCount(invoiceDate)}`}
                      ஆம் ஆண்டு விழா
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="py-2">
                      <Table className="table mb-0 table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <span style={{ fontWeight: "bolder" }}>
                                நன்கொடையாளர்:
                              </span>{" "}
                              {`${capitalizeFirstLetter(
                                isVerificationRequired == 0
                                  ? billingAddress
                                  : billingAddress_en,
                              )}`}{" "}
                              <br />{" "}
                              <span style={{ fontWeight: "bolder" }}>
                                பதிவு எண்:
                              </span>{" "}
                              {`${donorid}`}{" "}
                            </td>
                            <td width={"40%"} className="text-end">
                              <span style={{ fontWeight: "bolder" }}>
                                ரசீது எண் :
                              </span>{" "}
                              {`${invoiceNumber}`}
                              <br />
                              <span style={{ fontWeight: "bolder" }}>
                                ரசீது தேதி :
                              </span>{" "}
                              {`${moment(invoiceDate).format("lll")}`}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                  {products?.map(
                    (
                      {
                        productName_ta,
                        invoiceAmount,
                        invoiceQuantity,
                        invoiceType,
                      },
                      key,
                    ) => (
                      <tr key={`${key}`}>
                        <td className="text-start px-3 py-1">
                          {`${productName_ta}`} ({`${invoiceQuantity}`}){" "}
                          {invoiceType === "Products" && <>பொருள்</>}
                        </td>
                        <td className="text-end px-3 py-1">
                          {formatINR(
                            invoiceType !== "Products" ? invoiceAmount : 0,
                          )}
                        </td>
                      </tr>
                    ),
                  )}
                  {Array.from(Array(4 - products.length), (e, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-3 py-0">&nbsp;</td>
                        <td width={"40%"} className="px-3 py-1">
                          &nbsp;
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="p-0">&nbsp;</td>
                    <td className="text-end p-0 h4 py-3">
                      <span style={{ fontWeight: "bolder" }}>மொத்தம்:</span>{" "}
                      {formatINR(isNaN(totalAmount) ? 0 : totalAmount)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
});
SingleReceipt.displayName = "SingleReceipt";

SingleReceipt.propTypes = {
  dataToPrint: PropTypes.array.isRequired,
};

export default SingleReceipt;
