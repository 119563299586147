import {
  GET_NOTES,
  NOTES_SUCCESS,
  NOTES_FAILED,
  NOTES_RESET,
  SAVE_NOTES,
  SAVE_NOTES_SUCCESS,
  SAVE_NOTES_FAILED,
  SAVE_NOTES_RESET,
  GET_SINGLE_NOTES,
  GET_SINGLE_NOTES_SUCCESS,
  GET_SINGLE_NOTES_FAILED,
  GET_SINGLE_NOTES_RESET,
  UPDATE_NOTES,
  UPDATE_NOTES_SUCCESS,
  UPDATE_NOTES_FAILED,
  UPDATE_NOTES_RESET,
} from "./actionTypes";

export const getNotes = () => {
  return {
    type: GET_NOTES,
  };
};

export const getNotesSuccess = (data) => {
  return {
    type: NOTES_SUCCESS,
    data,
  };
};

export const getNotesFailed = (failed) => {
  return {
    type: NOTES_FAILED,
    failed,
  };
};

export const getNotesReset = (failed) => {
  return {
    type: NOTES_RESET,
    failed,
  };
};

export const createNote = (dataToPass) => {
  return {
    type: SAVE_NOTES,
    dataToPass,
  };
};

export const createNoteSuccess = (data) => {
  return {
    type: SAVE_NOTES_SUCCESS,
    data,
  };
};

export const createNoteFailed = (data) => {
  return {
    type: SAVE_NOTES_FAILED,
    data,
  };
};

export const createNoteReset = () => {
  return {
    type: SAVE_NOTES_RESET,
  };
};

export const getSingleNote = (id) => {
  return {
    type: GET_SINGLE_NOTES,
    id,
  };
};

export const getSingleNoteSuccess = (data) => {
  return {
    type: GET_SINGLE_NOTES_SUCCESS,
    data,
  };
};

export const getSingleNoteFailed = (failed) => {
  return {
    type: GET_SINGLE_NOTES_FAILED,
    failed,
  };
};

export const getSingleNoteReset = (failed) => {
  return {
    type: GET_SINGLE_NOTES_RESET,
    failed,
  };
};

export const updateNote = (dataToPass, id) => {
  return {
    type: UPDATE_NOTES,
    dataToPass,
    id,
  };
};

export const updateNoteSuccess = (data) => {
  return {
    type: UPDATE_NOTES_SUCCESS,
    data,
  };
};

export const updateNoteFailed = (data) => {
  return {
    type: UPDATE_NOTES_FAILED,
    data,
  };
};

export const updateNoteReset = () => {
  return {
    type: UPDATE_NOTES_RESET,
  };
};
