import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";

import Donors from "./donors/reducer";
import DonorTypes from "./donorTypes/reducer";
import Locality from "./locality/reducer";
import Preferences from "./preferences/reducer";
import Products from "./products/reducer";
import Reports from "./reports/reducer";
import Notes from "./notes/reducer";
import FileManager from "./filemanager/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Donors,
  DonorTypes,
  Locality,
  Products,
  Preferences,
  Reports,
  Notes,
  profile,
  FileManager,
});

export default rootReducer;
