import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Grid from "../../Components/Grid";

const OneTimeDonors = () => {
  const gridColumns = [
    {
      data: "name_ta",
      title: "name_ta",
    },
    {
      data: "phone",
      title: "phone",
    },
    {
      data: "rupees",
      title: "Rupees",
    },
    {
      data: "addedBy",
      title: "Added By",
    },
    {
      data: "addedDate",
      title: "Added Date",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="One-time Donors" pageTitle="Donors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Grid
                    columns={gridColumns}
                    data={[]}
                    sortOrder={[0, "desc"]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OneTimeDonors;
