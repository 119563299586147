import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Modal, ModalHeader, Col, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import Cleave from "cleave.js/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useProfile } from "../../Components/Hooks/UserHooks";
import {
  getProducts,
  getPreferences,
  generateReceipt,
  generateReceiptReset,
  getDonorsList,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import SingleReceipt from "../../Prints/SingleReceipt";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import NotificationModal from "./NotificationModal";
import PropTypes from "prop-types";
import { createQueryString } from "../../helpers/utility";

const DonateNow = ({
  openModal,
  openDonateNowOnClick = () => {},
  showExpressDonateButton = false,
  showDonateButton = false,
  donorsDetails,
  donorType,
}) => {
  const history = useHistory();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [openPhoneNumberModal, setOpenPhoneNumberModal] = useState(false);
  const [SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR, setSandhaProductIdForCurrentYear] =
    useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [print, setPrint] = useState(false);
  const [netTotal, setNetTotal] = useState(0);
  const [dataToPrint, setDataToPrint] = useState({});
  const [showExpressDonateDialog, setShowExpressDonateDialog] = useState(false);
  const [objToSave, setObjToSave] = useState({});
  const { userProfile } = useProfile();
  const dispatch = useDispatch();
  const expressDonatePrintRef = useRef();
  const closePhoneNumberPopup = () => {
    setOpenPhoneNumberModal(!openPhoneNumberModal);
  };
  const initialValues = {
    phone: phoneNumber,
  };
  const { productsList = [], isProductsSuccess } = useSelector((state) => ({
    productsList: state.Products.allProducts.data,
    isProductsSuccess: state.Products.allProducts.success,
    isProductsHasError: state.Products.allProducts.error,
  }));
  const { preferences } = useSelector((state) => ({
    preferences: state?.Preferences?.pref?.data,
  }));

  const {
    createReceiptsData,
    isReceiptCreationSuccess,
    isReceiptCreationHasError,
    isReceiptsLoading,
  } = useSelector((state) => ({
    createReceiptsData: state.Donors.createReceipts.data,
    isReceiptCreationSuccess: state.Donors.createReceipts.success,
    isReceiptCreationHasError: state.Donors.createReceipts.error,
    isReceiptsLoading: state.Donors.createReceipts.loading,
  }));

  const onSubmit = (data) => {
    const { rate, id } = find(
      productsList,
      (product) => product.id == SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR,
    );
    setNetTotal(rate);
    const objToSaveLocal = {
      phone: data?.phone,
      donorId: donorsDetails?.donorId,
      addedBy: userProfile?.user.id,
      invoice: [
        {
          type: "Rupees",
          amount: rate,
          product: id,
          quantity: 1,
        },
      ],
    };
    setObjToSave(objToSaveLocal);
    setShowExpressDonateDialog(true);
  };
  const invoiceValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .nullable()
      .test(
        "phone-required",
        "Please enter donor phone number",
        (value = "") => {
          return preferences?.enablePhoneNumber ? value.trim() != "" : true;
        },
      )
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is not valid. Enter 10 digit valid number")
      .max(10, "Phone number is not valid. Enter 10 digit valid number"),
  });
  useEffect(() => {
    setPhoneNumber(donorsDetails?.donorPhone);
  }, [donorsDetails?.donorPhone]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    dispatch(getProducts(currentYear));
    dispatch(getPreferences("preferences"));
  }, []);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSandhaProductIdForCurrentYear(preferences?.SANDHA_ID?.[currentYear]);
  }, [isProductsSuccess, preferences]);
  useEffect(() => {
    if (isReceiptCreationSuccess) {
      const invoiceNumber = Object.keys(createReceiptsData?.data)[0];
      const products = createReceiptsData?.data[invoiceNumber];
      const invoiceDate = products[0].invoiceDate;
      const dataToPrint = {
        donorid: donorsDetails?.donorId,
        invoiceNumber,
        billingAddress: `${donorsDetails?.donorName_ta}, ${phoneNumber}`,
        products,
        totalAmount: parseInt(netTotal),
        invoiceDate,
        donorName_ta: donorsDetails?.donorName_ta,
      };
      setDataToPrint(dataToPrint);
    }
  }, [isReceiptCreationSuccess]);

  const printExpressDonateOnClick = () => {
    if (isReceiptCreationSuccess) {
      setPrint(true);
      setTimeout(() => {
        handlePrint();
      }, 10);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => expressDonatePrintRef.current,
    onAfterPrint: () => {
      doActionAfterprint();
    },
  });

  const onCloseofModalDialog = () => {
    setShowExpressDonateDialog(false);
  };

  const doActionAfterprint = () => {
    setShowExpressDonateDialog(false);
    setOpenPhoneNumberModal(false);
    openDonateNowOnClick();
    dispatch(generateReceiptReset());
    dispatch(
      getDonorsList(
        donorType,
        createQueryString(
          preferences?.enableSSR
            ? {
                perPage: preferences?.ssrRecordsPerPage,
                page: 1,
              }
            : {},
        ),
      ),
    );
  };
  return (
    <React.Fragment>
      <Modal
        size="sm"
        isOpen={openModal}
        toggle={() => {
          openDonateNowOnClick();
        }}
      >
        <ModalHeader
          toggle={() => {
            openDonateNowOnClick();
          }}
        >
          Donate Options
        </ModalHeader>
        <div className="modal-body">
          <Row>
            <Col lg={12} className="mb-3">
              {`Donor Name: ${donorsDetails?.donorName_ta}, Donor ID: ${donorsDetails?.donorId}`}
            </Col>
            <Col lg={12}>
              {showExpressDonateButton && (
                <Button
                  color="primary"
                  className="w-100 mb-3"
                  type="submit"
                  onClick={() => {
                    setOpenPhoneNumberModal(true);
                  }}
                >
                  Express Donate (Sandha Only)
                </Button>
              )}
            </Col>
            <Col lg={12}>
              {showDonateButton && (
                <Button
                  color="primary"
                  className="btn w-100 mb-3"
                  type="submit"
                  onClick={() => {
                    history.push("/generate-invoice", {
                      donorsDetails: donorsDetails,
                      isSandhaOptionRequired:
                        showExpressDonateButton && showDonateButton,
                      donorType: donorType,
                    });
                  }}
                >
                  Donate Now
                </Button>
              )}
            </Col>
            <Col lg={12}>
              <Button
                color="light"
                className="w-100"
                type="submit"
                onClick={() => {
                  openDonateNowOnClick();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      {openPhoneNumberModal && (
        <Modal
          size="sm"
          isOpen={openModal}
          toggle={() => {
            closePhoneNumberPopup();
          }}
        >
          <ModalHeader
            toggle={() => {
              closePhoneNumberPopup();
            }}
          >
            Confirm phone number
          </ModalHeader>
          <div className="modal-body">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={invoiceValidationSchema}
            >
              {(formik) => {
                const { errors, setFieldValue } = formik;
                return (
                  <>
                    <Row>
                      <Form className="formContainer">
                        <Col lg={12} className="mb-3">
                          {`Donor Name: ${donorsDetails?.donorName_ta}, Donor ID: ${donorsDetails?.donorId}`}
                        </Col>
                        <Col lg={12} className="mb-3">
                          <label
                            htmlFor="invoice-phone"
                            className="col-form-label"
                          >
                            Mobile Number{" "}
                            {preferences?.enablePhoneNumber && (
                              <span className="text-danger">*</span>
                            )}
                          </label>
                          <Cleave
                            placeholder="xxxx xxx xxx"
                            className="form-control"
                            value={phoneNumber}
                            name="phone"
                            onInit={() => setFieldValue("phone", phoneNumber)}
                            onChange={(e) => {
                              setPhoneNumber(e.target.rawValue);
                              setFieldValue("phone", e.target.rawValue);
                            }}
                          />
                          <div className="text-danger">
                            {errors?.phone ? `${errors?.phone}` : ""}
                          </div>
                        </Col>
                        <Col lg={12} className="mb-3">
                          <Button
                            color="primary"
                            className="w-100 mb-3"
                            type="submit"
                            onClick={() => {}}
                          >
                            Donate Sandha Now
                          </Button>
                          <Button
                            color="light"
                            className="w-100"
                            type="submit"
                            onClick={() => {
                              closePhoneNumberPopup();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Form>
                    </Row>
                  </>
                );
              }}
            </Formik>
          </div>
          {isReceiptCreationSuccess && (
            <ToastifyMessage message={`${createReceiptsData?.message}`} />
          )}
          {!isReceiptCreationSuccess && isReceiptCreationHasError && (
            <ToastifyMessage
              status=""
              message={`Error: ${createReceiptsData?.message}.`}
            />
          )}
        </Modal>
      )}

      {print && (
        <div className="d-none">
          <SingleReceipt
            ref={expressDonatePrintRef}
            dataToPrint={dataToPrint}
          />
        </div>
      )}
      {showExpressDonateDialog && (
        <NotificationModal
          show={showExpressDonateDialog}
          receiptData={dataToPrint}
          onClickOfPrintButton={printExpressDonateOnClick}
          showPrintButton={isReceiptCreationSuccess}
          onCloseClick={onCloseofModalDialog}
          onCloseClickWithPageRefresh={doActionAfterprint}
          disableSubmitButton={isReceiptsLoading}
          netTotal={netTotal}
          onSubmitClick={() => {
            dispatch(generateReceipt(objToSave));
          }}
        />
      )}
    </React.Fragment>
  );
};

DonateNow.propTypes = {
  openModal: PropTypes.bool.isRequired,
  openDonateNowOnClick: PropTypes.func,
  showExpressDonateButton: PropTypes.bool,
  showDonateButton: PropTypes.bool,
  donorsDetails: PropTypes.object,
  donorType: PropTypes.string,
};

export default DonateNow;
