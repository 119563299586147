import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_RESET,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_RESET,
} from "./actionTypes";

const initialState = {
  allProducts: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  createProduct: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  updateProduct: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
};

const Products = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      state = {
        ...state,
        allProducts: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GET_PRODUCTS_SUCCESS:
      state = {
        ...state,
        allProducts: {
          loading: false,
          success: true,
          error: false,
          data: action.payload,
        },
      };
      break;
    case GET_PRODUCTS_FAILED:
      state = {
        ...state,
        allProducts: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    case CREATE_PRODUCT:
      state = {
        ...state,
        createProduct: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case CREATE_PRODUCT_SUCCESS:
      state = {
        ...state,
        createProduct: {
          loading: false,
          success: true,
          error: false,
          data: action.data,
        },
      };
      break;
    case CREATE_PRODUCT_FAILED:
      state = {
        ...state,
        createProduct: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    case CREATE_PRODUCT_RESET:
      state = {
        ...state,
        createProduct: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    case UPDATE_PRODUCT:
      state = {
        ...state,
        updateProduct: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case UPDATE_PRODUCT_SUCCESS:
      state = {
        ...state,
        updateProduct: {
          loading: false,
          success: true,
          error: false,
          data: action.data,
        },
      };
      break;
    case UPDATE_PRODUCT_FAILED:
      state = {
        ...state,
        updateProduct: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    case UPDATE_PRODUCT_RESET:
      state = {
        ...state,
        updateProduct: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Products;
