import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { formatINR } from "../../helpers/utility";

const NotificationModal = ({
  show,
  onSubmitClick,
  onCloseClick,
  showPrintButton,
  onClickOfPrintButton,
  receiptData,
  disableSubmitButton = false,
  onCloseClickWithPageRefresh,
  netTotal,
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3">
        <div className="text-center">
          <div className="animatedIcon">
            <i
              className={`${
                showPrintButton
                  ? "ri-check-double-fill"
                  : "ri-money-rupee-circle-line"
              } text-primary`}
              style={{ fontSize: "50px" }}
            ></i>
          </div>
          <div className="fs-15 ">
            {!showPrintButton && (
              <>
                <h4>Collect cash</h4>
                <h3 className="mb-2 text-primary">
                  <span className="blink_me">{`${formatINR(netTotal)}`}</span>
                </h3>
                <p className="text-muted mx-4 mb-0">
                  Click below button to generate receipt &amp; print.
                </p>
              </>
            )}
            {showPrintButton && (
              <>
                <p className="border-primary lh-lg">
                  Receipt generated successfully for donor{" "}
                  <strong className="text-decoration-underline">{`${receiptData?.donorName_ta}`}</strong>{" "}
                  with registration ID{" "}
                  <strong className="text-decoration-underline">{`${receiptData?.donorid}`}</strong>
                  .
                </p>
                {receiptData?.invoiceNumber ? (
                  <h4 className="text-primary blink_me">{`${receiptData?.invoiceNumber}`}</h4>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="btn w-sm btn-primary p-2 fs-15"
                  data-bs-dismiss="modal"
                  onClick={onClickOfPrintButton}
                >
                  Print Receipt
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-light p-2 fs-15 ms-3"
                  data-bs-dismiss="modal"
                  disabled={disableSubmitButton}
                  onClick={onCloseClickWithPageRefresh}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
        {!showPrintButton && (
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
              disabled={disableSubmitButton}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-primary "
              id="delete-record"
              disabled={disableSubmitButton}
              onClick={onSubmitClick}
            >
              {disableSubmitButton && (
                <>
                  <Spinner
                    size="sm"
                    type="grow"
                    className="flex-shrink-0 ms-2"
                    role="status"
                  ></Spinner>
                  Generating...
                </>
              )}
              {!disableSubmitButton && "Generate Receipt"}
            </button>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

NotificationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  onSubmitClick: PropTypes.func,
  showPrintButton: PropTypes.bool,
  onClickOfPrintButton: PropTypes.func,
  receiptData: PropTypes.array,
  disableSubmitButton: PropTypes.bool,
  onCloseClickWithPageRefresh: PropTypes.func,
  netTotal: PropTypes.any,
};

export default NotificationModal;
