export const GENERATE_REPORTS = "GENERATE_REPORTS";
export const GENERATE_REPORTS_SUCCESS = "GENERATE_REPORTS_SUCCESS";
export const GENERATE_REPORTS_FAILED = "GENERATE_REPORTS_FAILED";

export const DASHBOARD_REPORTS = "DASHBOARD_REPORTS";
export const DASHBOARD_REPORTS_SUCCESS = "DASHBOARD_REPORTS_SUCCESS";
export const DASHBOARD_REPORTS_FAILED = "DASHBOARD_REPORTS_FAILED";

export const GENERATE_PRINTS = "GENERATE_PRINTS";
export const GENERATE_PRINTS_SUCCESS = "GENERATE_PRINTS_SUCCESS";
export const GENERATE_PRINTS_FAILED = "GENERATE_PRINTS_FAILED";
export const GENERATE_PRINTS_RESET = "GENERATE_PRINTS_RESET";
