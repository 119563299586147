import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import Grid from "../../Components/Grid";
import { useSelector, useDispatch } from "react-redux";
import { getDonorsList, getDonorTypes } from "../../store/actions";
import ViewModal from "./ViewModal";
import UserReceipts from "./UserReceipts";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useHistory, useLocation } from "react-router-dom";
import LoadingBar from "../../Components/Common/LoadingBar";
import DonateNow from "./DonateNow";
import { find } from "lodash";
import {
  isAdminUser,
  capitalizeFirstLetter,
  label,
} from "../../helpers/utility";
import {
  preparePostalAddress,
  isEligibleToPaySandhaThisYear,
  isEligibleToPayDonateThisYear,
  isDonateAllowed,
  createQueryString,
} from "../../helpers/utility";
import Reject from "./Reject";
import { META_TITLE } from "../../helpers/constants";

const GridTable = () => {
  const regex = /(<([^>]+)>)/gi;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [selectType, setSelectType] = useState("sandha");
  const [singleSelectDonorId, setSingleSelectDonorId] = useState();
  const [openViewModal, setopenViewModal] = useState(false);
  const [openReceiptsModal, setOpenReceiptsModal] = useState(false);
  const [postalAddress, setPostalAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [openDonateNowModal, setOpenDonateNowModal] = useState(false);
  const [showExpressDonateButton, setShowExpressDonateButton] = useState(false);
  const [showDonateButton, setShowDonateButton] = useState(false);
  const [donorsDetails, setDonorsDetails] = useState({});
  const [isAdmin] = useState(isAdminUser());
  const [showAreSureDialog, setShowAreSureDialog] = useState(false);
  const [donorDataToDelete, setDonorDataToDelete] = useState("");
  const [rejectAction, setRejectAction] = useState("");
  const [enablePagination, setEnablePagination] = useState(true);
  const defaultParams = {
    perPage: 100,
    page: 1,
    s: undefined,
  };
  const [params, setPrams] = useState(null);
  document.title = `Donors List - ${capitalizeFirstLetter(
    selectType,
  )} || ${META_TITLE}`;
  const { preferenceData, preferenceDataLoading, preferenceDataSuccess } =
    useSelector((state) => ({
      preferenceData: state.Preferences.pref.data,
      preferenceDataLoading: state.Preferences.pref.loading,
      preferenceDataSuccess: state.Preferences.pref.success,
    }));
  const { donorType } = { ...location?.state };

  const { donors, isDonorsSuccess, isDonorsLoading } = useSelector((state) => ({
    donors: state.Donors.donors,
    isDonorsSuccess: state.Donors.success,
    isDonorsLoading: state.Donors.loading,
  }));
  const { donorTypes, isDonorTypeSuccess } = useSelector((state) => ({
    donorTypes: state.DonorTypes.donorTypes,
    isDonorTypeSuccess: state.DonorTypes.success,
  }));
  const openViewModalOnClick = (donorid) => {
    setopenViewModal(!openViewModal);
    setSingleSelectDonorId(donorid);
  };

  const openViewModalOnClickOnClose = () => {
    setOpenReceiptsModal(false);
  };

  const openDonateNowOnClick = () => {
    setOpenDonateNowModal(!openDonateNowModal);
    setShowExpressDonateButton(false);
    setDonorsDetails({});
  };

  const openReceiptsModalOnClick = (row) => {
    if (row === undefined) return;
    setOpenReceiptsModal(!openReceiptsModal);
    setSingleSelectDonorId(row.donorId);
    setPostalAddress(preparePostalAddress(row));
    setBillingAddress(`${row.donorName_ta}, ${row.donorPhone}`);
  };

  const refreshOnClick = () => {
    !enablePagination
      ? dispatch(getDonorsList(selectType))
      : dispatch(getDonorsList(selectType, createQueryString(params)));
  };

  const onDonorEditClick = (editDonorId, editDonorType) => {
    history.push("/edit-donor", { editDonorId, editDonorType });
  };

  const onTrashButtonClick = (donorData, action) => {
    setShowAreSureDialog(true);
    setDonorDataToDelete(donorData);
    setRejectAction(action);
  };
  useEffect(() => {
    if (params != null && preferenceDataSuccess) {
      !enablePagination
        ? dispatch(getDonorsList(selectType))
        : dispatch(getDonorsList(selectType, createQueryString(params)));
    }
  }, [selectType, params]);

  useEffect(() => {
    dispatch(getDonorTypes());
  }, []);

  useEffect(() => {
    setPrams({
      ...defaultParams,
      ...{ perPage: preferenceData?.ssrRecordsPerPage },
    });
    setEnablePagination(preferenceData?.enableSSR);
  }, [preferenceData]);

  const expressDonate = (donorId) => {
    const donorInfo = find(
      !enablePagination ? donors : donors?.data,
      (donor) => donor.donorId == donorId,
    );
    preferenceData;
    return (
      <Button
        color="primary"
        disabled={
          !isEligibleToPaySandhaThisYear({ ...donorInfo, ...preferenceData }) &&
          !isEligibleToPayDonateThisYear({ ...donorInfo })
        }
        onClick={() => {
          setOpenDonateNowModal(true);
          setShowExpressDonateButton(
            isEligibleToPaySandhaThisYear({ ...donorInfo, ...preferenceData }),
          );
          setShowDonateButton(isEligibleToPayDonateThisYear({ ...donorInfo }));
          setDonorsDetails(donorInfo);
        }}
      >
        Donate
      </Button>
    );
  };

  useEffect(() => {
    donorType !== undefined && setSelectType(donorType);
  }, [donorType]);

  const gridColumns = [
    {
      data: "donorId",
      title: label("regId"),
      width: "100px",
    },
    {
      data: "donorPrintNameInBook_en",
      visible: false,
    },
    {
      data: "donorPrintNameInBook_ta",
      title: label("Print_in_Book"),
    },
    {
      data: "donorId",
      title: label("Postal_Address"),
    },
    { data: "donorType", title: "Donor Type", width: "120px" },
    {
      data: "donorId",
      title: "Actions",
      width: "120px",
    },
    ...(isDonateAllowed(preferenceData) &&
    selectType != "address-only" &&
    selectType != "discardPostal" &&
    selectType != "verifyDonors"
      ? [
          {
            data: "donorId",
            title: selectType == "deleted" ? "Deleted" : "Donate",
            width: "120px",
          },
        ]
      : []),
    {
      data: "donorPhone",
      title: "Mobile Number",
      visible: false,
    },
    {
      data: "pincode",
      title: "pincode",
      visible: false,
    },
    {
      data: "post_office",
      title: "post_office",
      visible: false,
    },
    {
      data: "state",
      title: "state",
      visible: false,
    },
    {
      data: "taluk",
      title: "taluk",
      visible: false,
    },
    {
      data: "village",
      title: "village",
      visible: false,
    },
    {
      data: "donorAddress_en",
      title: "donorAddress_en",
      visible: false,
    },
  ];
  const columnDefs = [
    {
      targets: [1, 2],
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: cellData == null ? "" : cellData.replace(regex, ""),
              }}
            ></span>
          </>,
        ),
    },
    {
      targets: [3],
      searchable: true,
      createdCell: (td, cellData, rowData) =>
        ReactDOM.createRoot(td).render(
          <>
            <span
              className={`text-capitalize ${
                rowData?.sendPostal == 1 ? "text-danger" : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: preparePostalAddress(rowData),
              }}
            ></span>
          </>,
        ),
    },
    {
      targets: 5,
      createdCell: (td, cellData, row) =>
        ReactDOM.createRoot(td).render(
          <>
            <ButtonGroup>
              <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-info">
                  Action{" "}
                  <i className="ri-arrow-drop-down-line fs-22 float-end"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {selectType != "address-only" &&
                    selectType != "faq_donors" && (
                      <>
                        <DropdownItem
                          disabled={
                            row.totalReceipts == null || row.totalReceipts <= 0
                          }
                          onClick={() => {
                            openReceiptsModalOnClick(row);
                          }}
                        >
                          Receipts{" "}
                          <Badge
                            color={`${
                              row.totalReceipts != null ? "success" : "danger"
                            }`}
                            pill
                          >
                            {" "}
                            {`${
                              row.totalReceipts != null ? row.totalReceipts : 0
                            }`}{" "}
                          </Badge>
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    )}
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation();
                      openViewModalOnClick(row.donorId);
                    }}
                  >
                    View
                  </DropdownItem>

                  {selectType != "deleted" && (
                    <>
                      <DropdownItem
                        onClick={() =>
                          onDonorEditClick(row.donorId, selectType)
                        }
                      >
                        Edit
                      </DropdownItem>
                    </>
                  )}
                  {isAdmin && (
                    <>
                      <DropdownItem divider />
                      {selectType != "deleted" && (
                        <DropdownItem
                          onClick={() => {
                            onTrashButtonClick(row, "postal");
                          }}
                        >
                          {row.sendPostal == 1
                            ? "Send Postal"
                            : "Stop Sending Postal"}
                        </DropdownItem>
                      )}
                      {selectType != "faq_donors" && (
                        <DropdownItem
                          className="bg-danger text-white"
                          onClick={() => {
                            onTrashButtonClick(row, "donor");
                          }}
                          disabled={
                            row.totalReceipts !== null && row.totalReceipts >= 0
                          }
                        >
                          {selectType != "deleted"
                            ? "Delete Donor"
                            : "Undelete donor"}
                        </DropdownItem>
                      )}
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </>,
        ),
    },
    ...(isDonateAllowed(preferenceData) &&
    selectType != "address-only" &&
    selectType != "verifyDonors"
      ? [
          {
            targets: 6,
            createdCell: (td, cellData, rowData) =>
              ReactDOM.createRoot(td).render(
                <>
                  {selectType != "deleted" ? (
                    expressDonate(rowData.donorId)
                  ) : (
                    <div className="bg-danger text-white text-center fw-bold p-1">
                      Deleted
                    </div>
                  )}
                </>,
              ),
          },
        ]
      : []),
  ];
  const renderDonorTypes = () => {
    return (
      <>
        <ListGroup className="list-group-horizontal-sm mb-3">
          {donorTypes.map((type, index) => {
            const activeClass = selectType === type.slug ? "active" : "";
            return (
              <ListGroupItem
                disabled={isDonorsLoading}
                tag="a"
                to="#!"
                key={index + 1}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectType(`${type.slug}`);
                  setPrams({ ...params, ...{ page: 1 } });
                }}
                className={`list-group-item-action ${activeClass}`}
              >{`${type?.name}`}</ListGroupItem>
            );
          })}
          {isDonorTypeSuccess && (
            <ListGroupItem
              disabled={isDonorsLoading}
              tag="a"
              to="#!"
              key={donorTypes.length + 2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectType(`deleted`);
                setPrams({ ...params, ...{ page: 1 } });
              }}
              className={`list-group-item-action ${
                selectType === "deleted" ? "active" : ""
              }`}
            >{`Deleted Donors`}</ListGroupItem>
          )}

          <ListGroupItem
            disabled={isDonorsLoading}
            tag="a"
            to="#!"
            key={donorTypes.length + 3}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectType(`discardPostal`);
              setPrams({ ...params, ...{ page: 1 } });
            }}
            className={`list-group-item-action ${
              selectType === "discardPostal" ? "active" : ""
            }`}
          >{`Discarded Posts`}</ListGroupItem>
          <ListGroupItem
            disabled={isDonorsLoading}
            tag="a"
            to="#!"
            key={donorTypes.length + 3}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectType(`skipped`);
              setPrams({ ...params, ...{ page: 1 } });
            }}
            className={`list-group-item-action ${
              selectType === "skipped" ? "active" : ""
            }`}
          >{`Skipped Address`}</ListGroupItem>
          <ListGroupItem
            disabled={isDonorsLoading}
            tag="a"
            to="#!"
            key={donorTypes.length + 3}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectType(`verifyDonors`);
              setPrams({ ...params, ...{ page: 1 } });
            }}
            className={`list-group-item-action ${
              selectType === "verifyDonors" ? "active" : ""
            }`}
          >{`Verify`}</ListGroupItem>
        </ListGroup>
      </>
    );
  };

  const onCloseClickOnSureDialog = () => {
    setShowAreSureDialog(false);
  };

  const buttons = (
    <>
      <Button
        color="primary"
        className="bg-animation w-xs-100 mb-2 me-2"
        onClick={() => {
          refreshOnClick();
        }}
      >
        <i className="ri-refresh-line me-1 align-middle fw-bold"></i>
        <span>Refresh</span>
      </Button>
      <Button
        color="primary"
        className="bg-animation w-xs-100 mb-2 me-2"
        onClick={() => {
          history.push("/add-donor");
        }}
      >
        <i className="ri-add-line me-1 align-middle fw-bold"></i>
        <span>New Donor</span>
      </Button>
      <Button
        color="primary"
        className="bg-animation w-xs-100 mb-2 me-2"
        onClick={() => {
          history.push("/invoice-list");
        }}
      >
        <i className="ri-bill-line me-1 align-middle fw-bold"></i>
        <span>Receipts</span>
      </Button>
    </>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Donors List" pageTitle="Donors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="position-relative">
                  {isDonorTypeSuccess && (
                    <Col lg={12}>
                      <Row className="d-flex align-items-center">
                        <Col lg={2}>
                          <h5>Filter by Type</h5>
                        </Col>
                        <Col>{renderDonorTypes()}</Col>
                      </Row>
                    </Col>
                  )}
                  <Col className="w-100 donor-grid">
                    {isDonorsSuccess && preferenceDataSuccess && (
                      <Grid
                        columns={gridColumns}
                        columnDefs={columnDefs}
                        data={donors}
                        sortOrder={[0, "desc"]}
                        buttons={buttons}
                        enableServerSidePagination={enablePagination}
                        paginateCallBack={(page) =>
                          setPrams({ ...params, ...{ page } })
                        }
                        serverSideSearchCallBack={(s) =>
                          setPrams({
                            ...params,
                            ...{ s: s == "" ? undefined : s, page: 1 },
                          })
                        }
                        defaultSearchValue={params?.s}
                      />
                    )}
                    {(isDonorsLoading || preferenceDataLoading) && (
                      <LoadingBar />
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ViewModal
        openModal={openViewModal}
        openViewModalOnClick={openViewModalOnClick}
        donorid={singleSelectDonorId}
        donorType={selectType}
      />
      <UserReceipts
        openModal={openReceiptsModal}
        openViewModalOnClick={openReceiptsModalOnClick}
        donorid={singleSelectDonorId}
        postalAddress={postalAddress}
        billingAddress={billingAddress}
        openViewModalOnClickOnClose={openViewModalOnClickOnClose}
      />
      <DonateNow
        openModal={
          openDonateNowModal && (showDonateButton || showExpressDonateButton)
        }
        openDonateNowOnClick={openDonateNowOnClick}
        showDonateButton={showDonateButton}
        showExpressDonateButton={showExpressDonateButton}
        donorsDetails={donorsDetails}
        donorType={selectType}
      />
      {showAreSureDialog && (
        <Reject
          donorData={donorDataToDelete}
          onCloseClickOnSureDialog={onCloseClickOnSureDialog}
          reloadDonorsPage={refreshOnClick}
          donorType={selectType}
          action={rejectAction}
        />
      )}
    </React.Fragment>
  );
};

export default GridTable;
