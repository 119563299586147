import {
  GET_USERS_DATA,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_FAILED,
  RESET_USERS_DATA,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAILED,
  CREATE_NEW_USER_RESET,
  DO_ACTION,
  DO_ACTION_SUCCESS,
  DO_ACTION_FAILED,
  DO_ACTION_RESET,
} from "./actionTypes";

export const getUsers = () => {
  return {
    type: GET_USERS_DATA,
  };
};

export const getUsersSuccess = (data) => {
  return {
    type: GET_USERS_DATA_SUCCESS,
    data,
  };
};

export const getUsersFailed = (failed) => {
  return {
    type: GET_USERS_DATA_FAILED,
    failed,
  };
};

export const getUsersReset = () => {
  return {
    type: RESET_USERS_DATA,
  };
};

export const createUser = (payload) => {
  return {
    type: CREATE_NEW_USER,
    payload,
  };
};

export const createUserSuccess = (data) => {
  return {
    type: CREATE_NEW_USER_SUCCESS,
    data,
  };
};

export const createUserFailed = (failed) => {
  return {
    type: CREATE_NEW_USER_FAILED,
    failed,
  };
};

export const createUserReset = () => {
  return {
    type: CREATE_NEW_USER_RESET,
  };
};

export const doUserAction = (payload, userId) => {
  return {
    type: DO_ACTION,
    payload,
    userId,
  };
};

export const doUserActionSuccess = (data) => {
  return {
    type: DO_ACTION_SUCCESS,
    data,
  };
};

export const doUserActionFailed = (failed) => {
  return {
    type: DO_ACTION_FAILED,
    failed,
  };
};

export const doUserActionReset = () => {
  return {
    type: DO_ACTION_RESET,
  };
};
