export const DASHBOARD_LINKS = [
  {
    name: "Donors",
    link: "/donors-list",
    description: "Hearts that share, making a difference.",
    role: [0, 1],
  },
  {
    name: "Create Donor",
    link: "/add-donor",
    description: "Start a giving journey, craft a donor's story.",
    role: [0, 1],
  },
  {
    name: "Pincode Search",
    link: "/locality-list",
    description: "Search for pincode by using this link.",
    role: [0, 1],
  },
  {
    name: "Receipts",
    link: "/invoice-list",
    description: "Track generosity, receipts in listing simplicity.",
    role: [0, 1],
  },
  {
    name: "Note Book",
    link: "/note-book",
    description: "Ideas unfold, in the pages of the note book.",
    role: [1],
  },
  {
    name: "Donor Types",
    link: "/donors-types",
    description: "Diverse hearts, various giving arts.",
    role: [1],
  },
  {
    name: "Products",
    link: "/products",
    description: "Offerings galore, find what you adore.",
    role: [1],
  },
  {
    name: "Users",
    link: "/users",
    description: "Collaborate, with users who innovate.",
    role: [1],
  },
  {
    name: "Reports",
    link: "/generate-report",
    description: "Data's tale, in comprehensive reports, unveil.",
    role: [1],
  },
  {
    name: "Prints",
    link: "/print-outs",
    description: "Capture moments, memories in prints.",
    role: [1],
  },
  {
    name: "Insights",
    link: "/insights",
    description: "Wisdom's glow, in insights that flow.",
    role: [1],
  },
  {
    name: "Preferences",
    link: "/preferences",
    description: "Tailor your space, set your preferences.",
    role: [1],
  },
  {
    name: "Prepare Donors Book",
    link: "/prepare-donor-book",
    description: "Compile giving tales, with a donors' book's.",
    role: [1],
  },
  {
    name: "Invoice Raw",
    link: "/invoice-raw",
    description: "Bill the essence, with an invoice raw presence.",
    role: [1],
  },
  {
    name: "Rejection List",
    link: "/reject-list",
    description: "Details of rejected donors will be displayed here.",
    role: [1],
  },
];
