import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createNote,
  createNoteReset,
  getSingleNote,
  updateNote,
  updateNoteReset,
} from "../../store/actions";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../Components/Common/LoadingBar";

const AddNotes = () => {
  document.title = "Add Note";
  const { quill, quillRef } = useQuill();
  const dispatch = useDispatch();
  const history = useHistory();
  const { mode = "NEW", getId } = { ...history?.location?.state };
  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        const text = quill.container.firstChild.innerHTML;
        formik.setFieldValue("description", text);
      });
      if (mode != "NEW") {
        quill.container.firstChild.innerHTML = sinlgeNoteData[0]?.description;
      }
      if (mode == "VIEW") {
        quill.disable();
      }
    }
  }, [quill, mode]);

  const { savedNoteData, savedNoteLoading, savedNoteSuccess, savedNoteError } =
    useSelector((state) => ({
      savedNoteData: state.Notes.saveNotes?.data,
      savedNoteLoading: state.Notes.saveNotes?.loading,
      savedNoteSuccess: state.Notes.saveNotes?.success,
      savedNoteError: state.Notes.saveNotes?.error,
    }));

  const {
    updateNoteData,
    updateNoteLoading,
    updateNoteSuccess,
    updateNoteError,
  } = useSelector((state) => ({
    updateNoteData: state.Notes.updateNote?.data,
    updateNoteLoading: state.Notes.updateNote?.loading,
    updateNoteSuccess: state.Notes.updateNote?.success,
    updateNoteError: state.Notes.updateNote?.error,
  }));

  const { sinlgeNoteData, singleNoteLoading } = useSelector((state) => ({
    sinlgeNoteData: state.Notes.singleNote?.data,
    singleNoteLoading: state.Notes.singleNote?.loading,
    singleNoteSuccess: state.Notes.singleNote?.success,
    singleNoteError: state.Notes.singleNote?.error,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      title: mode != "NEW" ? sinlgeNoteData[0]?.title : "",
      description: mode != "NEW" ? sinlgeNoteData[0]?.description : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      mode == "NEW" && dispatch(createNote(values));
      mode == "EDIT" && dispatch(updateNote(values, sinlgeNoteData[0]?.id));
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (savedNoteSuccess || updateNoteSuccess) {
        setTimeout(() => {
          dispatch(createNoteReset());
          dispatch(updateNoteReset());
          history.push("/note-book", { isRefresh: true });
        }, 0);
      }
    }, 3000);
  }, [savedNoteSuccess, updateNoteSuccess]);

  useEffect(() => {
    if (mode != "NEW") {
      dispatch(getSingleNote(getId));
    }
  }, [mode]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Notes List" pageTitle="Notes" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {singleNoteLoading && <LoadingBar />}
                  {!singleNoteLoading && (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                      action="#"
                    >
                      <Row className="g-0">
                        <Col sm={6}>
                          <Label htmlFor="donorName_en" className="form-label">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            readOnly={mode == "VIEW"}
                            placeholder="Enter title in english"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.title && formik.errors.title
                            }
                            value={formik.values.title}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <FormFeedback type="invalid">
                              {formik.errors.title}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col sm={12}>
                          <Label
                            htmlFor="donorName_en"
                            className="form-label pt-3"
                          >
                            Description <span className="text-danger">*</span>
                          </Label>
                          <div ref={quillRef} />
                          {formik.errors.description ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "block" }}
                            >
                              {formik.errors.description}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={6} className="pt-3">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab w-xs-100 mt-xs-3"
                            onClick={() => {
                              dispatch(createNoteReset());
                              history.push("/note-book");
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                            Back to Notes List
                          </button>
                        </Col>
                        {mode !== "VIEW" && (
                          <Col
                            sm={6}
                            className="d-flex justify-content-end pt-3"
                          >
                            <Button
                              color="primary"
                              className="btn-label right ms-auto nexttab w-xs-100 mt-xs-3"
                              type="submit"
                              disabled={savedNoteLoading || updateNoteLoading}
                            >
                              {!(savedNoteLoading || updateNoteLoading) && (
                                <>
                                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                  {mode == "EDIT" ? "Update" : "Save Now"}
                                </>
                              )}
                              {(savedNoteLoading || updateNoteLoading) && (
                                <span className="d-flex align-items-center">
                                  <span className="flex-grow-1 me-2">
                                    {mode == "EDIT"
                                      ? "Updating..."
                                      : "Saving..."}
                                  </span>
                                  <Spinner
                                    size="sm"
                                    type="grow"
                                    className="flex-shrink-0"
                                    role="status"
                                  >
                                    {" "}
                                    {mode == "EDIT"
                                      ? "Updating..."
                                      : "Saving..."}
                                  </Spinner>
                                </span>
                              )}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {savedNoteSuccess && (
            <ToastifyMessage
              message={`${savedNoteData?.message}. Please wait...`}
            />
          )}
          {savedNoteError && (
            <ToastifyMessage
              status=""
              message={`Error: ${savedNoteData?.message}`}
            />
          )}
          {updateNoteSuccess && (
            <ToastifyMessage
              message={`${updateNoteData?.message}. Please wait...`}
            />
          )}
          {updateNoteError && (
            <ToastifyMessage
              status=""
              message={`Error: ${updateNoteData?.message}`}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddNotes;
