import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Grid from "../../Components/Grid";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../store/actions";
import LoadingBar from "../../Components/Common/LoadingBar";
import ReactDOM from "react-dom/client";
import UserForm from "./UserForm";
import UserActions from "./UserActions";
import { usersRoleName } from "../../helpers/utility";
import { META_TITLE } from "../../helpers/constants";

const Users = () => {
  document.title = `Users Management || ${META_TITLE}`;
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [showActionForm, setShowActionForm] = useState("");
  const [selectedUserToPass, setSelectedUser] = useState(0);
  const gridColumns = [
    {
      data: "id",
      title: "User ID",
      width: "150px",
    },
    {
      data: "name",
      title: "Name",
    },
    {
      data: "email",
      title: "Username/Email",
    },
    {
      data: "user_role",
      title: "Role",
    },
    {
      data: "status",
      title: "Status",
      width: "150px",
    },
    {
      data: "id",
      title: "Actions",
      width: "150px",
    },
  ];

  const {
    usersList = [],
    isUsersSuccess,
    isUsersLoading,
  } = useSelector((state) => ({
    usersList: state.profile?.users.data,
    isUsersSuccess: state.profile?.users.success,
    isUsersLoading: state.profile?.users.loading,
    isUsersHasError: state.profile?.users.error,
  }));

  const onActionBtnClick = (selectedUser, selectedAction) => {
    setShowActionForm(true);
    setAction(selectedAction);
    setSelectedUser(selectedUser);
  };

  const columnDefs = [
    {
      targets: [3],
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: usersRoleName(cellData),
              }}
            ></span>
          </>,
        ),
    },
    {
      targets: [4],
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: cellData == 1 ? "Active" : "In-Active",
              }}
            ></span>
          </>,
        ),
    },
    {
      targets: [5],
      createdCell: (td, cellData, rowData) =>
        ReactDOM.createRoot(td).render(
          <>
            <ButtonGroup>
              <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-info">
                  Action{" "}
                  <i className="ri-arrow-drop-down-line fs-22 float-end"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => onActionBtnClick(rowData, "pwd")}
                  >
                    Change Password
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onActionBtnClick(rowData, "role")}
                  >
                    Change Role
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onActionBtnClick(rowData, "status")}
                  >
                    Change Status
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </>,
        ),
    },
  ];

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const onRefresh = () => {
    dispatch(getUsers());
  };

  const buttons = (
    <>
      <Button
        color="primary"
        className="bg-animation w-xs-100 mb-2 me-2"
        data-text="Refresh"
        onClick={() => onRefresh()}
      >
        <i className="ri-refresh-line me-1 align-middle fw-bold"></i>
        <span>Refresh</span>
      </Button>
      <Button
        color="primary"
        className="bg-animation w-xs-100 mb-2 me-2"
        data-text="Refresh"
        onClick={() => setShowForm(true)}
      >
        <i className="ri-add-line me-1 align-middle fw-bold"></i>
        <span>New User</span>
      </Button>
    </>
  );
  const onFormClose = () => {
    setShowForm(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Users Management" pageTitle="Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="w-100 donor-grid">
                      {isUsersSuccess && (
                        <Grid
                          columns={gridColumns}
                          data={usersList}
                          sortOrder={[0, "desc"]}
                          isLoading={isUsersLoading}
                          columnDefs={columnDefs}
                          buttons={buttons}
                        />
                      )}
                      {isUsersLoading && <LoadingBar />}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {showForm && <UserForm isOpen={showForm} onClose={onFormClose} />}
        {showActionForm && (
          <UserActions
            isOpen={showActionForm}
            action={action}
            onActionCloseCb={(isCanceled = false) => {
              setShowActionForm(!action);
              !isCanceled && dispatch(getUsers());
            }}
            userData={selectedUserToPass}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Users;
