import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import Grid from "../../Components/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getNotes } from "../../store/actions";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../Components/Common/LoadingBar";
import { META_TITLE } from "../../helpers/constants";

const Notes = () => {
  document.title = `Notes || ${META_TITLE}`;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isRefresh = false } = { ...history?.location?.state };

  const gridColumns = [
    {
      data: "id",
      title: "Note ID",
      width: "120px",
    },
    {
      data: "title",
      title: "Note Name",
    },
    {
      data: "addedBy",
      title: "Added By",
      width: "200px",
    },
    {
      title: "Actions",
      data: "id",
      width: "120px",
    },
  ];
  const onEditClick = (getId) => {
    history.push("/add-note", { mode: "EDIT", getId });
  };
  const onViewClick = (getId) => {
    history.push("/add-note", { mode: "VIEW", getId });
  };
  const columnDefs = [
    {
      targets: 3,
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <ButtonGroup>
              <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-info">
                  Action{" "}
                  <i className="ri-arrow-drop-down-line fs-22 float-end"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      onViewClick(cellData);
                    }}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      onEditClick(cellData);
                    }}
                  >
                    Edit
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </>,
        ),
    },
  ];
  const {
    notesList = [],
    isNotesSuccess,
    isNotesLoading,
  } = useSelector((state) => ({
    notesList: state.Notes?.notes.data,
    isNotesSuccess: state.Notes?.notes.success,
    isNotesLoading: state.Notes?.notes.loading,
    isNotesHasError: state.Notes?.notes.error,
  }));

  const buttons = (
    <>
      <Button
        color="primary"
        className="me-3"
        onClick={() => {
          dispatch(getNotes());
        }}
      >
        <i className="ri-refresh-line me-1 align-middle fw-bold"></i>
        Refresh
      </Button>
      <Button
        color="primary"
        className="bg-gradient"
        disabled={false}
        onClick={() => {
          history.push("/add-note");
        }}
      >
        <i className="ri-add-line me-1 align-middle fw-bold"></i>
        Add New Note
      </Button>
    </>
  );

  useEffect(() => {
    !isNotesSuccess && dispatch(getNotes());
  }, [isNotesSuccess]);

  useEffect(() => {
    isRefresh && dispatch(getNotes());
  }, [isRefresh]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Notes List" pageTitle="Notes" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="table-gridjs">
                    {isNotesSuccess && (
                      <Grid
                        columns={gridColumns}
                        data={notesList}
                        buttons={buttons}
                        columnDefs={columnDefs}
                        sortOrder={[0, "desc"]}
                      />
                    )}
                    {isNotesLoading && <LoadingBar />}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Notes;
