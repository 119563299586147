import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import {
  GET_NOTES,
  SAVE_NOTES,
  GET_SINGLE_NOTES,
  UPDATE_NOTES,
} from "./actionTypes";
import {
  getNotesSuccess,
  getNotesFailed,
  createNoteSuccess,
  createNoteFailed,
  getSingleNoteSuccess,
  getSingleNoteFailed,
  updateNoteSuccess,
  updateNoteFailed,
} from "./actions";

const api = new APIClient();

function* getNotesSaga() {
  try {
    const getNotes = () => api.get(`${"/notes"}`);
    const response = yield call(getNotes);
    const { data, status } = response;
    if (status === 200) {
      yield put(getNotesSuccess(data));
    } else {
      yield put(getNotesFailed(response));
    }
  } catch (error) {
    yield put(getNotesFailed(error));
  }
}

function* getSingleNoteSaga(action) {
  const { id } = action;
  try {
    const getNote = () => api.get(`${"/notes"}/${id}`);
    const response = yield call(getNote);
    const { data, status } = response;
    status === 201
      ? yield put(getSingleNoteSuccess(data))
      : yield put(getSingleNoteFailed(data));
  } catch (error) {
    yield put(getSingleNoteFailed(error));
  }
}

function* getSaveNotesSaga(action) {
  const { dataToPass } = action;
  try {
    const addNotes = (data) => api.create(`${"/notes"}`, data);
    const response = yield call(addNotes, dataToPass);
    const { data, status } = response;
    status === 201
      ? yield put(createNoteSuccess(data))
      : yield put(createNoteFailed(data));
  } catch (error) {
    yield put(createNoteFailed(error));
  }
}

function* getUpdateNotesSaga(action) {
  const { dataToPass, id } = action;
  try {
    const addNotes = (data) => api.put(`${"/notes"}/${id}`, data);
    const response = yield call(addNotes, dataToPass);
    const { data, status } = response;
    status === 201
      ? yield put(updateNoteSuccess(data))
      : yield put(updateNoteFailed(data));
  } catch (error) {
    yield put(updateNoteFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(GET_NOTES, getNotesSaga);
  yield takeEvery(SAVE_NOTES, getSaveNotesSaga);
  yield takeEvery(GET_SINGLE_NOTES, getSingleNoteSaga);
  yield takeEvery(UPDATE_NOTES, getUpdateNotesSaga);
}

function* notesSagaOutput() {
  yield all([fork(watchUserRegister)]);
}

export default notesSagaOutput;
