import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalBody,
  ModalHeader,
  Modal,
  Row,
  Col,
  Label,
  Input,
  ModalFooter,
  Form,
  Button,
} from "reactstrap";
import {
  createProduct,
  createProductReset,
  getProducts,
  updateProduct,
  updateProductReset,
} from "../../store/actions";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import LoadingBar from "../../Components/Common/LoadingBar";
import GoogleTranslatorInputField from "../../Components/Common/GoogleTranslatorInputField";
import PropTypes from "prop-types";

const AddEdit = ({
  openModal,
  onCloseClick,
  productsYear,
  mode = "NEW",
  dataForCopyEdit,
}) => {
  const productAddtionRef = useRef();
  const dispatch = useDispatch();
  const [currentYear] = useState(new Date().getFullYear());
  const metricsData = [
    { label: "Kg(s)", value: "kg" },
    { label: "Rupees", value: "rs" },
    { label: "Litter(s)", value: "litter" },
    { label: "Bag(s)", value: "bag" },
    { label: "Grams", value: "grams" },
    { label: "Numbers", value: "numbers" },
    { label: "Person", value: "person" },
  ];
  const [metrics] = useState(metricsData);

  const {
    createProductData,
    createProductLoading,
    createProductSuccess,
    createProductError,
  } = useSelector((state) => ({
    createProductData: state.Products.createProduct.data,
    createProductLoading: state.Products.createProduct.loading,
    createProductSuccess: state.Products.createProduct.success,
    createProductError: state.Products.createProduct.error,
  }));

  const {
    updateProductData,
    updateProductLoading,
    updateProductSuccess,
    updateProductError,
  } = useSelector((state) => ({
    updateProductData: state.Products.updateProduct.data,
    updateProductLoading: state.Products.updateProduct.loading,
    updateProductSuccess: state.Products.updateProduct.success,
    updateProductError: state.Products.updateProduct.error,
  }));

  const performProductAddition = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: mode == "EDIT" ? dataForCopyEdit?.name : "",
      name_ta: mode == "EDIT" ? dataForCopyEdit?.name_ta : "",
      metric: mode != "NEW" ? dataForCopyEdit?.metric : "",
      description: mode != "NEW" ? dataForCopyEdit?.description : "",
      price: mode != "NEW" ? dataForCopyEdit?.rate : "",
      year: mode == "EDIT" ? dataForCopyEdit?.year : currentYear,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter product name in English"),
      name_ta: Yup.string().required("Please enter product name in tamil"),
      metric: Yup.string().required("Please select metric"),
      price: Yup.number()
        .required("Please enter price")
        .test(
          "Is positive?",
          "ERROR: The number must be greater than 0!",
          (value) => value > 0,
        ),
      year: Yup.string().required("Please enter year"),
    }),
    onSubmit: (values) => {
      const additionalValues = { quantity: 1, active: 1 };
      mode != "EDIT" &&
        dispatch(createProduct({ ...values, ...additionalValues }));
      mode == "EDIT" &&
        dispatch(
          updateProduct({
            ...values,
            ...additionalValues,
            productId: dataForCopyEdit?.id,
          }),
        );
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (createProductSuccess || updateProductSuccess) {
        onCloseClick();
        dispatch(getProducts(productsYear));
        performProductAddition.resetForm();
        setTimeout(() => {
          dispatch(createProductReset());
          dispatch(updateProductReset());
        }, 0);
      }
    }, 3000);
  }, [createProductSuccess, updateProductSuccess]);

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={openModal} toggle={onCloseClick}>
        <ModalHeader id="addProduct" toggle={onCloseClick}>
          Add Product
        </ModalHeader>
        <FormikProvider value={performProductAddition}>
          <Form
            innerRef={productAddtionRef}
            onSubmit={(e) => {
              e.preventDefault();
              performProductAddition.handleSubmit();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Row>
                {createProductLoading && (
                  <Col>{createProductLoading && <LoadingBar />}</Col>
                )}
                {!createProductLoading && (
                  <>
                    <Col lg={4} sm={6} className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Product Name (English){" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="name"
                        autoComplete="off"
                        placeholder="Product Name (English)"
                        onChange={performProductAddition.handleChange}
                        onBlur={performProductAddition.handleBlur}
                        value={performProductAddition.values.name}
                      />
                      {performProductAddition.touched.name &&
                      performProductAddition.errors.name ? (
                        <div className="text-danger">
                          {performProductAddition.errors.name}
                        </div>
                      ) : null}
                    </Col>
                    <Col lg={4} sm={6} className="mb-3">
                      <Label htmlFor="name_ta" className="form-label">
                        Product Name (Tamil){" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <GoogleTranslatorInputField
                        type="text"
                        className="form-control"
                        id="name_ta"
                        autoComplete="off"
                        placeholder="Product Name (Tamil)"
                        onChange={performProductAddition.handleChange}
                        onBlur={performProductAddition.handleBlur}
                        value={performProductAddition.values.name_ta}
                      />
                      {performProductAddition.touched.name_ta &&
                      performProductAddition.errors.name_ta ? (
                        <div className="text-danger">
                          {performProductAddition.errors.name_ta}
                        </div>
                      ) : null}
                    </Col>
                    <Col lg={4} sm={6} className="mb-3">
                      <Label htmlFor="metric" className="form-label">
                        Metric <span className="text-danger">*</span>
                      </Label>
                      <Select
                        classNamePrefix="myOwnSelect"
                        isMulti={false}
                        id="metric"
                        name="metric"
                        options={metrics}
                        placeholder={"Select Metric"}
                        onChange={(option) => {
                          performProductAddition.setFieldValue(
                            "metric",
                            option.value,
                          );
                        }}
                        onBlur={performProductAddition.handleBlur}
                        value={metrics.filter(
                          (option) =>
                            performProductAddition.values.metric ===
                            option.value,
                        )}
                      />
                      {performProductAddition.touched.metric &&
                      performProductAddition.errors.metric ? (
                        <div className="text-danger">
                          {performProductAddition.errors.metric}
                        </div>
                      ) : null}
                    </Col>
                    <Col lg={4} sm={6} className="mb-3">
                      <Label htmlFor="description" className="form-label">
                        Description
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="description"
                        autoComplete="off"
                        placeholder="Description"
                        onChange={performProductAddition.handleChange}
                        onBlur={performProductAddition.handleBlur}
                        value={performProductAddition.values.description}
                      />
                    </Col>
                    <Col lg={4} sm={6} className="mb-3">
                      <Label htmlFor="price" className="form-label">
                        Price <span className="text-danger">*</span>
                      </Label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">&#x20b9;</span>
                        <Input
                          type="text"
                          className="form-control"
                          id="price"
                          placeholder="Price"
                          autoComplete="off"
                          onChange={performProductAddition.handleChange}
                          onBlur={performProductAddition.handleBlur}
                          value={performProductAddition.values.price}
                        />
                        <div className="d-inline w-100">
                          {performProductAddition.touched.price &&
                          performProductAddition.errors.price ? (
                            <div className="text-danger">
                              {performProductAddition.errors.price}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} sm={6} className="mb-3">
                      <Label htmlFor="year" className="form-label">
                        Year <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        id="year"
                        readOnly={mode != "NEW"}
                        value={new Date().getFullYear()}
                        placeholder="Year"
                      />
                    </Col>
                  </>
                )}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Link
                to="#"
                className="btn btn-danger fw-medium"
                onClick={onCloseClick}
                disabled={createProductLoading}
              >
                <i className="ri-close-line me-1 align-middle"></i> Close
              </Link>
              {mode != "EDIT" && (
                <Button
                  color="primary"
                  className="ms-auto"
                  type="submit"
                  disabled={createProductLoading}
                >
                  Add New Product
                </Button>
              )}
              {mode == "EDIT" && (
                <Button
                  color="primary"
                  className="btn btn-primary ms-auto"
                  type="submit"
                  disabled={updateProductLoading}
                >
                  Update Product
                </Button>
              )}
            </ModalFooter>
          </Form>
        </FormikProvider>
        {createProductSuccess && (
          <ToastifyMessage message={`${createProductData?.message}`} />
        )}
        {createProductError && createProductData?.message && (
          <ToastifyMessage
            status=""
            message={`Error: ${createProductData?.message}.`}
          />
        )}

        {updateProductSuccess && (
          <ToastifyMessage message={`${updateProductData?.message}`} />
        )}
        {updateProductError && updateProductData?.message && (
          <ToastifyMessage
            status=""
            message={`Error: ${updateProductData?.message}.`}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

AddEdit.propTypes = {
  openModal: PropTypes.bool,
  onCloseClick: PropTypes.func,
  productsYear: PropTypes.any,
  mode: PropTypes.string,
  dataForCopyEdit: PropTypes.object,
};

export default AddEdit;
