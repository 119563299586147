import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import Logout from "../../pages/Authentication/Logout";

function IdleChecker({ timeout = 300000, onLogout }) {
  const [isIdle, setIsIdle] = useState(false);
  const [callLogout, setCallLogout] = useState(false);

  useEffect(() => {
    let timeoutId;
    let logoutTimerId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsIdle(true);
        logoutTimerId = setTimeout(() => setCallLogout(true), timeout + 60000);
      }, timeout);
    };

    const onActivity = () => {
      setIsIdle(false);
      clearTimeout(logoutTimerId);
      resetTimer();
    };

    window.addEventListener("mousemove", onActivity);
    window.addEventListener("keydown", onActivity);

    resetTimer();

    return () => {
      window.removeEventListener("mousemove", onActivity);
      window.removeEventListener("keydown", onActivity);
      clearTimeout(timeoutId);
      clearTimeout(logoutTimerId);
      setCallLogout(false);
    };
  }, [timeout, onLogout]);

  return (
    <div>
      {callLogout && <Logout />}
      {isIdle && (
        <Modal isOpen={isIdle} className="modal-dialog-centered">
          <ModalBody className="py-3 px-5 text-center">
            <p>
              <i
                className="ri-timer-line text-primary"
                style={{ fontSize: "100px" }}
              ></i>
            </p>
            <p className="fs-4 text">
              The screen has been idle for 5 minutes; it will log out in another
              1 minute.
            </p>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}

export default IdleChecker;
