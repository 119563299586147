import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { doUserAction, doUserActionReset } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";

const UserActions = ({ isOpen = false, onActionCloseCb, action, userData }) => {
  const { email, name, id, user_role, status } = userData;
  const dispatch = useDispatch();
  const [roles] = useState([
    { label: "Data Entry Operator", value: 0 },
    { label: "Admin", value: 1 },
    { label: "Press", value: 2 },
  ]);
  const [statuses] = useState([
    { label: "In-Active", value: 0 },
    { label: "Active", value: 1 },
  ]);
  const {
    doActionData = [],
    isActionSuccess,
    isActionLoading,
    isActionError,
  } = useSelector((state) => ({
    doActionData: state.profile?.doAction.data,
    isActionSuccess: state.profile?.doAction.success,
    isActionLoading: state.profile?.doAction.loading,
    isActionError: state.profile?.doAction.error,
  }));
  const passwordChangeForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      password: "",
      password_confirmation: "",
      action: "pwd",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password is required"),
      password_confirmation: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      dispatch(doUserAction(values, id));
    },
  });
  useEffect(() => {
    isActionSuccess &&
      action == "pwd" &&
      setTimeout(() => {
        passwordChangeForm.resetForm();
        onActionCloseCb();
      }, 3000);
  }, [isActionSuccess, action]);
  useEffect(() => {
    return () => {
      passwordChangeForm.resetForm();
      dispatch(doUserActionReset());
    };
  }, []);
  const Password = () => {
    return (
      <>
        <ModalHeader id="UserActions">Change Password</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            passwordChangeForm.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalBody>
            <p>
              User Email: {`${email}`}, Name: {`${name}`}
            </p>
            <Row>
              <Col className="mb-3">
                <Label htmlFor="password" className="form-label">
                  Password <span className="text-danger">*</span>
                </Label>
                <Input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter new password"
                  autocomplete="new-password"
                  onChange={passwordChangeForm.handleChange}
                  onBlur={passwordChangeForm.handleBlur}
                  value={passwordChangeForm.values.password || ""}
                  invalid={
                    passwordChangeForm.touched.password &&
                    passwordChangeForm.errors.password
                  }
                />
                {passwordChangeForm.touched.password &&
                passwordChangeForm.errors.password ? (
                  <FormFeedback type="invalid">
                    {passwordChangeForm.errors.password}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label htmlFor="password_confirmation" className="form-label">
                  Confirm Password <span className="text-danger">*</span>
                </Label>
                <Input
                  type="password"
                  className="form-control"
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder="Enter confirm password"
                  autocomplete="new-password"
                  onChange={passwordChangeForm.handleChange}
                  onBlur={passwordChangeForm.handleBlur}
                  value={passwordChangeForm.values.password_confirmation || ""}
                  invalid={
                    passwordChangeForm.touched.password_confirmation &&
                    passwordChangeForm.errors.password_confirmation
                  }
                />
                {passwordChangeForm.touched.password_confirmation &&
                passwordChangeForm.errors.password_confirmation ? (
                  <FormFeedback type="invalid">
                    {passwordChangeForm.errors.password_confirmation}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              type="button"
              disabled={isActionLoading}
              onClick={() => {
                passwordChangeForm.resetForm();
                onActionCloseCb();
              }}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isActionLoading}>
              Update Password
            </Button>
          </ModalFooter>
        </Form>
      </>
    );
  };

  const roleChangeForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      role: user_role == null ? 0 : user_role,
      action: "role",
    },
    validationSchema: Yup.object({
      role: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      dispatch(doUserAction(values, id));
    },
  });

  useEffect(() => {
    isActionSuccess &&
      action == "role" &&
      setTimeout(() => {
        roleChangeForm.resetForm();
        onActionCloseCb();
      }, 3000);
  }, [isActionSuccess, action]);
  useEffect(() => {
    return () => {
      roleChangeForm.resetForm();
      dispatch(doUserActionReset());
    };
  }, []);
  const Role = () => {
    return (
      <>
        <ModalHeader id="UserActions">Change Role</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            roleChangeForm.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalBody>
            <p>
              User Email: {`${email}`}, Name: {`${name}`}
            </p>
            <Row>
              <Col>
                <Label htmlFor="role" className="form-label">
                  Change Role <span className="text-danger">*</span>
                </Label>
                <Select
                  classNamePrefix="myOwnSelect"
                  id="role"
                  name="role"
                  options={roles}
                  placeholder={"Select a Role"}
                  className={
                    roleChangeForm?.touched?.role &&
                    roleChangeForm?.errors?.role
                      ? "is-invalid"
                      : ""
                  }
                  onChange={(option) => {
                    roleChangeForm.setFieldValue("role", option.value);
                  }}
                  value={roles.filter(
                    (option) => roleChangeForm.values.role === option.value,
                  )}
                />

                {roleChangeForm.touched.role && roleChangeForm.errors.role ? (
                  <FormFeedback type="invalid" className="d-block">
                    {roleChangeForm.errors.role}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              type="button"
              disabled={isActionLoading}
              onClick={() => {
                roleChangeForm.resetForm();
                onActionCloseCb(true);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isActionLoading}>
              Save new Role
            </Button>
          </ModalFooter>
        </Form>
      </>
    );
  };

  const statusChangeForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      status: status == null ? 0 : status,
      action: "status",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      dispatch(doUserAction(values, id));
    },
  });

  useEffect(() => {
    isActionSuccess &&
      action == "status" &&
      setTimeout(() => {
        statusChangeForm.resetForm();
        onActionCloseCb();
      }, 3000);
  }, [isActionSuccess, action]);
  useEffect(() => {
    return () => {
      statusChangeForm.resetForm();
      dispatch(doUserActionReset());
    };
  }, []);
  const Status = () => {
    return (
      <>
        <ModalHeader id="UserActions">Change Status</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            statusChangeForm.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalBody>
            <p>
              User Email: {`${email}`}, Name: {`${name}`}
            </p>
            <Row>
              <Col>
                <Label htmlFor="status" className="form-label">
                  Change Status <span className="text-danger">*</span>
                </Label>
                <Select
                  classNamePrefix="myOwnSelect"
                  id="status"
                  name="status"
                  options={statuses}
                  placeholder={"Select a Status"}
                  className={
                    statusChangeForm?.touched?.role &&
                    statusChangeForm?.errors?.role
                      ? "is-invalid"
                      : ""
                  }
                  onChange={(option) => {
                    statusChangeForm.setFieldValue("status", option.value);
                  }}
                  value={statuses.filter(
                    (option) => statusChangeForm.values.status === option.value,
                  )}
                />

                {statusChangeForm.touched.role &&
                statusChangeForm.errors.role ? (
                  <FormFeedback type="invalid" className="d-block">
                    {statusChangeForm.errors.role}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              type="button"
              disabled={isActionLoading}
              onClick={() => {
                statusChangeForm.resetForm();
                onActionCloseCb();
              }}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isActionLoading}>
              Save new Status
            </Button>
          </ModalFooter>
        </Form>
      </>
    );
  };
  return (
    <React.Fragment>
      <Modal size="sm" isOpen={isOpen}>
        {action === "pwd" && Password()}
        {action === "role" && Role()}
        {action === "status" && Status()}
      </Modal>

      {isActionSuccess && (
        <ToastifyMessage message={`${doActionData?.message}`} />
      )}
      {isActionError && (
        <ToastifyMessage
          status=""
          message={`Error: ${Object.values(doActionData?.message)}`}
        />
      )}
    </React.Fragment>
  );
};

export default UserActions;
