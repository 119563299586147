import {
  GENERATE_REPORTS,
  GENERATE_REPORTS_SUCCESS,
  GENERATE_REPORTS_FAILED,
  DASHBOARD_REPORTS,
  DASHBOARD_REPORTS_SUCCESS,
  DASHBOARD_REPORTS_FAILED,
  GENERATE_PRINTS,
  GENERATE_PRINTS_SUCCESS,
  GENERATE_PRINTS_FAILED,
  GENERATE_PRINTS_RESET,
} from "./actionTypes";

const initialState = {
  reports: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  dashboardReports: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  generatePrints: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
};

const Reports = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_REPORTS:
      state = {
        ...state,
        reports: {
          data: action.payload,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GENERATE_REPORTS_SUCCESS:
      state = {
        ...state,
        reports: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GENERATE_REPORTS_FAILED:
      state = {
        ...state,
        reports: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    case DASHBOARD_REPORTS:
      state = {
        ...state,
        dashboardReports: {
          data: action.year,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case DASHBOARD_REPORTS_SUCCESS:
      state = {
        ...state,
        dashboardReports: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case DASHBOARD_REPORTS_FAILED:
      state = {
        ...state,
        dashboardReports: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    case GENERATE_PRINTS:
      state = {
        ...state,
        generatePrints: {
          data: action.year,
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GENERATE_PRINTS_SUCCESS:
      state = {
        ...state,
        generatePrints: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GENERATE_PRINTS_FAILED:
      state = {
        ...state,
        generatePrints: {
          loading: false,
          success: false,
          error: true,
          data: action.error,
        },
      };
      break;
    case GENERATE_PRINTS_RESET:
      state = {
        ...state,
        generatePrints: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Reports;
