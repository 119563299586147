import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactDragListView from "react-drag-listview/lib/index.js";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import GoogleTranslatorInputField from "../../Components/Common/GoogleTranslatorInputField";
import {
  getBookPrintDonor,
  updateBookPrintDonor,
  getPreferences,
} from "../../store/actions";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import LoadingBar from "../../Components/Common/LoadingBar";
import moment from "moment";
import { META_TITLE } from "../../helpers/constants";

const PrepareDonorBook = () => {
  document.title = `Donors Book Preparation || ${META_TITLE}`;
  const [openModal, setOpenModal] = useState(false);
  const [getDonorInfoForModal, setDonorInfoforModal] = useState({});
  const [updatedDonorBookInfo, setUpdatedDonorBookInfo] = useState("");
  const [currentYear] = useState(new Date().getFullYear());
  const [displatButtons, setDisplayButtons] = useState(false);
  const dispatch = useDispatch();
  const {
    bookDonor = [],
    isBookDonorApiSuccess,
    isBookDonorApiLoading,
  } = useSelector((state) => ({
    bookDonor: state.Donors.bookPrepareDonors.data,
    isBookDonorApiSuccess: state.Donors.bookPrepareDonors.success,
    isBookDonorApiLoading: state.Donors.bookPrepareDonors.loading,
  }));
  const {
    updateBookInfoData = [],
    isUpdateBookInfoSuccess,
    hasUpdateBookError,
  } = useSelector((state) => ({
    updateBookInfoData: state.Donors.updateBookPrepare.data,
    isUpdateBookInfoSuccess: state.Donors.updateBookPrepare.success,
    hasUpdateBookError: state.Donors.updateBookPrepare.error,
  }));
  const [propsData, setData] = useState([]);
  const { preferenceData = {} } = useSelector((state) => ({
    preferenceData: state.Preferences.pref.data,
    preferenceDataLoading: state.Preferences.pref.loading,
  }));
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...propsData];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setData(data);
    },
    nodeSelector: "li",
    handleSelector: "i",
  };
  const openEditModalOnClose = () => {
    setOpenModal(!openModal);
    setUpdatedDonorBookInfo("");
    setDonorInfoforModal({});
  };
  const updateProductsOnClick = (key) => {
    setOpenModal(true);
    setDonorInfoforModal({ data: propsData[key], key });
  };
  const updateGlobalStateWithNewDonorProductData = (key) => {
    let dataToModify = propsData[key];
    dataToModify.products = updatedDonorBookInfo;
    setUpdatedDonorBookInfo("");
    setDonorInfoforModal({});
    setOpenModal(false);
  };
  const onBookPrefSaveOnClick = () => {
    const transformedData = propsData
      .filter(({ isDeleted }) => isDeleted !== 1)
      .map(({ donorId, products }) => ({
        id: donorId,
        products,
      }));
    dispatch(
      updateBookPrintDonor(currentYear, {
        book_info: JSON.stringify(transformedData),
      }),
    );
  };
  const handleDragStart = (e, isDeleted) => {
    if (isDeleted === 1) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    !isBookDonorApiSuccess && dispatch(getBookPrintDonor(currentYear));
  }, [isBookDonorApiSuccess]);
  useEffect(() => {
    setData(bookDonor);
  }, [bookDonor]);
  useEffect(() => {
    isUpdateBookInfoSuccess &&
      setTimeout(() => {
        dispatch(getBookPrintDonor(currentYear));
      }, 3000);
  }, [isUpdateBookInfoSuccess]);

  useEffect(() => {
    dispatch(getPreferences("preferences"));
  }, []);

  useEffect(() => {
    if (Object.keys(preferenceData).length != 0) {
      const currentYearFunction = moment(
        preferenceData.FUNCTION_YEARS[currentYear],
      );
      setDisplayButtons(
        !moment(currentYearFunction, "YYYY/MM/DD").isBefore(moment()),
      );
    }
  }, [preferenceData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Donors Book Preparation" pageTitle="Donors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {propsData?.length > 0 && (
                    <>
                      {displatButtons && (
                        <Button
                          color="danger"
                          className="mb-2 w-lg"
                          onClick={() => {
                            onBookPrefSaveOnClick();
                          }}
                        >
                          Save Changes
                        </Button>
                      )}
                      {isBookDonorApiLoading && <LoadingBar />}
                      {!isBookDonorApiLoading && (
                        <ListGroup className="col nested-list nested-sortable-handle">
                          <ReactDragListView {...dragProps}>
                            {propsData.map((item, key) => {
                              if (item?.donorId === undefined) return;
                              return (
                                <React.Fragment key={key}>
                                  <ListGroupItem
                                    className="nested-1"
                                    onMouseDown={(e) =>
                                      handleDragStart(e, item.isDeleted)
                                    }
                                  >
                                    <Row
                                      className={`${
                                        item.isDeleted == 1
                                          ? "bg-danger text-white"
                                          : ""
                                      }`}
                                      style={{
                                        cursor:
                                          item.isDeleted === 1
                                            ? "not-allowed"
                                            : "default",
                                      }}
                                    >
                                      <Col className="col-1">
                                        <i className="ri-drag-move-fill align-bottom handle me-2"></i>
                                        {item.donorId}
                                      </Col>
                                      <Col className="col-1">{key + 1}</Col>
                                      <Col className="col-2">
                                        {item?.featuredLocality_ta}
                                      </Col>
                                      <Col>{item.donorPrintNameInBook_ta}</Col>
                                      <Col className="col-4">
                                        {item.products}
                                        {item.isDeleted == 1 && (
                                          <p>Donor is in a deleted state.</p>
                                        )}
                                      </Col>
                                      <Col className="col-1">
                                        {displatButtons && (
                                          <Button
                                            color="primary"
                                            className="ms-3"
                                            disabled={item.isDeleted === 1}
                                            onClick={() => {
                                              updateProductsOnClick(key);
                                            }}
                                          >
                                            Change
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </ListGroupItem>
                                </React.Fragment>
                              );
                            })}
                          </ReactDragListView>
                        </ListGroup>
                      )}
                    </>
                  )}
                  {isBookDonorApiSuccess && propsData?.length == 0 && (
                    <>
                      <p>
                        There is no dataset available for the current year (
                        {new Date().getFullYear()}). Please clone the data from
                        last year's table in database and make modifications as
                        necessary.
                      </p>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {openModal && (
        <Modal
          size="md"
          isOpen={openModal}
          toggle={() => {
            openEditModalOnClose();
          }}
        >
          <ModalHeader
            toggle={() => {
              openEditModalOnClose();
            }}
          >
            Update product info
          </ModalHeader>
          <div className="modal-body">
            <Row>
              <Col lg={12} className="mb-3">
                <strong>Donor:</strong>{" "}
                {`${getDonorInfoForModal.data.donorId}, ${getDonorInfoForModal.data.donorPrintNameInBook_ta}`}
              </Col>
              <Col lg={12}>
                <strong>Update Product:</strong>
                <GoogleTranslatorInputField
                  onChange={(e) => {
                    setUpdatedDonorBookInfo(e.target.value || undefined);
                  }}
                  id="product_book_info"
                  defaultValue={getDonorInfoForModal.data.products}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              className="ms-3"
              onClick={() => {
                updateGlobalStateWithNewDonorProductData(
                  getDonorInfoForModal.key,
                );
              }}
            >
              Update Donor
            </Button>
          </div>
        </Modal>
      )}
      {hasUpdateBookError && (
        <ToastifyMessage status="" message={`Error: Something went wrong.`} />
      )}
      {isUpdateBookInfoSuccess && (
        <ToastifyMessage message={`${updateBookInfoData?.message}.`} />
      )}
    </React.Fragment>
  );
};

export default PrepareDonorBook;
