import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { useSelector } from "react-redux";

const GoogleTranslatorInputField = (props) => {
  const [trigger, setTrigger] = useState(true);
  const [isBrowserCompatible, setIsBrowserCompatible] = useState(true);
  const { preferences } = useSelector((state) => ({
    preferences: state.Preferences.pref?.data,
    isPreferencesLoading: state.Preferences.pref?.loading,
    isPreferencesSuccess: state.Preferences.pref?.success,
  }));
  const GoogleTranslateOnLoad = () => {
    if (!trigger) return;
    const google = window.google;
    if (google.elements.transliteration.isBrowserCompatible()) {
      const options = {
        sourceLanguage: "en",
        destinationLanguage: ["ta"],
        shortcutKey: "ctrl+g",
        transliterationEnabled: true,
      };
      const control =
        new google.elements.transliteration.TransliterationControl(options);
      control.makeTransliteratable([props?.id]);
      setTrigger(false);
      setIsBrowserCompatible(true);
    } else setIsBrowserCompatible(false);
  };

  const onBlurEvent = () => {
    props?.onBlur && props?.onBlur;
    removeDivBlocks(".inputapi-popupeditor");
    removeDivBlocks(".inputapi-multisegmentpopupeditor");
  };

  const removeDivBlocks = (sel) => {
    document.querySelectorAll(sel).forEach((el) => el.remove());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        GoogleTranslateOnLoad();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    preferences?.enableTranslator && fetchData();
  }, [props?.id, preferences?.enableTranslator]);

  // useEffect(() => {
  //   console.log(preferences);
  //   !isPreferencesSuccess && dispatch(getPreferences("preferences"));
  // }, [isPreferencesSuccess]);

  return (
    <React.Fragment>
      {isBrowserCompatible ? (
        <>
          <Input {...props} onBlur={onBlurEvent} />
          {preferences?.enableTranslator && (
            <span className="text-decoration-underline fst-italic text-muted">
              <small>
                (Use <strong>Ctrl+G</strong> to toggle between English &amp;
                Tamil)
              </small>
            </span>
          )}
        </>
      ) : (
        "Sorry! Your browser does not support transliteration"
      )}
    </React.Fragment>
  );
};

GoogleTranslatorInputField.propTypes = {
  children: PropTypes.object.isRequired,
};

export default GoogleTranslatorInputField;
