import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import { GET_FILEMANAGER } from "./actionTypes";
import { getFileManagerSuccess, getFileManagerFailed } from "./actions";

const api = new APIClient();

function* getFileManagerData() {
  try {
    const getFiles = () => api.get(`${"/filemanager"}`);
    const response = yield call(getFiles);
    const { data, status } = response;
    if (status === 200) {
      yield put(getFileManagerSuccess(data));
    } else {
      yield put(getFileManagerFailed(response));
    }
  } catch (error) {
    yield put(getFileManagerFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(GET_FILEMANAGER, getFileManagerData);
}

function* fmSagaOutput() {
  yield all([fork(watchUserRegister)]);
}

export default fmSagaOutput;
