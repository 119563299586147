import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth

import AuthSaga from "./auth/login/saga";

import donorsSaga from "./donors/saga";
import donorTypesSaga from "./donorTypes/saga";
import localitySaga from "./locality/saga";
import preferencesSagaOutput from "./preferences/saga";
import productsSaga from "./products/saga";
import reportsaga from "./reports/saga";
import notesSagaOutput from "./notes/saga";
import fmSagaOutput from "./filemanager/saga";
import usersSagaOutput from "./auth/profile/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(donorsSaga),
    fork(donorTypesSaga),
    fork(localitySaga),
    fork(preferencesSagaOutput),
    fork(productsSaga),
    fork(reportsaga),
    fork(notesSagaOutput),
    fork(usersSagaOutput),
    fork(fmSagaOutput),
  ]);
}
