import {
  GET_CACHED_ADDRESS,
  GET_CACHED_ADDRESS_SUCCESS,
  GET_CACHED_ADDRESS_FAILED,
  GET_RAW_ADDRESS,
  GET_RAW_ADDRESS_SUCCESS,
  GET_RAW_ADDRESS_FAILED,
  GET_PINCODE,
  GET_PINCODE_SUCCESS,
  GET_PINCODE_FAILED,
} from "./actionTypes";

export const getCachedAddress = () => {
  return {
    type: GET_CACHED_ADDRESS,
  };
};

export const getCachedAddressSuccess = (cachedAddress) => {
  return {
    type: GET_CACHED_ADDRESS_SUCCESS,
    cachedAddress,
  };
};

export const getCachedAddressFailed = (error) => {
  return {
    type: GET_CACHED_ADDRESS_FAILED,
    error,
  };
};

export const getRawAddress = (params, requestType) => {
  return {
    type: GET_RAW_ADDRESS,
    params,
    requestType,
  };
};

export const getRawAddressSuccess = (rawAddress, requestType) => {
  return {
    type: GET_RAW_ADDRESS_SUCCESS,
    rawAddress,
    requestType,
  };
};

export const getRawAddressFailed = (error) => {
  return {
    type: GET_RAW_ADDRESS_FAILED,
    error,
  };
};

export const getPincode = (pincode) => {
  return {
    type: GET_PINCODE,
    pincode,
  };
};

export const getPincodeSuccess = (pincodeList) => {
  return {
    type: GET_PINCODE_SUCCESS,
    pincodeList,
  };
};

export const getPincodeFailed = (error) => {
  return {
    type: GET_PINCODE_FAILED,
    error,
  };
};
