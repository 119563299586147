import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import {
  GENERATE_REPORTS,
  DASHBOARD_REPORTS,
  GENERATE_PRINTS,
} from "./actionTypes";
import {
  generateReportSuccess,
  generateReportFailed,
  dashboardReportSuccess,
  dashboardReportFailed,
  generatePrintsSuccess,
  generatePrintsFailed,
} from "./actions";

const api = new APIClient();

function* generateReportSaga(action) {
  const { year, product = "all", product_type } = action?.payload;
  try {
    const getDonors = () =>
      api.get(
        `/receipts/reports?donors=all&year=${year}&product=${product}&product_type=${product_type}`,
      );
    const response = yield call(getDonors);
    const { data, status } = response;
    if (status === 200) {
      yield put(generateReportSuccess(data));
    } else {
      yield put(generateReportFailed(response));
    }
  } catch (error) {
    yield put(generateReportFailed(error));
  }
}

function* dashboardReportSaga(action) {
  const { year } = action;
  try {
    const getDashboardData = () => api.get(`/receipts/sum?year=${year}`);
    const response = yield call(getDashboardData);
    const { data, status } = response;
    if (status === 200) {
      yield put(dashboardReportSuccess(data));
    } else {
      yield put(dashboardReportFailed(response));
    }
  } catch (error) {
    yield put(dashboardReportFailed(error));
  }
}

function* printSaga(action) {
  const { params } = action;
  try {
    const getPrintData = () => api.get(`/prints?${params}`);
    const response = yield call(getPrintData);
    const { data, status } = response;
    if (status === 200) {
      yield put(generatePrintsSuccess(data));
    } else {
      yield put(generatePrintsFailed(response));
    }
  } catch (error) {
    yield put(generatePrintsFailed(error));
  }
}

export function* watchReportsSaga() {
  yield takeEvery(GENERATE_REPORTS, generateReportSaga);
  yield takeEvery(DASHBOARD_REPORTS, dashboardReportSaga);
  yield takeEvery(GENERATE_PRINTS, printSaga);
}

function* reportsaga() {
  yield all([fork(watchReportsSaga)]);
}

export default reportsaga;
