import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { useSelector, useDispatch } from "react-redux";
import Grid from "../../Components/Grid";
import { SOFTWARE_STARTED_YEAR } from "../../helpers/constants";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getRawInvoice } from "../../store/actions";
import LoadingBar from "../../Components/Common/LoadingBar";
import { formatINR } from "../../helpers/utility";
import moment from "moment";

const InvoiceListRaw = () => {
  const dispatch = useDispatch();
  const [defaultCurrentYear] = useState(new Date().getFullYear());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { invoiceRawData, invoiceRawDataSuccess, invoiceRawDataLoading } =
    useSelector((state) => ({
      invoiceRawData: state.Donors.invoiceRaw.data,
      invoiceRawDataSuccess: state.Donors.invoiceRaw.success,
      invoiceRawDataError: state.Donors.invoiceRaw.error,
      invoiceRawDataLoading: state.Donors.invoiceRaw.loading,
    }));

  useEffect(() => {
    dispatch(getRawInvoice(currentYear));
  }, [currentYear]);

  const gridColumns = [
    {
      title: "Invoice Number",
      data: "invoiceId",
    },
    {
      title: "Donate By",
      data: "donorName_en",
    },
    {
      title: "Type / Product / Quantity / Remarks / Amount / Reversed",
      data: "invoiceAmount",
    },
    {
      title: "Invoice Date",
      data: "invoiceDate",
    },
  ];
  const columnDefs = [
    {
      targets: 2,
      createdCell: (
        td,
        cell,
        {
          invoiceType,
          productName_en,
          productName_ta,
          invoiceQuantity,
          invoiceRemarks,
          invoiceAmount,
          isInvoiceReversed,
        },
      ) =>
        ReactDOM.createRoot(td).render(
          <>
            {`${invoiceType} / ${productName_en} / ${productName_ta} / ${invoiceQuantity} ${
              invoiceRemarks != "" ? "/ " + invoiceRemarks : ""
            } / ${formatINR(invoiceAmount)} ${
              isInvoiceReversed == "Y" ? "/ Cancelled" : ""
            }`}
          </>,
        ),
    },
    {
      targets: 1,
      createdCell: (td, cell, { phoneNumber, donorName_ta }) =>
        ReactDOM.createRoot(td).render(
          <>{`${cell}, ${donorName_ta}, ${phoneNumber}`}</>,
        ),
    },
    {
      targets: 3,
      createdCell: (td, cell) =>
        ReactDOM.createRoot(td).render(<>{moment(cell).format("lll")}</>),
    },
  ];
  const yearOptions = () => {
    var arr = [];
    for (let year = SOFTWARE_STARTED_YEAR; year <= defaultCurrentYear; year++) {
      arr.push(
        <option key={year} value={year}>
          {year}
        </option>,
      );
    }
    return arr;
  };
  const buttons = (
    <>
      <Button
        color="primary"
        onClick={() => dispatch(getRawInvoice(currentYear))}
      >
        <i className="ri-refresh-line me-1 align-middle fw-bold"></i>
        Refresh
      </Button>
      <label htmlFor="selectInvoiceYear" className="normal-label ms-3">
        Select Year
      </label>
      <select
        id="selectInvoiceYear"
        className="form-select ms-3"
        style={{ width: "auto", display: "inline-block" }}
        defaultValue={currentYear}
        onChange={(e) => setCurrentYear(e.target.value)}
      >
        {yearOptions()}
      </select>
    </>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Invoice List" pageTitle="Donors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="InvoiceTable">
                    {invoiceRawDataSuccess && (
                      <Grid
                        data={invoiceRawData}
                        columns={gridColumns}
                        ordering={false}
                        columnDefs={columnDefs}
                        buttons={buttons}
                      />
                    )}
                    {invoiceRawDataLoading && <LoadingBar />}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceListRaw;
