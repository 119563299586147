import React, { useEffect, useState } from "react";
import { JsonEditor } from "json-edit-react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  UncontrolledAlert,
  Button,
} from "reactstrap";
import { getPreferences, savePreferences } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from "../../Components/Common/LoadingBar";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { META_TITLE } from "../../helpers/constants";

const Preferences = () => {
  document.title = `Preferences || ${META_TITLE}`;
  const dispatch = useDispatch();
  const [dataToPass, setDataToPass] = useState({});
  const { preferenceData, preferenceDataSucess, preferenceDataLoading } =
    useSelector((state) => ({
      preferenceData: state.Preferences.pref.data,
      preferenceDataLoading: state.Preferences.pref.loading,
      preferenceDataSucess: state.Preferences.pref.success,
    }));
  useEffect(() => {
    dispatch(getPreferences("preferences"));
  }, []);
  useEffect(() => {
    setDataToPass(preferenceData);
  }, [preferenceData]);
  const handleJsonChange = ({ newData }) => {
    setDataToPass(newData);
  };
  const onPageReferesh = () => {
    dispatch(getPreferences("preferences"));
  };
  const {
    PreferencesSaveData,
    isPreferencesSaveSuccess = false,
    isPreferencesSaveDataLoading,
    isPreferencesSaveDataError,
  } = useSelector((state) => ({
    PreferencesSaveData: state.Preferences.savePref.data,
    isPreferencesSaveSuccess: state.Preferences.savePref.success,
    isPreferencesSaveDataLoading: state.Preferences.savePref.loading,
    isPreferencesSaveDataError: state.Preferences.savePref.error,
  }));
  useEffect(() => {
    isPreferencesSaveSuccess && dispatch(getPreferences("preferences"));
  }, [isPreferencesSaveSuccess]);
  const savePreferenceOnClick = () => {
    dispatch(savePreferences({ preference: JSON.stringify(dataToPass) }));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Preferences" pageTitle="Portal Preferences" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <UncontrolledAlert
                    color="danger"
                    className="alert-solid alert-label-icon mb-3"
                  >
                    <i className="ri-error-warning-line label-icon"></i>
                    <strong>Danger</strong> - Don't touch the below values
                    without knowledge.
                  </UncontrolledAlert>
                  {isPreferencesSaveDataLoading ||
                    (preferenceDataLoading && <LoadingBar />)}
                  {preferenceDataSucess && (
                    <JsonEditor
                      data={preferenceData}
                      onUpdate={handleJsonChange}
                      maxWidth="100%"
                    />
                  )}
                  <Row>
                    <Col md={6} className="mt-3 text-start">
                      <Button
                        color="primary"
                        disable={isPreferencesSaveDataLoading}
                        onClick={onPageReferesh}
                      >
                        Refresh
                      </Button>
                    </Col>
                    <Col md={6} className="mt-3 text-end">
                      <Button
                        color="primary"
                        disable={isPreferencesSaveDataLoading}
                        onClick={savePreferenceOnClick}
                      >
                        Save Preferences
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isPreferencesSaveDataError && (
            <ToastifyMessage
              status=""
              message={`Error: ${PreferencesSaveData?.message}.`}
            />
          )}
          {isPreferencesSaveSuccess && (
            <ToastifyMessage message={`${PreferencesSaveData?.message}`} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Preferences;
