import { call, put, takeEvery } from "redux-saga/effects";
import { APIClient } from "../../../helpers/api_helper";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

const api = new APIClient();

function* loginUser({ payload: { user } }) {
  const postJwtLogin = (data) => api.create("/auth/login", data, true);
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    });
    const { data, status } = response;
    if (status === 200) {
      localStorage.setItem("authUser", JSON.stringify(data));
      //history.push("/dashboard");
      yield put(loginSuccess(response));
      window.location.href = "/dashboard";
      //window.location.reload();
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
