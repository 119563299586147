import React, { useState, useEffect } from "react";

const Navdata = () => {
  const [iscurrentState, setIscurrentState] = useState("");
  const [donorsDropDown, setDonorsDropDown] = useState("");
  const [adminDropDown, setAdminDropDOwn] = useState("");
  const [homeDropDown, setHomeDropDown] = useState("");
  const [reports, setReports] = useState("");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }
  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    setDonorsDropDown(!(iscurrentState !== "donors-manager"));
  }, [history, iscurrentState, donorsDropDown, adminDropDown]);
  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "home-dashbaord",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      role: 0,
      link: "/dashboard",
      stateVariables: homeDropDown,
      click: function (e) {
        setHomeDropDown(!homeDropDown);
        setIscurrentState("home-dashbaord");
        e.preventDefault();
        updateIconSidebar(e);
      },
    },
    {
      id: "donors-manager",
      label: "Donors Manager",
      icon: "ri-hand-coin-line",
      role: 0,
      link: "/#",
      stateVariables: donorsDropDown,
      click: function (e) {
        setDonorsDropDown(!donorsDropDown);
        setIscurrentState("donors-manager");
        e.preventDefault();
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "donors-list",
          label: "Donors List",
          link: "/donors-list",
          parentId: "donors-manager",
        },
        {
          id: "add-donor",
          label: "Create Donor",
          link: "/add-donor",
          parentId: "donors-manager",
        },
        {
          id: "invoice-list",
          label: "Receipts",
          link: "/invoice-list",
          parentId: "donors-manager",
        },
        {
          id: "locality-list",
          label: "Pincode Search",
          link: "/locality-list",
          parentId: "donors-manager",
        },
      ],
    },
    {
      id: "managers",
      label: "Managers",
      icon: "ri-booklet-line",
      role: 1,
      link: "/#",
      stateVariables: adminDropDown,
      click: function (e) {
        e.preventDefault();
        setAdminDropDOwn(!adminDropDown);
        setIscurrentState("managers");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "note-book",
          label: "Note Book",
          link: "/note-book",
          parentId: "managers",
        },

        {
          id: "donors-types",
          label: "Donors Types",
          link: "/donors-types",
          parentId: "managers",
        },
        {
          id: "products",
          label: "Products",
          link: "/products",
          parentId: "managers",
        },
        {
          id: "users",
          label: "Users",
          link: "/users",
          parentId: "managers",
        },
        {
          id: "downloads",
          label: "Downloads",
          link: "/downloads",
          parentId: "managers",
        },
      ],
    },
    {
      id: "reports",
      label: "Reports",
      icon: "ri-file-chart-line",
      role: 1,
      link: "/#",
      stateVariables: reports,
      click: function (e) {
        e.preventDefault();
        setReports(!reports);
        setIscurrentState("reports");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "generate-report",
          label: "Generate Reports",
          link: "/generate-report",
          parentId: "reports",
        },
        {
          id: "generate-print",
          label: "Generate Prints",
          link: "/print-outs",
          parentId: "reports",
        },
      ],
    },
    {
      id: "administration",
      label: "Administration",
      icon: "ri-user-settings-line",
      role: 1,
      link: "/#",
      stateVariables: adminDropDown,
      click: function (e) {
        e.preventDefault();
        setAdminDropDOwn(!adminDropDown);
        setIscurrentState("administration");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "insights",
          label: "Insights",
          link: "/insights",
          parentId: "administration",
        },
        {
          id: "preferences",
          label: "Preferences",
          link: "/preferences",
          parentId: "administration",
        },
        {
          id: "donors-book-preparation",
          label: "Prepare Donor Book",
          link: "/prepare-donor-book",
          parentId: "administration",
        },
        {
          id: "invoice-raw",
          label: "Invoice - Raw",
          link: "/invoice-raw",
          parentId: "administration",
        },
        {
          id: "reject-list",
          label: "Donors Reject Reason",
          link: "/reject-list",
          parentId: "administration",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
