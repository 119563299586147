import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "../../Components/Grid";
import { Card, CardBody, Col, Container, Row, Badge, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getRejectList } from "../../store/actions";
import LoadingBar from "../../Components/Common/LoadingBar";
import ReactDOM from "react-dom/client";
import moment from "moment";
import { META_TITLE } from "../../helpers/constants";

const RejectListing = () => {
  document.title = `Rejection List || ${META_TITLE}`;
  const dispatch = useDispatch();
  const gridColumns = [
    {
      data: "id",
      title: "Reject. ID",
      width: "100px",
    },
    {
      data: "donorId",
      title: "Reg. ID",
      width: "100px",
    },
    {
      data: "donorName_ta",
      title: "Name English",
    },
    {
      data: "donorName_en",
      title: "Name English",
      visible: false,
    },
    {
      data: "reason",
      title: "Reject Reason",
    },
    {
      data: "reasonType",
      title: "Rejection Type",
      width: "135px",
    },
    { data: "rejectedDate", title: "Rejected Date" },
    { data: "status", title: "Current Status", width: "135px" },
  ];
  const {
    rejectList = [],
    rejectListSuccess,
    rejectListLoading,
  } = useSelector((state) => ({
    rejectList: state.Donors?.rejectList.data || [],
    rejectListSuccess: state.Donors?.rejectList.success,
    rejectListLoading: state.Donors?.rejectList.loading,
    rejectListHasError: state.Donors?.rejectList.error,
  }));
  const columnDefs = [
    {
      targets: [5],
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <Badge
              color={cellData == "postal" ? "primary" : "secondary"}
              className="p-2 text-uppercase"
            >
              {cellData}
            </Badge>
          </>,
        ),
    },
    {
      targets: [6],
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: `${moment(cellData).format("lll")}`,
              }}
            ></span>
          </>,
        ),
    },
    {
      targets: [7],
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <Badge
              color={cellData == 1 ? "danger" : "success"}
              className="p-2 text-uppercase"
            >
              {cellData == 1 ? "Rejected" : "Readded"}
            </Badge>
          </>,
        ),
    },
  ];

  const buttons = (
    <>
      <Button
        color="primary"
        className="bg-animation w-xs-100 mb-2 me-2"
        data-text="Refresh"
        onClick={() => {
          refreshOnClick();
        }}
      >
        <i className="ri-refresh-line me-1 align-middle fw-bold"></i>
        <span>Refresh</span>
      </Button>
    </>
  );

  const refreshOnClick = () => {
    dispatch(getRejectList());
  };

  useEffect(() => {
    !rejectListSuccess && dispatch(getRejectList());
  }, [rejectListSuccess]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Rejection List" pageTitle="Donors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="position-relative">
                  <Col className="w-100 donor-grid">
                    {rejectListSuccess && (
                      <Grid
                        columns={gridColumns}
                        data={rejectList}
                        columnDefs={columnDefs}
                        sortOrder={[0, "desc"]}
                        buttons={buttons}
                      />
                    )}
                    {rejectListLoading && <LoadingBar />}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RejectListing;
