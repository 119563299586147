import {
  GET_PREFERENCES,
  PREFERENCES_SUCCESS,
  PREFERENCES_FAILED,
  RESET_PREFERENCES,
  SAVE_PREFERENCES,
  SAVE_PREFERENCES_SUCCESS,
  SAVE_PREFERENCES_FAILED,
  SAVE_RESET_PREFERENCES,
} from "./actionTypes";

const initialState = {
  pref: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  savePref: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
};

const Preferences = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREFERENCES:
      state = {
        ...state,
        pref: {
          loading: true,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case PREFERENCES_SUCCESS:
      state = {
        ...state,
        pref: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case PREFERENCES_FAILED:
      state = {
        ...state,
        pref: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case RESET_PREFERENCES:
      state = {
        ...state,
        pref: {
          loading: false,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case SAVE_PREFERENCES:
      state = {
        ...state,
        savePref: {
          loading: true,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case SAVE_PREFERENCES_SUCCESS:
      state = {
        ...state,
        savePref: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case SAVE_PREFERENCES_FAILED:
      state = {
        ...state,
        savePref: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case SAVE_RESET_PREFERENCES:
      state = {
        ...state,
        savePref: {
          loading: false,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Preferences;
