import React, { forwardRef } from "react";
import "./print.scss";
import { Col, Container, Row, Table } from "reactstrap";
import PropTypes from "prop-types";
import { sortBy } from "lodash";

const BookPrint = forwardRef((props, ref) => {
  const { dataToPrint, isLocalityRequired, requestedData } = props;
  return (
    <React.Fragment>
      <div ref={ref}>
        <Container fluid>
          <Row className="gy-5">
            <Col lg={12}>
              <p>{`${JSON.stringify(requestedData)}, ${
                dataToPrint.length
              } records`}</p>
              <Table className="table mb-0 table-bordered page-break">
                <thead>
                  <tr className="bg-dark text-white">
                    <td
                      className="text-start fw-bold"
                      style={{ width: "100px" }}
                    >
                      பதிவு எண்
                    </td>
                    <td className="text-start fw-bold">முகவரி</td>
                    {(isLocalityRequired ||
                      requestedData?.filterBy === "printed_in_book") && (
                      <td className="text-start fw-bold">பொருள்கள்</td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortBy(dataToPrint, "donorId")?.map(
                    (
                      {
                        donorId,
                        donorPrintNameInBook_ta,
                        donorPhone,
                        featuredLocality_ta,
                        products,
                        pincode,
                        book_printed_products = "",
                      },
                      key,
                    ) => (
                      <tr key={`${key}`}>
                        <td className="text-start">
                          {`${donorId}`}
                          <br />
                          {requestedData?.donor_type === "product" &&
                            featuredLocality_ta != "" &&
                            `(${featuredLocality_ta})`}
                        </td>
                        <td className="text-start">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                donorPrintNameInBook_ta +
                                (pincode > 0 ? ", " + pincode : "") +
                                (donorPhone && donorPhone.trim() !== ""
                                  ? ", செல்:- " + donorPhone
                                  : ""),
                            }}
                          ></span>
                        </td>
                        {(requestedData?.filterBy === "lastyear_donors" ||
                          requestedData?.filterBy === "currentyear_donors" ||
                          requestedData?.filterBy === "printed_in_book") && (
                          <td className="text-start">{`${
                            requestedData?.filterBy === "printed_in_book"
                              ? book_printed_products
                              : products
                          }`}</td>
                        )}
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
});
BookPrint.displayName = "BookPrint";

BookPrint.propTypes = {
  dataToPrint: PropTypes.array.isRequired,
  isLocalityRequired: PropTypes.bool.isRequired,
  requestedData: PropTypes.array.isRequired,
};

export default BookPrint;
