import React, { forwardRef } from "react";
import "./print.scss";
import "./PostalAddress.scss";
import { Col, Container, Row } from "reactstrap";
import { preparePostalAddress } from "../helpers/utility";
import PropTypes from "prop-types";

const PostalAddressPrint = forwardRef((props, ref) => {
  const { dataToPrint, args } = props;

  return (
    <React.Fragment>
      <div ref={ref}>
        <Container fluid>
          <Row className="gy-5">
            {dataToPrint?.map((data, key) => {
              if (data?.sendPostal == 1) return;
              return (
                <Col lg={4} key={key}>
                  <span
                    className="text-capitalize"
                    dangerouslySetInnerHTML={{
                      __html: preparePostalAddress(data),
                    }}
                  ></span>
                  . <br />{" "}
                  <span className="fst-italic">
                    Reg. Id: {`${data?.donorId}`}
                  </span>
                </Col>
              );
            })}
          </Row>
          <p className="pt-5">
            {JSON.stringify(args)}, {dataToPrint.length} records
          </p>
        </Container>
      </div>
    </React.Fragment>
  );
});
PostalAddressPrint.displayName = "PostalAddressPrint";

PostalAddressPrint.propTypes = {
  dataToPrint: PropTypes.array.isRequired,
  args: PropTypes.object.isRequired,
};

export default PostalAddressPrint;
