import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, Table } from "reactstrap";
import { getSingleDonor } from "../../store/actions";
import LoadingBar from "../../Components/Common/LoadingBar";
import moment from "moment";
import { preparePostalAddress } from "../../helpers/utility";
import PropTypes from "prop-types";

const ViewModal = ({ openModal, openViewModalOnClick, donorid, donorType }) => {
  const dispatch = useDispatch();

  const { singleDonor, isSingleDonorLoading, isSingleApiSuccess } = useSelector(
    (state) => ({
      singleDonor: state.Donors.donor.data,
      isSingleApiSuccess: state.Donors.donor.success,
      isSingleDonorLoading: state.Donors.donor.loading,
    }),
  );
  const {
    donorId = "",
    donorName_en,
    donorName_ta,
    donorPrintNameInBook_ta,
    donorEmail,
    donorPhone,
    donorType: typeToPrint,
    featuredLocality_ta,
    lastModifiedDate,
    lastModifiedBy,
    addedDate,
    addedBy,
  } = { ...singleDonor };
  useEffect(() => {
    openModal && dispatch(getSingleDonor(donorid, donorType));
  }, [donorid, openModal]);
  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={openModal}
        toggle={() => {
          openViewModalOnClick();
        }}
      >
        <ModalHeader
          id="DonorView"
          toggle={() => {
            openViewModalOnClick();
          }}
        >
          Donor View
        </ModalHeader>
        <div className="modal-body">
          {isSingleDonorLoading && <LoadingBar />}
          {isSingleApiSuccess && (
            <div className="table-responsive">
              <Table className="table-bordered table-nowrap align-middle mb-0">
                <tbody>
                  <tr>
                    <td>
                      <strong>Donor ID</strong>
                    </td>
                    <td>{`${donorId}`}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Name</strong>
                    </td>
                    <td>
                      {donorName_ta && `${donorName_ta}`} /{" "}
                      {donorName_en && `${donorName_en}`}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Book Print</strong>
                    </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: donorPrintNameInBook_ta,
                        }}
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Postal Address</strong>
                    </td>
                    <td>
                      <span
                        className="text-capitalize"
                        dangerouslySetInnerHTML={{
                          __html: preparePostalAddress(singleDonor),
                        }}
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <strong>Phone: </strong>
                      {donorPhone !== "" ? donorPhone : "--"},{" "}
                      <strong>Email:</strong>{" "}
                      {donorEmail !== "" ? donorEmail : "--"},{" "}
                      <strong>Donor Type:</strong> {`${typeToPrint}`},{" "}
                      <strong>Feature Locality:</strong>{" "}
                      {featuredLocality_ta !== "" ? featuredLocality_ta : "--"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <strong>Added By:</strong> {addedBy && `${addedBy}`},{" "}
                      <strong>Added On:</strong>{" "}
                      {addedBy && `${moment(addedDate).format("lll")}`},{" "}
                      <strong>Last Modified by:</strong>{" "}
                      {lastModifiedBy !== "" ? lastModifiedBy : "--"},{" "}
                      <strong>Last Modified On: </strong>{" "}
                      {lastModifiedDate !== "0000-00-00 00:00:00"
                        ? lastModifiedDate
                        : "--"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light fw-medium"
            onClick={() => {
              openViewModalOnClick();
            }}
          >
            <i className="ri-close-line me-1 align-middle"></i> Close
          </Link>
        </div>
      </Modal>
    </React.Fragment>
  );
};

ViewModal.propTypes = {
  openModal: PropTypes.bool,
  openViewModalOnClick: PropTypes.func,
  donorid: PropTypes.any,
  donorType: PropTypes.string,
};

export default ViewModal;
