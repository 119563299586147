import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";

import PropTypes from "prop-types";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading, auth_token } = useProfile();
  useEffect(() => {
    if (!userProfile && loading && !auth_token) {
      dispatch(logoutUser());
    }
  }, [auth_token, userProfile, loading, dispatch]);

  /*
    redirect is un-auth access protected routes via url
    */

  if (!userProfile && loading && !auth_token) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

AuthProtected.propTypes = {
  location: PropTypes.any,
  children: PropTypes.any.isRequired,
  component: PropTypes.any,
};

AccessRoute.propTypes = {
  component: PropTypes.any,
};
export { AuthProtected, AccessRoute };
