import React from "react";
import PropTypes from "prop-types";

const LightDark = ({ onChangeLayoutMode }) => {
  const onChangeOfTheme = () => {
    const theme = localStorage.getItem("khills-theme");
    localStorage.setItem("khills-theme", theme == "light" ? "dark" : "light");
    onChangeLayoutMode(theme);
  };
  return (
    <div className="ms-1 header-item d-none d-sm-flex">
      <button
        onClick={() => onChangeOfTheme()}
        type="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
      >
        <i className="ri-moon-line fs-22"></i>
      </button>
    </div>
  );
};

LightDark.propTypes = {
  onChangeLayoutMode: PropTypes.func.isRequired,
};

export default LightDark;
