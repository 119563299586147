import React, { useEffect, useState } from "react";
import Grid from "../../Components/Grid";
import { useSelector, useDispatch } from "react-redux";
import { getDonorTypes } from "../../store/actions";
import AddEdit from "./AddEdit";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import LoadingBar from "../../Components/Common/LoadingBar";
import ReactDOM from "react-dom/client";
import { has, isEmpty } from "lodash";
import { META_TITLE } from "../../helpers/constants";

const DonorTypes = () => {
  document.title = `Donors Types || ${META_TITLE}`;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("NEW");
  const [dataToFillForEdit, setDataToFillForEdit] = useState({});

  const { donorTypes, donorLoading, isDonorSuccess } = useSelector((state) => ({
    donorTypes: state.DonorTypes.donorTypes,
    donorLoading: state.DonorTypes.loading,
    isDonorSuccess: state.DonorTypes.success,
  }));

  useEffect(() => {
    dispatch(getDonorTypes());
  }, []);

  const gridColumns = [
    {
      data: "id",
      title: "Type ID",
    },
    {
      data: "name",
      title: "Type Name",
    },
    {
      data: "slug",
      title: "Type Slug",
    },
    {
      title: "Actions",
      data: "id",
      width: "120px",
      formatter: (cell, row) => (
        <>
          <Button
            color="primary"
            className="bg-gradient"
            disabled={true}
            onClick={() => {
              editOnClick(row.id);
            }}
          >
            {" "}
            Edit{" "}
          </Button>
        </>
      ),
    },
  ];

  const columnDefs = [
    {
      targets: 3,
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <Button
              color="primary"
              className="bg-gradient"
              disabled={true}
              onClick={() => {
                editOnClick(cellData);
              }}
            >
              {" "}
              Edit{" "}
            </Button>
          </>,
        ),
    },
  ];

  const editOnClick = (donorTypeid) => {
    const typeArray = donorTypes.filter((type) => type.id === donorTypeid);
    setOpenModal(true);
    setMode("EDIT");
    setDataToFillForEdit(typeArray[0]);
  };

  const addNewOnClick = () => {
    setOpenModal(true);
    setMode("NEW");
  };
  const openViewModalOnClick = () => {
    setOpenModal(!openModal);
  };
  const callbackMethod = () => {
    setOpenModal(false);
    dispatch(getDonorTypes());
  };
  const { successMessage } = useSelector((state) => ({
    successMessage: state.DonorTypes.createDonor.data.message,
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Donors Types" pageTitle="Donors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col
                    lg={12}
                    style={{ position: "absolute", right: "17px", zIndex: 99 }}
                    className="d-flex flex-row-reverse"
                  >
                    <Button
                      color="primary"
                      className="bg-gradient d-none"
                      onClick={() => {
                        addNewOnClick();
                      }}
                    >
                      {" "}
                      Add New Type{" "}
                    </Button>
                  </Col>
                  <div id="table-gridjs">
                    {isDonorSuccess &&
                      !isEmpty(donorTypes) &&
                      has(donorTypes[0], "id") && (
                        <Grid
                          columns={gridColumns}
                          data={donorTypes}
                          columnDefs={columnDefs}
                        />
                      )}
                    {donorLoading && <LoadingBar />}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <AddEdit
          openModal={openModal}
          openViewModalOnClick={openViewModalOnClick}
          callbackMethod={callbackMethod}
          mode={mode}
          dataToFill={dataToFillForEdit}
        />
      </div>
      {successMessage && <ToastifyMessage message={`${successMessage}`} />}
    </React.Fragment>
  );
};

export default DonorTypes;
