import {
  GET_DONOR_TYPES,
  GET_DONOR_TYPES_SUCCESS,
  DONOR_TYPES_FAILED,
  CREATE_DONOR_TYPES,
  CREATE_DONOR_TYPES_SUCCESS,
  CREATE_TYPES_FAILED,
  UPDATE_DONOR_TYPES,
  UPDATE_DONOR_TYPES_SUCCESS,
  UPDATE_TYPES_FAILED,
} from "./actionTypes";

export const getDonorTypes = (queryParams = "") => {
  return {
    type: GET_DONOR_TYPES,
    queryParams,
  };
};

export const getDonorTypesSuccess = (types) => {
  return {
    type: GET_DONOR_TYPES_SUCCESS,
    payload: types,
  };
};

export const donorTypesFailed = (types) => {
  return {
    type: DONOR_TYPES_FAILED,
    payload: types,
  };
};

export const createDonorType = (payload) => {
  return {
    type: CREATE_DONOR_TYPES,
    payload,
  };
};

export const createDonorTypeSuccess = (payload) => {
  return {
    type: CREATE_DONOR_TYPES_SUCCESS,
    payload,
  };
};

export const createDonorTypeFailed = (error) => {
  return {
    type: CREATE_TYPES_FAILED,
    error,
  };
};

export const updateDonorType = (donortypeid, payload) => {
  return {
    type: UPDATE_DONOR_TYPES,
    payload,
    donortypeid,
  };
};

export const updateDonorTypeSuccess = (payload) => {
  return {
    type: UPDATE_DONOR_TYPES_SUCCESS,
    payload,
  };
};

export const updateDonorTypeFailed = (error) => {
  return {
    type: UPDATE_TYPES_FAILED,
    error,
  };
};
