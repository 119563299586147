import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Form,
  ModalHeader,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { deleteDonor, deleteDonorReset } from "../../store/actions";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import { useSelector, useDispatch } from "react-redux";

const Reject = ({
  donorData,
  onCloseClickOnSureDialog,
  reloadDonorsPage,
  donorType = "",
  action,
}) => {
  const dispatch = useDispatch();
  const { donorId, totalReceipts, sendPostal, donorName_ta, donorName_en } =
    donorData;
  const [areSureClose, setAreSureClose] = useState(
    totalReceipts != null || totalReceipts > 0,
  );
  const [aresureProceed, setAreSureProceed] = useState(
    totalReceipts == null || totalReceipts <= 0,
  );
  const {
    deleteDonorData,
    isDeleteDonorSuccess,
    deleteDonorHasError,
    isDeleteDonorLoading,
  } = useSelector((state) => ({
    deleteDonorData: state.Donors.deleteDonor.data,
    isDeleteDonorSuccess: state.Donors.deleteDonor.success,
    isDeleteDonorLoading: state.Donors.deleteDonor.loading,
    deleteDonorHasError: state.Donors.deleteDonor.error,
  }));
  const rejectForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      reason: "",
      donorId: donorId,
      type: action,
    },
    validationSchema: Yup.object({
      reason: Yup.string().required(
        "Please provide the reason for deleting the donor.",
      ),
    }),
    onSubmit: (values) => {
      dispatch(deleteDonor(values));
    },
  });
  const onAreYouCloseButtonClick = () => {
    setAreSureClose(false);
    onCloseClickOnSureDialog();
  };
  const onAreYouProceedButtonClick = () => {
    setAreSureClose(false);
    setAreSureProceed(true);
  };
  const onProceedCloseBtnClick = () => {
    setAreSureProceed(false);
    onCloseClickOnSureDialog();
  };

  useEffect(() => {
    if (isDeleteDonorSuccess) {
      setTimeout(() => {
        onCloseClickOnSureDialog();
        reloadDonorsPage();
      }, 3000);
    }
    return () => {
      setTimeout(() => {
        dispatch(deleteDonorReset());
      }, 3000);
    };
  }, [isDeleteDonorSuccess]);
  return (
    <>
      <Modal isOpen={areSureClose} centered={true}>
        <ModalBody className="p-1">
          <div className="mt-2 text-center">
            <div className="animatedIcon">
              <i
                className={`${
                  donorType !== "deleted"
                    ? "ri-delete-bin-fill"
                    : "ri-user-add-line"
                }`}
                style={{ fontSize: "50px" }}
              ></i>
            </div>
            <div className="pt-0 fs-15 mx-4 mx-sm-5">
              {action === "donor" && donorType !== "deleted" && (
                <>
                  <h4>Are you sure you want to delete?</h4>
                  <p className="text-muted mx-4 mb-0">
                    You are about to delete a donor with registration ID:{" "}
                    {`${donorId}`}, name: {`${donorName_ta}(${donorName_en})`}{" "}
                    who has already paid. If you still want to delete, please
                    proceed with the 'Proceed' button, OR click the 'Close'
                    button to stop the deletion.
                  </p>
                </>
              )}
              {action === "donor" && donorType === "deleted" && (
                <>
                  <h4>Are you sure you want to re-add donor back?</h4>
                  <p className="text-muted mx-4 mb-0">
                    You are about to re-add a donor with registration ID:{" "}
                    {`${donorId}`}, name: {`${donorName_ta}(${donorName_en})`}{" "}
                    back. If you still want to re-add, please proceed with the
                    'Proceed' button, OR click the 'Close' button to stop the
                    adding.
                  </p>
                </>
              )}
              {action === "postal" && sendPostal !== 1 && (
                <>
                  <h4>Are you sure you want to stop sending postals?</h4>
                  <p className="text-muted mx-4 mb-0">
                    You are about to stop sending postals for a donor with
                    registration ID: {`${donorId}`}, name:{" "}
                    {`${donorName_ta}(${donorName_en})`}. If you still want to
                    stop, please proceed with the 'Proceed' button, OR click the
                    'Close' button to halt the process.
                  </p>
                </>
              )}

              {action === "postal" && sendPostal === 1 && (
                <>
                  <h4>Are you sure you want to re-send postals?</h4>
                  <p className="text-muted mx-4 mb-0">
                    You are about to resending postals for a donor with
                    registration ID: {`${donorId}`}, name:{" "}
                    {`${donorName_ta}(${donorName_en})`}. If you still want to
                    stop, please proceed with the 'Proceed' button, OR click the
                    'Close' button to halt the process.
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <Button
              type="button"
              className="btn w-sm btn-danger"
              onClick={onAreYouCloseButtonClick}
            >
              <i className="ri-close-fill me-2"></i>
              Close
            </Button>
            <Button
              type="button"
              className="btn w-sm btn-primary"
              onClick={onAreYouProceedButtonClick}
            >
              <i className="ri-arrow-right-line me-2"></i>
              Proceed
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={aresureProceed} centered={true}>
        <ModalHeader id="rejectModal">
          {action === "donor"
            ? donorType !== "deleted"
              ? "Delete Donor"
              : "Re-add donor"
            : ""}
          {action === "postal"
            ? sendPostal !== 1
              ? "Stop Postal"
              : "Resend Postal"
            : ""}
        </ModalHeader>
        <ModalBody className="p-4">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              rejectForm.handleSubmit();
              return false;
            }}
            action="#"
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <p>
                    Registration ID: {`${donorId}`}, Name:{" "}
                    {`${donorName_ta} (${donorName_en})`}
                  </p>
                  <Label htmlFor="name" className="form-label">
                    {action === "donor"
                      ? donorType !== "deleted"
                        ? "Delete"
                        : "Re-add"
                      : ""}
                    {action === "postal"
                      ? sendPostal !== 1
                        ? "Stop Postal"
                        : "Resend Postal"
                      : ""}{" "}
                    reason
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    placeholder="Enter reason"
                    autoComplete="off"
                    id="reason"
                    name="reason"
                    rows="10"
                    onChange={rejectForm.handleChange}
                    onBlur={rejectForm.handleBlur}
                    value={rejectForm.values.reason || ""}
                    invalid={
                      rejectForm.touched.reason && rejectForm.errors.reason
                    }
                  />
                  {rejectForm.touched.reason && rejectForm.errors.reason ? (
                    <FormFeedback type="invalid">
                      {rejectForm.errors.reason}
                    </FormFeedback>
                  ) : null}
                  <div className="d-flex gap-2 justify-content-center mt-4">
                    <Button
                      type="button"
                      color="danger"
                      className="w-sm"
                      onClick={onProceedCloseBtnClick}
                      disabled={isDeleteDonorLoading || isDeleteDonorSuccess}
                    >
                      <i className="ri-close-fill me-2"></i>
                      Close
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="w-sm"
                      disabled={isDeleteDonorLoading || isDeleteDonorSuccess}
                    >
                      <i className="ri-arrow-right-line me-2"></i>
                      Save{" "}
                      {isDeleteDonorLoading && (
                        <Spinner
                          size="sm"
                          type="grow"
                          className="flex-shrink-0 ms-2"
                          role="status"
                          data-testid="spinner-cancel"
                        ></Spinner>
                      )}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>

          {deleteDonorHasError && (
            <ToastifyMessage
              status=""
              message={`Error: ${deleteDonorData?.message}.`}
            />
          )}

          {isDeleteDonorSuccess && (
            <ToastifyMessage message={`${deleteDonorData?.message}`} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Reject;
