import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Button,
  Spinner,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { prepareOptionsWithyears } from "../../helpers/utility";
import Flatpickr from "react-flatpickr";
import {
  generatePrints,
  generatePrintsReset,
  getDonorTypes,
} from "../../store/actions";
import BookPrint from "../../Prints/BookPrint";
import PostalAddressPrint from "../../Prints/PostalAddressPrint";
import BookEntryPaymentStatus from "../../Prints/BookEntryPaymentStatus";
import { getPreferences } from "../../store/actions";
import { isEmpty } from "lodash";
import { META_TITLE } from "../../helpers/constants";

const PrintsToDownload = () => {
  document.title = `Print || ${META_TITLE}`;
  const dispatch = useDispatch();
  const PrintDonorsRef = useRef();
  const [filterBy, setFilterBy] = useState([
    {
      label: `All paid donors`,
      value: "all_paid_donors",
    },
    {
      label: `All donors (including non-paid)`,
      value: "all_donors",
    },
    {
      label: `All non paid donors`,
      value: "non_paid_donors",
    },
    {
      label: `Year based paid donors`,
      value: "year_based_paid_donors",
    },
    {
      label: `Year based non-paid donors`,
      value: "year_based_non_paid_donors",
    },
    {
      label: `Modified between dates`,
      value: "modified_dates",
    },
  ]);
  const [showYear, setShowYear] = useState(false);
  const [showDateField, setShowDateField] = useState(false);
  const [showLocalityField, setShowLocalityField] = useState(false);
  const [argumentsToPass, setArgumentsToPass] = useState({});
  const [showPreloader, setShowPreloader] = useState(true);
  const [showDonorType, setShowDonorType] = useState(false);
  const { preferences, isPreferencesSuccess } = useSelector((state) => ({
    preferences: state.Preferences.pref?.data,
    isPreferencesSuccess: state.Preferences.pref?.success,
  }));
  const DownloadOnClick = () => {
    dispatch(generatePrints(new URLSearchParams(argumentsToPass).toString()));
  };
  const { generatePrintsData, generatePrintsSuccess, generatePrintsLoading } =
    useSelector((state) => ({
      generatePrintsData: state.Reports.generatePrints.data,
      generatePrintsSuccess: state.Reports.generatePrints.success,
      generatePrintsLoading: state.Reports.generatePrints.loading,
      isgeneratePrintsError: state.Reports.generatePrints.error,
    }));
  const { donorTypes, donorTypesSuccess } = useSelector((state) => ({
    donorTypes: state.DonorTypes.donorTypes,
    donorTypesSuccess: state.DonorTypes.success,
  }));
  const handlePrint = useReactToPrint({
    content: () => PrintDonorsRef.current,
    onAfterPrint: () => {
      setShowPreloader(false);
    },
  });

  const handlePrintAddress = useReactToPrint({
    content: () => PrintDonorsRef.current,
    onAfterPrint: () => {
      setShowPreloader(false);
    },
  });

  useEffect(() => {
    !isPreferencesSuccess &&
      isEmpty(preferences) &&
      dispatch(getPreferences("preferences"));
  }, [isPreferencesSuccess]);

  useEffect(() => {
    if (generatePrintsSuccess && argumentsToPass?.filter_type != "address") {
      setShowPreloader(generatePrintsSuccess);
      setTimeout(() => {
        if (argumentsToPass?.format === "print") handlePrint();
        if (argumentsToPass?.format === "html") {
          const element = document.createElement("a");
          const file = new Blob(
            [
              document.getElementById("copy").innerHTML,
              ["<style>table, th, td {border: 1px solid #000;}</style>"],
            ],
            { type: "text/html" },
          );
          element.href = URL.createObjectURL(file);
          element.download = `${objectToUrlString(argumentsToPass)}.html`;
          document.body.appendChild(element);
          element.click();
        }
      }, 0);
    }
  }, [generatePrintsSuccess, argumentsToPass?.filter_type]);

  const objectToUrlString = (obj) => {
    const values = Object.values(obj);
    const urlString = values
      .map((value) => value.toString().replace(/\s+/g, "_"))
      .join("-");
    return urlString;
  };

  useEffect(() => {
    if (generatePrintsSuccess && argumentsToPass?.filter_type === "address") {
      setTimeout(() => {
        handlePrintAddress();
      }, 0);
    }
  }, [generatePrintsSuccess, argumentsToPass?.filter_type]);

  useEffect(() => {
    setShowPreloader(generatePrintsLoading);
  }, [generatePrintsLoading]);

  useEffect(() => {
    !donorTypesSuccess && dispatch(getDonorTypes("for=dropdown"));
  }, [donorTypesSuccess]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Download" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={2}>
                      <Label for="filter_donor_by" className="form-label">
                        Template format <span className="text-danger">*</span>
                      </Label>

                      <Select
                        classNamePrefix="myOwnSelect"
                        isMulti={false}
                        id="filter_type"
                        name="filter_type"
                        options={[
                          {
                            label: `Sandha`,
                            value: "sandha",
                          },
                          {
                            label: `Product`,
                            value: "product",
                          },
                          {
                            label: `Address`,
                            value: "address",
                          },
                        ]}
                        placeholder={"Select a Template"}
                        onChange={(selected) => {
                          let changedObj = filterBy;
                          if (selected?.value == "product") {
                            changedObj.push(
                              {
                                label: "Printed in Book",
                                value: "printed_in_book",
                              },
                              {
                                label: "Book Entry Payment Status",
                                value: "paid_book_status",
                              },
                            );
                          } else {
                            changedObj = changedObj.filter((obj) => {
                              return (
                                obj.value !== "printed_in_book" &&
                                obj.value !== "paid_book_status"
                              );
                            });
                          }
                          setFilterBy(changedObj);

                          setShowLocalityField(selected.value === "address");
                          setShowDonorType(
                            selected.value === "address" &&
                              argumentsToPass?.filterBy === "all_donors",
                          );
                          if (selected.value !== "address") {
                            delete argumentsToPass?.localities;
                            delete argumentsToPass?.address_donor_type;
                          }
                          setArgumentsToPass({
                            ...argumentsToPass,
                            donor_type: selected?.value,
                          });
                        }}
                      />
                    </Col>
                    <Col lg={2}>
                      <Label for="filter_donor_by" className="form-label">
                        Filter By <span className="text-danger">*</span>
                      </Label>
                      <Select
                        classNamePrefix="myOwnSelect"
                        isMulti={false}
                        id="filter_by"
                        name="filter_by"
                        options={filterBy}
                        defaultValue={""}
                        placeholder={"Select Filter By"}
                        onChange={(selected) => {
                          setShowYear(
                            selected.value === "year_based_paid_donors" ||
                              selected.value === "printed_in_book" ||
                              selected.value === "paid_book_status" ||
                              selected.value === "year_based_non_paid_donors",
                          );
                          setShowDateField(selected.value === "modified_dates");
                          setShowDonorType(
                            selected.value === "all_donors" &&
                              argumentsToPass?.donor_type === "address",
                          );
                          selected.value === "modified_dates" &&
                            delete argumentsToPass?.forYear;
                          selected.value === "year_based_paid_donors" &&
                            delete argumentsToPass?.betweenDates;
                          selected.value !== "all_donors" &&
                            delete argumentsToPass?.address_donor_type;
                          setArgumentsToPass({
                            ...argumentsToPass,
                            filterBy: selected?.value,
                          });
                        }}
                      />
                    </Col>
                    {showDonorType && (
                      <Col lg={2}>
                        <Label
                          htmlFor="address_donor_type"
                          className="form-label"
                        >
                          Donor Type <span className="text-danger">*</span>
                        </Label>
                        <Select
                          classNamePrefix="myOwnSelect"
                          id="address_donor_type"
                          name="address_donor_type"
                          onChange={(selected) => {
                            setArgumentsToPass({
                              ...argumentsToPass,
                              address_donor_type: selected?.value,
                            });
                          }}
                          options={[{ label: "All", value: "all" }].concat(
                            donorTypes,
                          )}
                          isLoading={!donorTypesSuccess}
                          placeholder={"Select Donor Type"}
                        />
                      </Col>
                    )}
                    {showYear && (
                      <Col lg={2}>
                        <Label for="filter_donor_by" className="form-label">
                          Select Year <span className="text-danger">*</span>
                        </Label>
                        <Select
                          classNamePrefix="myOwnSelect"
                          isMulti={false}
                          id="filter_by_year"
                          name="filter_by_year"
                          options={prepareOptionsWithyears(
                            "",
                            [],
                            argumentsToPass?.filterBy === "printed_in_book" ||
                              argumentsToPass?.filterBy ===
                                "year_based_paid_donors" ||
                              argumentsToPass?.filterBy === "paid_book_status",
                          )}
                          placeholder={"Select Year"}
                          onChange={(selected) => {
                            setArgumentsToPass({
                              ...argumentsToPass,
                              forYear: selected?.value,
                            });
                          }}
                        />
                      </Col>
                    )}
                    {showDateField && (
                      <Col lg={2}>
                        <Label for="filter_donor_by" className="form-label">
                          Select Dates <span className="text-danger">*</span>
                        </Label>
                        <Flatpickr
                          className="form-control"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            onChange: (selectedDates, dateStr) => {
                              setArgumentsToPass({
                                ...argumentsToPass,
                                betweenDates: dateStr,
                              });
                            },
                          }}
                        />
                      </Col>
                    )}
                    {showLocalityField && (
                      <>
                        <Col lg={2}>
                          <Label for="filter_donor_by" className="form-label">
                            Filter Temple Specific Localities{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            classNamePrefix="myOwnSelect"
                            isMulti={false}
                            id="localities"
                            name="localities"
                            options={[
                              {
                                label: `Yes`,
                                value: "yes",
                              },
                              {
                                label: `No`,
                                value: "no",
                              },
                            ]}
                            placeholder={"Select Yes or No"}
                            onChange={(selected) => {
                              setArgumentsToPass({
                                ...argumentsToPass,
                                localities: selected?.value,
                              });
                            }}
                          />
                        </Col>
                        <Col lg={3}>
                          <Label for="filter_donor_by" className="form-label">
                            Address only enteries are required?{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            classNamePrefix="myOwnSelect"
                            isMulti={false}
                            id="addressRequired"
                            name="addressRequired"
                            options={[
                              {
                                label: `Yes`,
                                value: "yes",
                              },
                              {
                                label: `No`,
                                value: "no",
                              },
                            ]}
                            placeholder={"Select Yes or No"}
                            onChange={(selected) => {
                              setArgumentsToPass({
                                ...argumentsToPass,
                                addressRequired: selected?.value,
                              });
                            }}
                          />
                        </Col>
                      </>
                    )}
                    {argumentsToPass?.filterBy === "all_paid_donors" && (
                      <>
                        <Col lg={2}>
                          <Label for="sandha_years" className="form-label">
                            Select Year
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter year(s) separated by commas (Leave blank for all paid donors)"
                            autoComplete="off"
                            id="sandha_years"
                            name="sandha_years"
                            onChange={(e) => {
                              setArgumentsToPass({
                                ...argumentsToPass,
                                sandha_years: e?.target?.value,
                              });
                            }}
                            onBlur={(e) => {
                              setArgumentsToPass({
                                ...argumentsToPass,
                                sandha_years: e?.target?.value,
                              });
                            }}
                          />
                        </Col>
                        <Col lg={2}>
                          <Label for="sandha_donor_ids" className="form-label">
                            Select Donor ID's
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter donor id's separated by commas (Leave blank for all paid donors)"
                            autoComplete="off"
                            id="sandha_donor_ids"
                            name="sandha_donor_ids"
                            onChange={(e) => {
                              setArgumentsToPass({
                                ...argumentsToPass,
                                donor_ids: e?.target?.value,
                              });
                            }}
                            onBlur={(e) => {
                              setArgumentsToPass({
                                ...argumentsToPass,
                                donor_ids: e?.target?.value,
                              });
                            }}
                          />
                        </Col>
                      </>
                    )}
                    <Col lg={2}>
                      <Label for="from_date" className="form-label">
                        Download Format <span className="text-danger">*</span>
                      </Label>
                      <Select
                        classNamePrefix="myOwnSelect"
                        isMulti={false}
                        id="filter_donor_by"
                        name="filter_donor_by"
                        onChange={(selected) => {
                          setArgumentsToPass({
                            ...argumentsToPass,
                            format: selected?.value,
                          });
                          dispatch(generatePrintsReset());
                        }}
                        options={[
                          {
                            label: `HTML`,
                            value: "html",
                          },
                          {
                            label: `Print`,
                            value: "print",
                          },
                        ]}
                        placeholder={"Select Download Format"}
                      />
                    </Col>
                    <Col lg={2}>
                      <Label for="from_date" className="form-label invisible">
                        Download Format <span className="text-danger">*</span>
                      </Label>
                      <Button
                        color="primary"
                        className="w-100"
                        type="submit"
                        onClick={() => {
                          DownloadOnClick();
                        }}
                      >
                        {!showPreloader && (
                          <>
                            <i className="ri-printer-fill me-1 align-middle fw-bold"></i>{" "}
                            Print
                          </>
                        )}
                        {showPreloader && (
                          <>
                            <span className="d-flex align-items-center">
                              <Spinner
                                size="sm"
                                type="grow"
                                className="flex-shrink-0"
                                role="status"
                              >
                                {" "}
                                Loading...{" "}
                              </Spinner>
                              <span className="flex-grow-1 me-2">
                                Loading...
                              </span>
                            </span>
                          </>
                        )}
                      </Button>
                    </Col>
                    <Col lg={12}>
                      <p className="m-0 pt-2">
                        Function dates:&nbsp;
                        {isPreferencesSuccess &&
                          Object.entries(preferences["FUNCTION_YEARS"]).map(
                            ([key, value]) => (
                              <span key={key}>
                                <strong>{`${key}: `}</strong>
                                {`${value}`},{" "}
                              </span>
                            ),
                          )}
                      </p>
                    </Col>
                    <Col lg={12} className="pt-3">
                      <h4>Important Note</h4>
                      <ol>
                        <li>
                          When selecting an address template, the default
                          setting will automatically filter out postal codes
                          with '0' and entries for the current year will be
                          added automatically.
                        </li>
                        <li>
                          When adding 'Select Donor IDs,' it does not check for
                          donor type. This blindly adds donor data without
                          validating the donor type. Please be more cautious
                          when providing this information.
                        </li>
                      </ol>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <div style={{ display: "none" }} id="copy">
          {generatePrintsSuccess &&
            argumentsToPass?.donor_type !== "address" &&
            argumentsToPass?.filterBy !== "paid_book_status" && (
              <BookPrint
                ref={PrintDonorsRef}
                dataToPrint={generatePrintsData}
                isLocalityRequired={false}
                requestedData={argumentsToPass}
              />
            )}
        </div>

        <div style={{ display: "none" }} id="copy">
          {generatePrintsSuccess &&
            argumentsToPass?.donor_type == "product" &&
            argumentsToPass?.filterBy === "paid_book_status" && (
              <>
                <BookEntryPaymentStatus
                  ref={PrintDonorsRef}
                  dataToPrint={generatePrintsData}
                  isLocalityRequired={false}
                  requestedData={argumentsToPass}
                />
              </>
            )}
        </div>

        <div style={{ display: "none" }}>
          {generatePrintsSuccess &&
            argumentsToPass?.donor_type === "address" && (
              <PostalAddressPrint
                ref={PrintDonorsRef}
                dataToPrint={generatePrintsData}
                args={argumentsToPass}
              />
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrintsToDownload;
