import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import { GET_PREFERENCES, SAVE_PREFERENCES } from "./actionTypes";
import {
  preferencesSuccess,
  preferencesFailed,
  savePreferencesSuccess,
  savePreferencesFailed,
} from "./actions";

const api = new APIClient();

function* preferencesSaga(action) {
  const { filterBy } = action;
  try {
    const getDonors = () => api.get(`${"/preferences?filter="}${filterBy}`);
    const response = yield call(getDonors);
    const { data, status } = response;
    if (status === 200) {
      yield put(preferencesSuccess(data));
    } else {
      yield put(preferencesFailed(response));
    }
  } catch (error) {
    yield put(preferencesFailed(error));
  }
}

function* updatePreferenceSaga(action) {
  const { ObjToPass } = action;
  try {
    const updatePreference = (data) => api.put(`${"/preferences"}`, data);
    const response = yield call(updatePreference, ObjToPass);
    const { data, status } = response;
    status === 201
      ? yield put(savePreferencesSuccess(data))
      : yield put(savePreferencesFailed(data));
  } catch (error) {
    yield put(savePreferencesFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(GET_PREFERENCES, preferencesSaga);
  yield takeEvery(SAVE_PREFERENCES, updatePreferenceSaga);
}

function* preferencesSagaOutput() {
  yield all([fork(watchUserRegister)]);
}

export default preferencesSagaOutput;
