import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import {
  GET_CACHED_ADDRESS,
  GET_RAW_ADDRESS,
  GET_PINCODE,
} from "./actionTypes";
import {
  getCachedAddressSuccess,
  getCachedAddressFailed,
  getRawAddressSuccess,
  getRawAddressFailed,
  getPincodeSuccess,
  getPincodeFailed,
} from "./actions";

const api = new APIClient();

function* getCachedLocality() {
  try {
    const getLocality = () => api.get(`${"/location/address"}`);
    const response = yield call(getLocality);
    const { data, status } = response;
    if (status === 200) {
      yield put(getCachedAddressSuccess(data));
    } else {
      yield put(getCachedAddressFailed(response));
    }
  } catch (error) {
    yield put(getCachedAddressFailed(error));
  }
}

function* getRawLocality(action) {
  const { params, requestType = "" } = action;
  try {
    const getLocality = () => api.get(`/location/address/india?${params}`);
    const response = yield call(getLocality);
    const { data, status } = response;
    if (status === 200) {
      yield put(getRawAddressSuccess(data, requestType));
    } else {
      yield put(getPincodeFailed(response));
    }
  } catch (error) {
    yield put(getPincodeFailed(error));
  }
}

function* getPincodeDetails(action) {
  const { pincode } = action;
  try {
    const getPincode = () => api.get(`/location/address/pincode/${pincode}`);
    const response = yield call(getPincode);
    const { data, status } = response;
    if (status === 200) {
      yield put(getPincodeSuccess(data));
    } else {
      yield put(getRawAddressFailed(response));
    }
  } catch (error) {
    yield put(getRawAddressFailed(error));
  }
}

export function* watchLocality() {
  yield takeEvery(GET_CACHED_ADDRESS, getCachedLocality);
  yield takeEvery(GET_RAW_ADDRESS, getRawLocality);
  yield takeEvery(GET_PINCODE, getPincodeDetails);
}

function* localitySaga() {
  yield all([fork(watchLocality)]);
}

export default localitySaga;
