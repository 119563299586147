export const GET_NOTES = "GET_NOTES";
export const NOTES_SUCCESS = "NOTES_SUCCESS";
export const NOTES_FAILED = "NOTES_FAILED";
export const NOTES_RESET = "NOTES_RESET";

export const SAVE_NOTES = "SAVE_NOTES";
export const SAVE_NOTES_SUCCESS = "SAVE_NOTES_SUCCESS";
export const SAVE_NOTES_FAILED = "SAVE_NOTES_FAILED";
export const SAVE_NOTES_RESET = "SAVE_NOTES_RESET";

export const GET_SINGLE_NOTES = "GET_SINGLE_NOTES";
export const GET_SINGLE_NOTES_SUCCESS = "GET_SINGLE_NOTES_SUCCESS";
export const GET_SINGLE_NOTES_FAILED = "GET_SINGLE_NOTES_FAILED";
export const GET_SINGLE_NOTES_RESET = "GET_SINGLE_NOTES_RESET";

export const UPDATE_NOTES = "UPDATE_NOTES";
export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_FAILED = "UPDATE_NOTES_FAILED";
export const UPDATE_NOTES_RESET = "UPDATE_NOTES_RESET";
