import {
  GET_FILEMANAGER,
  FILEMANAGER_SUCCESS,
  FILEMANAGER_FAILED,
  FILEMANAGER_RESET,
} from "./actionTypes";

const initialState = {
  fileManager: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
};

const Notes = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILEMANAGER:
      state = {
        ...state,
        fileManager: {
          loading: true,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case FILEMANAGER_SUCCESS:
      state = {
        ...state,
        fileManager: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case FILEMANAGER_FAILED:
      state = {
        ...state,
        fileManager: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case FILEMANAGER_RESET:
      state = {
        ...state,
        fileManager: {
          loading: false,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Notes;
