import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const prepareLink = () => (
    <span
      className="badge border-info bg-info text-white"
      style={{ cursor: "pointer" }}
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_DOCUMENTATION_PATH}${location?.pathname}`,
          "_blank",
        )
      }
    >
      <span>&#8505;</span> Documenation
    </span>
  );
  return (
    <React.Fragment>
      <footer className="footer text-center mt-xs-3">
        <Container fluid>
          <Row>
            <Col sm={6} className="text-start">
              {new Date().getFullYear()} &copy; Adhi Shiva WebApp, Mallikarjuna
              Swamy Temple, Kondarangi Keeranur. {prepareLink()}
              <span className="d-sm-none">
                Build Version {`${process.env.REACT_APP_VERSION}`}
              </span>
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Vijayakumar Selvaraj. Build Version{" "}
                {`${process.env.REACT_APP_VERSION}`}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
