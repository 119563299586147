import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import bgndImage from "../../assets/images/shivling-bholenath-shivji-png-images-download--thumbnail-1676772550.png";

const ParticlesAuth = ({ children }) => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 bg-primary full-height d-none d-md-flex align-items-center text-center">
            <div className="w-100">
              <img src={bgndImage} className="ps-5" style={{ width: "32%" }} />
              <h1 className="text-white pb-2 pt-3">Welcome Back,</h1>
              <h4 className="text-white pb-3" style={{ lineHeight: "40px" }}>
                We are glad to see you again! <br /> To keep connected with us
                please login with your personal info.
              </h4>
            </div>
          </div>
          <div className="col-md-5 full-height bg-white d-md-flex align-items-center">
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ParticlesAuth.propTypes = {
  children: PropTypes.any.isRequired,
};

export default withRouter(ParticlesAuth);
