import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

const IframeModal = ({ show = false, srcContent, onCloseClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleIframeLoad = () => {
    setIsLoading(false);
  };
  return (
    <Modal isOpen={show} centered={true} size={"l"} toggle={onCloseClick}>
      <ModalBody className="py-3">
        {isLoading && (
          <div
            className="text-center container d-flex align-items-center justify-content-center"
            style={{ position: "relative", top: "150px" }}
          >
            <div className="spinner-border" role="status" id="loader">
              <span className="sr-only d-none">Loading...</span>
            </div>
          </div>
        )}
        <iframe
          title="Content"
          width="100%"
          height="250px"
          src={srcContent}
          allowFullScreen
          onLoad={handleIframeLoad}
        />
        <div className="modal-footer pe-0">
          <Link
            to="#"
            className="btn btn-primary fw-medium"
            onClick={onCloseClick}
          >
            <i className="ri-close-line me-1 align-middle"></i> Close
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default IframeModal;
