import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import {
  GET_DONORS_DATA,
  GET_SINGLE_DONOR,
  GET_DONOR_RECEIPTS,
  ADD_DONOR,
  UPDATE_DONOR,
  BOOK_ADDRESS_DONOR,
  UPDATE_BOOK_ADDRESS_DONOR,
  GENERATE_RECEIPTS,
  INVOICE_LISTS,
  CANCEL_RECEIPTS,
  DELETE_DONOR,
  GET_INVOICE_RAW,
  GET_INVOICE_NUMBER,
  GET_OFFLINE_LIST,
  GET_REJECT_LIST,
  GET_ONETIME_DONORS_LIST,
} from "./actionTypes";
import {
  getDonorsListSuccess,
  getDonorsListFailed,
  getSingleDonorSuccess,
  getSingleDonorFailed,
  getDonorReceiptsSuccess,
  getDonorReceiptsFailed,
  addNewDonorSuccess,
  addNewDonorFailed,
  updateDonorSuccess,
  updateDonorFailed,
  getBookPrintDonorSuccess,
  getBookPrintDonorFailed,
  updateBookPrintDonorSuccess,
  updateBookPrintDonorFailed,
  generateReceiptSuccess,
  generateReceiptFailed,
  getInvoiceListSuccess,
  getInvoiceListFailed,
  cancelInvoiceSuccess,
  cancelInvoiceFailed,
  deleteDonorSuccess,
  deleteDonorFailed,
  getRawInvoiceSuccess,
  getRawInvoiceFailed,
  getSingleInvoiceSuccess,
  getSingleInvoiceFailed,
  getOfflineListSuccess,
  getOfflineListFailed,
  getRejectListSuccess,
  getRejectListFailed,
  getOneTimeDonorsListSuccess,
  getOneTimeDonorsListFailed,
} from "./actions";

const api = new APIClient();

function* donorsApis(action) {
  const { donorType, params } = action;
  const prepareParams = params === undefined ? "" : `&${params}`;
  try {
    const getDonors = () =>
      api.get(`${"/donors/list?type="}${donorType}${prepareParams}`);
    const response = yield call(getDonors);
    const { data, status } = response;
    if (status === 200) {
      yield put(getDonorsListSuccess(data));
    } else {
      yield put(getDonorsListFailed(response));
    }
  } catch (error) {
    yield put(getDonorsListFailed(error));
  }
}

function* singleDonor(action) {
  const { donorid, donorType } = action;
  try {
    const getDonor = () =>
      api.get(`${"/donors/list?type="}${donorType}&donorid=${donorid}`);
    const response = yield call(getDonor);
    const { data, status } = response;
    status === 200
      ? yield put(getSingleDonorSuccess(data))
      : yield put(getSingleDonorFailed(data));
  } catch (error) {
    yield put(getSingleDonorFailed(error));
  }
}

function* getUserReceipts(action) {
  const { donorid } = action;
  try {
    const getDonor = () => api.get(`${"/receipts?userid="}${donorid}`);
    const response = yield call(getDonor);
    const { data, status } = response;
    status === 200
      ? yield put(getDonorReceiptsSuccess(data))
      : yield put(getDonorReceiptsFailed(data));
  } catch (error) {
    yield put(getDonorReceiptsFailed(error));
  }
}

function* addNewDonorSaga(action) {
  const { payload } = action;
  try {
    const addDonor = (data) => api.create(`${"/donors"}`, data);
    const response = yield call(addDonor, payload);
    const { data, status } = response;
    status === 201
      ? yield put(addNewDonorSuccess(data))
      : yield put(addNewDonorFailed(data));
  } catch (error) {
    yield put(addNewDonorFailed(error));
  }
}

function* updateDonorSaga(action) {
  const { payload, donorid } = action;
  try {
    const addDonor = (data) => api.put(`${"/donors"}/${donorid}`, data);
    const response = yield call(addDonor, payload);
    const { data, status } = response;
    status === 201
      ? yield put(updateDonorSuccess(data))
      : yield put(updateDonorFailed(data));
  } catch (error) {
    yield put(updateDonorFailed(error));
  }
}

function* prepareBookSaga(action) {
  const { year = "all" } = action;
  const queryParams = `year=${year}`;
  try {
    const getDonor = () =>
      api.get(`/donors/book-printed-donors?${queryParams}`);
    const response = yield call(getDonor);
    const { data, status } = response;
    status === 200
      ? yield put(getBookPrintDonorSuccess(data))
      : yield put(getBookPrintDonorFailed(data));
  } catch (error) {
    yield put(getBookPrintDonorFailed(error));
  }
}

function* updateBookPrepareSaga(action) {
  const { payload, year } = action;
  try {
    const addDonor = (data) =>
      api.put(`${"/donors/book-printed-donors"}/${year}`, data);
    const response = yield call(addDonor, payload);
    const { data, status } = response;
    status === 201
      ? yield put(updateBookPrintDonorSuccess(data))
      : yield put(updateBookPrintDonorFailed(data));
  } catch (error) {
    yield put(updateBookPrintDonorFailed(error));
  }
}

function* addReceiptsSaga(action) {
  const { payload } = action;
  try {
    const addReceipt = (data) =>
      api.create(`${"/donors/generate-receipt"}`, data);
    const response = yield call(addReceipt, payload);
    const { data, status } = response;
    status === 201
      ? yield put(generateReceiptSuccess(data))
      : yield put(generateReceiptFailed(data));
  } catch (error) {
    yield put(generateReceiptFailed(error));
  }
}

function* getInvoiceListSaga(action) {
  const { year } = action;
  try {
    const getInvoice = () => api.get(`/receipts/all?year=${year}`);
    const response = yield call(getInvoice);
    const { data, status } = response;
    status === 200
      ? yield put(getInvoiceListSuccess(data))
      : yield put(getInvoiceListFailed(data));
  } catch (error) {
    yield put(getInvoiceListFailed(error));
  }
}

function* invoiceCancelSaga(action) {
  const { invoiceNumber } = action;
  try {
    const invoiceCancelCB = (invoiceNumber) =>
      api.put(`${"/donors/generate-receipt/cancel"}`, invoiceNumber);
    const response = yield call(invoiceCancelCB, invoiceNumber);
    const { data, status } = response;
    status === 201
      ? yield put(cancelInvoiceSuccess(data))
      : yield put(cancelInvoiceFailed(data));
  } catch (error) {
    yield put(cancelInvoiceFailed(error));
  }
}

function* deleteDonorSaga(action) {
  const { deleteData } = action;
  try {
    const deleteDonorCB = (donorId) =>
      api.put(`${"/donors/delete/"}${donorId}`, deleteData);
    const response = yield call(deleteDonorCB, deleteData?.donorId);
    const { data, status } = response;
    status === 201
      ? yield put(deleteDonorSuccess(data))
      : yield put(deleteDonorFailed(data));
  } catch (error) {
    yield put(deleteDonorFailed(error));
  }
}

function* invoiceListSaga(action) {
  const { year } = action;
  try {
    const getInvoice = () => api.get(`/receipts/raw/${year}`);
    const response = yield call(getInvoice);
    const { data, status } = response;
    status === 200
      ? yield put(getRawInvoiceSuccess(data))
      : yield put(getRawInvoiceFailed(data));
  } catch (error) {
    yield put(getRawInvoiceFailed(error));
  }
}

function* invoiceSingleSaga(action) {
  const { invoiceNumber } = action;
  try {
    const getInvoice = () => api.get(`/receipts/invoice/${invoiceNumber}`);
    const response = yield call(getInvoice);
    const { data, status } = response;
    status === 200
      ? yield put(getSingleInvoiceSuccess(data))
      : yield put(getSingleInvoiceFailed(data));
  } catch (error) {
    yield put(getSingleInvoiceFailed(error));
  }
}

function* downloadOfflineListAsZipSaga(action) {
  const { filename } = action;
  try {
    const getZip = () =>
      api.download(`/downloads/offline-finder`, filename, "application/zip");
    const response = yield call(getZip);
    const { data, status } = response;
    status === 200
      ? yield put(getOfflineListSuccess(data))
      : yield put(getOfflineListFailed(data));
  } catch (error) {
    yield put(getOfflineListFailed(error));
  }
}

function* getRejectionListSaga() {
  try {
    const getRejectList = () => api.get(`${"/donors/reject"}`);
    const response = yield call(getRejectList);
    const { data, status } = response;
    status === 200
      ? yield put(getRejectListSuccess(data))
      : yield put(getRejectListFailed(data));
  } catch (error) {
    yield put(getRejectListFailed(error));
  }
}

function* getOneTimeDonors(action) {
  const { donorid } = action;
  try {
    const getDonor = () => api.get(`${"/receipts?userid="}${donorid}`);
    const response = yield call(getDonor);
    const { data, status } = response;
    status === 200
      ? yield put(getOneTimeDonorsListSuccess(data))
      : yield put(getOneTimeDonorsListFailed(data));
  } catch (error) {
    yield put(getOneTimeDonorsListFailed(error));
  }
}
export function* watchUserRegister() {
  yield takeEvery(GET_DONORS_DATA, donorsApis);
  yield takeEvery(GET_SINGLE_DONOR, singleDonor);
  yield takeEvery(GET_DONOR_RECEIPTS, getUserReceipts);
  yield takeEvery(ADD_DONOR, addNewDonorSaga);
  yield takeEvery(UPDATE_DONOR, updateDonorSaga);
  yield takeEvery(BOOK_ADDRESS_DONOR, prepareBookSaga);
  yield takeEvery(UPDATE_BOOK_ADDRESS_DONOR, updateBookPrepareSaga);
  yield takeEvery(GENERATE_RECEIPTS, addReceiptsSaga);
  yield takeEvery(INVOICE_LISTS, getInvoiceListSaga);
  yield takeEvery(CANCEL_RECEIPTS, invoiceCancelSaga);
  yield takeEvery(DELETE_DONOR, deleteDonorSaga);
  yield takeEvery(GET_INVOICE_RAW, invoiceListSaga);
  yield takeEvery(GET_INVOICE_NUMBER, invoiceSingleSaga);
  yield takeEvery(GET_OFFLINE_LIST, downloadOfflineListAsZipSaga);
  yield takeEvery(GET_REJECT_LIST, getRejectionListSaga);
  yield takeEvery(GET_ONETIME_DONORS_LIST, getOneTimeDonors);
}

function* donorsSaga() {
  yield all([fork(watchUserRegister)]);
}

export default donorsSaga;
