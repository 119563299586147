import {
  GET_DONOR_TYPES,
  GET_DONOR_TYPES_SUCCESS,
  DONOR_TYPES_FAILED,
  CREATE_DONOR_TYPES,
  CREATE_DONOR_TYPES_SUCCESS,
  CREATE_TYPES_FAILED,
  UPDATE_DONOR_TYPES,
  UPDATE_DONOR_TYPES_SUCCESS,
  UPDATE_TYPES_FAILED,
} from "./actionTypes";

const initialState = {
  donorTypes: [],
  loading: false,
  success: false,
  error: false,
  createDonor: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  updateDonor: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
};

const Donors = (state = initialState, action) => {
  switch (action.type) {
    case GET_DONOR_TYPES:
      state = {
        ...state,
        loading: true,
        donorTypes: [],
        success: false,
      };
      break;
    case GET_DONOR_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        donorTypes: action.payload,
        success: true,
      };
      break;
    case DONOR_TYPES_FAILED:
      state = {
        ...state,
        donorTypes: [],
        loading: false,
        error: true,
      };
      break;
    case CREATE_DONOR_TYPES:
      state = {
        ...state,
        createDonor: {
          loading: true,
          success: false,
          data: [],
        },
      };
      break;
    case CREATE_DONOR_TYPES_SUCCESS:
      state = {
        ...state,
        createDonor: {
          loading: false,
          success: true,
          data: action.payload,
        },
      };
      break;
    case CREATE_TYPES_FAILED:
      state = {
        ...state,
        createDonor: {
          loading: false,
          success: false,
          error: action.error,
          data: [],
        },
      };
      break;
    case UPDATE_DONOR_TYPES:
      state = {
        ...state,
        updateDonor: {
          loading: true,
          success: false,
          data: [],
        },
      };
      break;
    case UPDATE_DONOR_TYPES_SUCCESS:
      state = {
        ...state,
        updateDonor: {
          loading: false,
          success: true,
          data: action.payload,
        },
      };
      break;
    case UPDATE_TYPES_FAILED:
      state = {
        ...state,
        updateDonor: {
          loading: false,
          success: false,
          error: action.error,
          data: [],
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Donors;
