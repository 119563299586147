import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import classnames from "classnames";
import Select from "react-select";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
  Form,
  Button,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getDonorTypes,
  getCachedAddress,
  getRawAddress,
  addNewDonor,
  getSingleDonor,
  resetSingleDonorData,
  updateNewDonor,
  updateDonorReset,
  getPreferences,
} from "../../store/actions";
import {
  getCachedLocalityForDropDown,
  getRawLocalityForDropDown,
  disableDonorEdit,
} from "../../helpers/utility";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import { useLocation, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import LoadingBar from "../../Components/Common/LoadingBar";
import AreYouSure from "./AreYouSure";
import GoogleTranslatorInputField from "../../Components/Common/GoogleTranslatorInputField";
import { META_TITLE } from "../../helpers/constants";

const CreateDonor = () => {
  const dispatch = useDispatch();
  const emailRegExp = /\S+@\S+\.\S+/;
  const location = useLocation();
  const history = useHistory();
  const pathName = location.pathname.replace("/", "");
  const perfornalFormRef = useRef();
  const AddressFormRef = useRef();
  const { editDonorId, editDonorType } = { ...location?.state };
  const { singleDonor, isSingleApiLoading } = useSelector((state) => ({
    singleDonor: state.Donors.donor.data,
    isSingleApiSuccess: state.Donors.donor.success,
    isSingleApiLoading: state.Donors.donor.loading,
  }));
  const {
    district,
    donorAddress_en,
    donorEmail,
    donorId,
    donorName_en,
    donorName_ta,
    donorPhone = "",
    donorType = "",
    donorPrintNameInBook_en,
    donorPrintNameInBook_ta,
    featuredLocality_ta,
    postId,
    state,
    taluk,
    isAddressSkipped = 0,
  } = { ...singleDonor };
  const mode = pathName === "edit-donor" ? "EDIT" : "NEW";
  const [currentMode] = useState(mode);
  const pageTitle = (document.title =
    currentMode === "NEW"
      ? `Create Donor || ${META_TITLE}`
      : `Edit ${donorName_en || ""}, ${
          donorId || ""
        } donor record || ${META_TITLE}`);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [activeVerticalTab, setactiveVerticalTab] = useState(0);
  const [passedverticalSteps, setPassedverticalSteps] = useState([1]);
  const [selectState, setselectState] = useState([]);
  const [selectDistrict, setselectDistrict] = useState([]);
  const [selectTaluk, setselectTaluk] = useState([]);
  const [selectLocality, setselectLocality] = useState([]);
  const [optionsState, selectOptionsState] = useState([]);
  const [optionsDistrict, setOptionsDistrict] = useState([]);
  const [optionsTaluk, setOptionsTaluk] = useState([]);
  const [optionsLocality, setOptionsLocality] = useState([]);
  const [rawAddressTriggered, setRawAddressTriggered] = useState(false);
  const [donorTypeAsArray, setDonorTypeAsArray] = useState([]);
  const [skipAddressTab, setSkipAddressTab] = useState(false);
  const [showAreSureDialog, setShowAreSureDialog] = useState(false);
  const [dataToPassForProcessing, setDataToPassForProcessing] = useState({});
  const [skipAddressValue, setSkipAddressValue] = useState(false);
  const { donorTypes, donorTypesSuccess } = useSelector((state) => ({
    donorTypes: state.DonorTypes.donorTypes,
    donorTypesSuccess: state.DonorTypes.success,
  }));
  const { cachedLocality, cachedLocalitySuccess } = useSelector((state) => ({
    cachedLocality: state.Locality.cached_address.data,
    cachedLocalitySuccess: state.Locality.cached_address.success,
  }));
  const { preferences } = useSelector((state) => ({
    preferences: state.Preferences.pref,
    preferenceDataSuccess: state.Preferences.pref.success,
  }));

  const { newDonorData, newDonorSuccess, newDonorError, newDonorLoading } =
    useSelector((state) => ({
      newDonorData: state.Donors.createDonor.data,
      newDonorSuccess: state.Donors.createDonor.success,
      newDonorLoading: state.Donors.createDonor.loading,
      newDonorError: state.Donors.createDonor.error,
    }));

  const { updateDonorData, updateDonorSuccess, updateDonorLoading } =
    useSelector((state) => ({
      updateDonorData: state.Donors.updateDonor.data,
      updateDonorSuccess: state.Donors.updateDonor.success,
      updateDonorLoading: state.Donors.updateDonor.loading,
      updateDonorError: state.Donors.updateDonor.error,
    }));

  const { rawState, rawDistrict, rawTaluk, rawLocality } = useSelector(
    (state) => ({
      rawState: state.Locality.raw_state,
      rawDistrict: state.Locality.raw_district,
      rawTaluk: state.Locality.raw_taluk,
      rawLocality: state.Locality.raw_locality,
      rawLocalitySuccess: state.Locality.raw_address.success,
    }),
  );

  const performInfoForm = useFormik({
    enableReinitialize: true,

    initialValues: {
      donor_type: donorType || "",
      donorName_en: donorName_en || "",
      donorName_ta: donorName_ta || "",
      phone: donorPhone || "",
      email: donorEmail || "",
    },
    validationSchema: Yup.object({
      donor_type: Yup.string().required("Please enter donor type"),
      donorName_en: Yup.string().required("Please enter donor name in english"),
      donorName_ta: Yup.string().required("Please enter donor name in tamil"),
      email: Yup.string().matches(emailRegExp, "Invalid email id"),
      phone: Yup.string()
        .test(
          "phone-required",
          "Please enter donor phone number",
          (value = "") => {
            return preferences?.data?.enablePhoneNumber
              ? value.trim() != ""
              : true;
          },
        )
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Phone number is not valid. Enter 10 digit valid number")
        .max(10, "Phone number is not valid. Enter 10 digit valid number"),
    }),
    onSubmit: (values) => {
      const additionalObj = {
        skipAddress: skipAddressValue,
      };
      if (skipAddressValue) {
        const donorsInfo = { ...values, ...additionalObj };
        if (currentMode === "NEW") {
          setDataToPassForProcessing(donorsInfo);
          setShowAreSureDialog(true);
        }
        currentMode === "EDIT" && dispatch(updateNewDonor(donorsInfo, donorId));
      } else toggleVerticalTab(activeVerticalTab + 1);
    },
  });

  useEffect(() => {
    currentMode == "EDIT" &&
      dispatch(getSingleDonor(editDonorId, editDonorType));
  }, [currentMode, editDonorId, editDonorType]);

  useEffect(() => {
    dispatch(getDonorTypes("for=dropdown"));
  }, [currentMode]);

  useEffect(() => {
    setDonorTypeAsArray(donorType.split(","));
  }, [donorType]);

  useEffect(() => {
    !cachedLocalitySuccess && dispatch(getCachedAddress());
    selectOptionsState(getCachedLocalityForDropDown(cachedLocality));
  }, [cachedLocalitySuccess]);

  useEffect(() => {
    console.log("dd");
    dispatch(getPreferences("preferences"));
  }, []);

  useEffect(() => {
    if (preferences.data.length != 0) {
      setSkipAddressTab(preferences?.data?.SKIP_ADDRESS_ENTRY_TAB);
    }
  }, [preferences]);

  const AddressForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      invite_book_names_en: donorPrintNameInBook_en || "",
      invite_book_print_ta: donorPrintNameInBook_ta || "",
      postal_address_en: donorAddress_en || "",
      state: state || "",
      district: district || "",
      taluk: taluk || "",
      postal_id: postId || "",
      featured_locality: featuredLocality_ta || "",
    },
    validationSchema: Yup.object({
      invite_book_names_en: Yup.string().required(
        "Please provide the donors' alias names in English",
      ),
      invite_book_print_ta: Yup.string().required(
        "Please enter the address in Tamil to print in the invitation book",
      ),
      state: Yup.string().required("Please select state from dropdown"),
      district: Yup.string().required("Please select district from dropdown"),
      taluk: Yup.string().required("Please select taluk from dropdown"),
      postal_id: Yup.string().required(
        "Please select locality, post office, pincode from dropdown",
      ),
    }),
    onSubmit: (values) => {
      const personalInfo = performInfoForm?.values;
      const donorsInfo = { ...personalInfo, ...values, skipAddress: false };
      if (currentMode === "NEW") {
        setDataToPassForProcessing(donorsInfo);
        setShowAreSureDialog(true);
      }
      currentMode === "EDIT" && dispatch(updateNewDonor(donorsInfo, donorId));
    },
  });

  const submitButtonAfterConfirm = () => {
    dispatch(addNewDonor(dataToPassForProcessing));
  };
  const onCloseClickOnSureDialog = () => {
    setShowAreSureDialog(false);
  };

  const toggleVerticalTab = (tab) => {
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];

      if (tab <= 3) {
        setactiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  };
  const localityRefreshOnClick = () => {
    setRawAddressTriggered(true);
    setselectState([]);
    setselectDistrict([]);
    setselectTaluk([]);
    setselectLocality([]);
    selectOptionsState([]);
    setOptionsDistrict([]);
    setOptionsTaluk([]);
    setOptionsLocality([]);
    dispatch(getRawAddress("", "state"));
  };
  const onStateChange = (value) => {
    setselectState([value.value]);
    setselectDistrict([]);
    setOptionsDistrict([]);
    setselectTaluk([]);
    setOptionsTaluk([]);
    setselectLocality([]);
    setOptionsLocality([]);
    AddressForm.setFieldValue("state", value.value);
    if (rawAddressTriggered) {
      dispatch(getRawAddress(`state=${value.value}`, "district"));
    } else {
      setOptionsDistrict(
        getCachedLocalityForDropDown(cachedLocality, "district", value.value),
      );
    }
  };
  const [phoneNumber, setPhoneNumber] = useState(
    currentMode == "EDIT" ? donorPhone : "",
  );
  const onPhoneChange = (e) => {
    setPhoneNumber(e.target.rawValue);
    performInfoForm.setFieldValue("phone", e.target.rawValue);
  };
  const goBackToDonor = () => {
    history.push("/donors-list");
  };
  const reloadDonorPage = () => {
    history.push("/temp");
    history.goBack();
  };
  useEffect(() => {
    if (rawAddressTriggered) {
      selectOptionsState(getRawLocalityForDropDown(rawState));
      setOptionsDistrict(getRawLocalityForDropDown(rawDistrict));
      setOptionsTaluk(getRawLocalityForDropDown(rawTaluk));
      setOptionsLocality(
        getCachedLocalityForDropDown(
          rawLocality,
          "locality",
          AddressForm?.values?.state,
          AddressForm?.values?.district,
          AddressForm?.values?.taluk,
        ),
      );
    }
  }, [rawAddressTriggered, rawState, rawDistrict, rawTaluk, rawLocality]);
  useEffect(() => {
    newDonorSuccess &&
      !newDonorData?.error &&
      toggleVerticalTab(!skipAddressValue ? activeVerticalTab + 1 : 3);
    newDonorSuccess && setShowAreSureDialog(false);
  }, [newDonorData, newDonorSuccess, newDonorError, newDonorLoading]);

  useEffect(() => {
    dispatch(resetSingleDonorData());
    return () => {
      dispatch(resetSingleDonorData());
      toggleVerticalTab(1);
    };
  }, [currentMode]);

  useEffect(() => {
    if (currentMode === "EDIT") {
      setselectState([state]);
      setselectDistrict([district]);
      setselectTaluk([taluk]);
      setselectLocality([postId]);
      setPhoneNumber(donorPhone);
      setOptionsDistrict(
        getCachedLocalityForDropDown(cachedLocality, "district", state),
      );
      setOptionsTaluk(
        getCachedLocalityForDropDown(cachedLocality, "taluk", state, district),
      );
      setOptionsLocality(
        getCachedLocalityForDropDown(
          cachedLocality,
          "locality",
          state,
          district,
          taluk,
        ),
      );
    }
  }, [currentMode, cachedLocality, state, district, taluk, postId, donorPhone]);

  useEffect(() => {
    updateDonorSuccess &&
      setTimeout(() => {
        history.push("/donors-list");
      }, 3000);
    return () => {
      setTimeout(() => {
        dispatch(updateDonorReset());
      }, 3000);
    };
  }, [updateDonorSuccess]);

  useEffect(() => {
    setactiveVerticalTab(1);
  }, []);

  useEffect(() => {
    currentMode === "EDIT" && setSkipAddressValue(isAddressSkipped == 1);
  }, [isAddressSkipped, currentMode]);

  const buttonsToShow = () => (
    <Col md={4} className="d-flex justify-content-end">
      {currentMode === "NEW" && (
        <Button
          color="primary"
          className="btn-label right ms-auto nexttab w-xs-100 mt-xs-3"
          type="submit"
          disabled={newDonorLoading}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Register Now
          {newDonorLoading && (
            <Spinner
              size="sm"
              type="grow"
              className="flex-shrink-0 ms-2"
              role="status"
            ></Spinner>
          )}
        </Button>
      )}
      {currentMode === "EDIT" && (
        <Button
          color="primary"
          className="btn-label right ms-auto nexttab w-xs-100 mt-xs-3"
          type="submit"
          disabled={updateDonorLoading}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Save Now
          {updateDonorLoading && (
            <Spinner
              size="sm"
              type="grow"
              className="flex-shrink-0 ms-2"
              role="status"
            ></Spinner>
          )}
        </Button>
      )}
    </Col>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={`${pageTitle}`} pageTitle="Donors" />
          <AreYouSure
            dialogTitle="Are you sure?"
            dialogDesc="Once registered cannot be undone."
            show={showAreSureDialog}
            onCloseClick={onCloseClickOnSureDialog}
            onSubmitClick={submitButtonAfterConfirm}
            disableSubmitButton={newDonorLoading}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="vertical-navs-step">
                    <Row className="gy-5">
                      <Col lg={3}>
                        <Nav className="flex-column custom-nav nav-pills">
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: activeVerticalTab === 1,
                                done: activeVerticalTab > 1,
                              })}
                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"></i>
                                Step 1
                              </span>
                              Personal Info
                            </NavLink>
                          </NavItem>
                          {!skipAddressValue && (
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({
                                  active: activeVerticalTab === 2,
                                  done: activeVerticalTab >= 2,
                                })}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Step 2
                                </span>
                                Address
                              </NavLink>
                            </NavItem>
                          )}
                          {currentMode !== "EDIT" && (
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({
                                  active: activeVerticalTab === 3,
                                  done: activeVerticalTab >= 3,
                                })}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Step {skipAddressValue ? 2 : 3}
                                </span>
                                Finish
                              </NavLink>
                            </NavItem>
                          )}
                        </Nav>
                      </Col>
                      <Col lg={9}>
                        <div className="px-lg-4">
                          <TabContent activeTab={activeVerticalTab}>
                            <TabPane tabId={1}>
                              {isSingleApiLoading && <LoadingBar />}
                              {((!isSingleApiLoading &&
                                currentMode === "EDIT") ||
                                currentMode === "NEW") && (
                                <FormikProvider value={performInfoForm}>
                                  <Form
                                    innerRef={perfornalFormRef}
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      performInfoForm.handleSubmit();
                                      return false;
                                    }}
                                    action="#"
                                  >
                                    <div>
                                      <h5>Personal Info</h5>
                                      <p className="text-muted">
                                        Fill all information below
                                      </p>
                                    </div>

                                    <div>
                                      <Row className="g-3">
                                        {currentMode === "EDIT" && (
                                          <Col sm={6}>
                                            <Label>Register ID</Label>
                                            <Input
                                              type="text"
                                              className="w-50 fs-5 text form-control fw-bold bg-primary border-light text-white"
                                              id="donor_regId"
                                              value={`${donorId || ""}`}
                                              readOnly={true}
                                            />
                                          </Col>
                                        )}
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="donor_type"
                                            className="form-label"
                                          >
                                            Donor Type{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Select
                                            classNamePrefix="myOwnSelect"
                                            value={donorTypes.filter((option) =>
                                              donorTypeAsArray.includes(
                                                option.value,
                                              ),
                                            )}
                                            isLoading={!donorTypesSuccess}
                                            isMulti={true}
                                            id="donor_type"
                                            name="donor_type"
                                            isDisabled={disableDonorEdit(
                                              currentMode,
                                            )}
                                            className={
                                              performInfoForm?.touched
                                                ?.donor_type &&
                                              performInfoForm?.errors
                                                ?.donor_type
                                                ? "is-invalid"
                                                : ""
                                            }
                                            onChange={(value) => {
                                              var type = value
                                                .map((e) => e.value)
                                                .toString();
                                              setDonorTypeAsArray(
                                                type.split(","),
                                              );
                                              performInfoForm.setFieldValue(
                                                "donor_type",
                                                type,
                                              );
                                            }}
                                            options={donorTypes}
                                            placeholder={"Select Donor Type"}
                                          />
                                          {performInfoForm?.touched
                                            ?.donor_type &&
                                            performInfoForm?.errors
                                              ?.donor_type && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  performInfoForm?.errors
                                                    ?.donor_type
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {
                                                  performInfoForm.errors
                                                    .donor_type
                                                }
                                              </FormFeedback>
                                            )}
                                        </Col>
                                        {currentMode !== "EDIT" && (
                                          <Col sm={6}></Col>
                                        )}
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="donorName_en"
                                            className="form-label"
                                          >
                                            Name in English{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="donorName_en"
                                            placeholder="Enter name in english"
                                            value={
                                              performInfoForm.values
                                                .donorName_en || ""
                                            }
                                            autoComplete="off"
                                            onChange={
                                              performInfoForm.handleChange
                                            }
                                            onBlur={performInfoForm.handleBlur}
                                            invalid={
                                              performInfoForm.touched
                                                .donorName_en &&
                                              performInfoForm.errors
                                                .donorName_en
                                            }
                                            readOnly={disableDonorEdit(
                                              currentMode,
                                            )}
                                          />

                                          {performInfoForm.touched
                                            .donorName_en &&
                                          performInfoForm.errors
                                            .donorName_en ? (
                                            <FormFeedback type="invalid">
                                              {
                                                performInfoForm.errors
                                                  .donorName_en
                                              }
                                            </FormFeedback>
                                          ) : null}
                                        </Col>

                                        <Col sm={6}>
                                          <Label
                                            htmlFor="donorName_ta"
                                            className="form-label"
                                          >
                                            Name in Tamil{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <GoogleTranslatorInputField
                                            type="text"
                                            className="form-control"
                                            id="donorName_ta"
                                            placeholder="Enter name in tamil"
                                            autoComplete="off"
                                            value={
                                              performInfoForm.values
                                                .donorName_ta || ""
                                            }
                                            onChange={
                                              performInfoForm.handleChange
                                            }
                                            onBlur={performInfoForm.handleBlur}
                                            invalid={
                                              performInfoForm.touched
                                                .donorName_ta &&
                                              performInfoForm.errors
                                                .donorName_ta
                                            }
                                            readOnly={disableDonorEdit(
                                              currentMode,
                                            )}
                                          />
                                          {performInfoForm.touched
                                            .donorName_ta &&
                                          performInfoForm.errors
                                            .donorName_ta ? (
                                            <FormFeedback type="invalid">
                                              {
                                                performInfoForm.errors
                                                  .donorName_ta
                                              }
                                            </FormFeedback>
                                          ) : null}
                                        </Col>

                                        <Col sm={6}>
                                          <Label
                                            htmlFor="phone"
                                            className="form-label"
                                          >
                                            Phone{" "}
                                            {preferences?.data
                                              ?.enablePhoneNumber && (
                                              <span className="text-danger">
                                                *
                                              </span>
                                            )}
                                          </Label>
                                          <div className="input-group">
                                            <div className="input-group-text">
                                              +91
                                            </div>
                                            <Cleave
                                              placeholder="xxxx xxx xxx"
                                              value={phoneNumber}
                                              onChange={onPhoneChange}
                                              options={{
                                                phone: true,
                                                phoneRegionCode: "IN",
                                              }}
                                              className={
                                                performInfoForm?.touched
                                                  ?.phone &&
                                                performInfoForm?.errors?.phone
                                                  ? "form-control is-invalid"
                                                  : "form-control"
                                              }
                                            />
                                            {performInfoForm?.touched?.phone &&
                                              performInfoForm?.errors
                                                ?.phone && (
                                                <FormFeedback
                                                  type="invalid"
                                                  className={
                                                    performInfoForm?.errors
                                                      ?.phone
                                                      ? "d-block"
                                                      : ""
                                                  }
                                                >
                                                  {performInfoForm.errors.phone}
                                                </FormFeedback>
                                              )}
                                          </div>
                                          {phoneNumber == "" &&
                                            preferences?.data
                                              ?.enablePhoneNumber && (
                                              <>
                                                <div
                                                  className="text-decoration-underline fst-italic text-muted text-end"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setPhoneNumber(
                                                      preferences?.data
                                                        ?.defaultPhoneNumber,
                                                    );
                                                    performInfoForm.setFieldValue(
                                                      "phone",
                                                      preferences?.data
                                                        ?.defaultPhoneNumber,
                                                    );
                                                  }}
                                                >
                                                  <small>
                                                    Click here to fill default
                                                    phone number{" "}
                                                    {`${preferences?.data?.defaultPhoneNumber}`}
                                                  </small>
                                                </div>
                                              </>
                                            )}
                                        </Col>

                                        <Col sm={6}>
                                          <Label
                                            htmlFor="email"
                                            className="form-label"
                                          >
                                            Email (optional)
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter email id"
                                            value={
                                              performInfoForm.values.email || ""
                                            }
                                            autoComplete="false"
                                            onChange={
                                              performInfoForm.handleChange
                                            }
                                            onBlur={performInfoForm.handleBlur}
                                            invalid={
                                              performInfoForm.touched.email &&
                                              performInfoForm.errors.email
                                            }
                                          />

                                          {performInfoForm.touched.email &&
                                          performInfoForm.errors.email ? (
                                            <FormFeedback type="invalid">
                                              {performInfoForm.errors.email}
                                            </FormFeedback>
                                          ) : null}
                                        </Col>
                                      </Row>
                                    </div>
                                    <Row className="mt-4">
                                      {skipAddressTab && (
                                        <Col sm={12}>
                                          <p className="m-0 mb-2 text-end">
                                            <input
                                              className="form-check-input me-2"
                                              type="checkbox"
                                              value="1"
                                              name="skipaddress"
                                              id="skipaddress"
                                              defaultChecked={
                                                isAddressSkipped === 1
                                              }
                                              onChange={(e) =>
                                                setSkipAddressValue(
                                                  e.target.checked,
                                                )
                                              }
                                            />
                                            <label htmlFor="skipaddress">
                                              Skip the Address tab for now
                                            </label>
                                          </p>
                                        </Col>
                                      )}
                                      <Col md={6}>
                                        <Button
                                          color="light"
                                          onClick={goBackToDonor}
                                          className="btn-label previestab w-xs-100"
                                        >
                                          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                          Go back to Donor List
                                        </Button>
                                      </Col>
                                      <Col
                                        md={6}
                                        className="d-flex justify-content-end"
                                      >
                                        {!skipAddressValue && (
                                          <Button
                                            color="primary"
                                            className="btn-label right ms-auto nexttab w-xs-100 mt-xs-3"
                                            type="submit"
                                          >
                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                            Go to Address
                                          </Button>
                                        )}
                                        {skipAddressValue && buttonsToShow()}
                                      </Col>
                                    </Row>
                                  </Form>
                                </FormikProvider>
                              )}
                            </TabPane>
                            {!skipAddressValue && (
                              <TabPane tabId={2}>
                                <FormikProvider value={AddressForm}>
                                  <Form
                                    innerRef={AddressFormRef}
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      AddressForm.handleSubmit();
                                      return false;
                                    }}
                                    action="#"
                                  >
                                    <div>
                                      <h5>Address</h5>
                                      <p className="text-muted">
                                        Fill all information below
                                      </p>
                                    </div>
                                    <div>
                                      <Row className="g-3">
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="invite_book_names_en"
                                            className="form-label"
                                          >
                                            Address in English to print in
                                            invitation book (used for search
                                            purpose){" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            type="textarea"
                                            className="form-control"
                                            id="invite_book_names_en"
                                            placeholder="Enter alias of donors in english"
                                            name="invite_book_names_en"
                                            value={
                                              AddressForm.values
                                                .invite_book_names_en ||
                                              donorPrintNameInBook_en
                                            }
                                            autoComplete="false"
                                            onChange={AddressForm.handleChange}
                                            onBlur={AddressForm.handleBlur}
                                            invalid={
                                              AddressForm.touched
                                                .invite_book_names_en &&
                                              AddressForm.errors
                                                .invite_book_names_en
                                            }
                                            rows="5"
                                          />
                                          {AddressForm?.touched
                                            ?.invite_book_names_en &&
                                            AddressForm?.errors
                                              ?.invite_book_names_en && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  AddressForm?.errors
                                                    ?.invite_book_names_en
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {
                                                  AddressForm.errors
                                                    .invite_book_names_en
                                                }
                                              </FormFeedback>
                                            )}
                                        </Col>
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="invite_book_print_ta"
                                            className="form-label"
                                          >
                                            Address in Tamil to print in
                                            invitation book{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <GoogleTranslatorInputField
                                            type="textarea"
                                            className="form-control"
                                            id="invite_book_print_ta"
                                            placeholder="Enter address in tamil to print in invitaion book"
                                            name="invite_book_print_ta"
                                            value={
                                              AddressForm.values
                                                .invite_book_print_ta || ""
                                            }
                                            autoComplete="false"
                                            onChange={AddressForm.handleChange}
                                            onBlur={AddressForm.handleBlur}
                                            invalid={
                                              AddressForm.touched
                                                .invite_book_print_ta &&
                                              AddressForm.errors
                                                .invite_book_print_ta
                                            }
                                            rows="5"
                                          />
                                          {AddressForm?.touched
                                            ?.invite_book_print_ta &&
                                            AddressForm?.errors
                                              ?.invite_book_print_ta && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  AddressForm?.errors
                                                    ?.invite_book_print_ta
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {
                                                  AddressForm.errors
                                                    .invite_book_print_ta
                                                }
                                              </FormFeedback>
                                            )}
                                        </Col>
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="postal_address_en"
                                            className="form-label"
                                          >
                                            Door No., Street Address
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="postal_address_en"
                                            placeholder="Enter postal address (door number, street number etc.,)"
                                            name="postal_address_en"
                                            value={
                                              AddressForm.values
                                                .postal_address_en || ""
                                            }
                                            autoComplete="off"
                                            onChange={AddressForm.handleChange}
                                            onBlur={AddressForm.handleBlur}
                                            invalid={
                                              AddressForm.touched
                                                .postal_address_en &&
                                              AddressForm.errors
                                                .postal_address_en
                                            }
                                            rows="5"
                                          />
                                          {AddressForm?.touched
                                            ?.postal_address_en &&
                                            AddressForm?.errors
                                              ?.postal_address_en && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  AddressForm?.errors
                                                    ?.postal_address_en
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {
                                                  AddressForm.errors
                                                    .postal_address_en
                                                }
                                              </FormFeedback>
                                            )}
                                        </Col>
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="state"
                                            className="form-label"
                                          >
                                            State{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Row className="g-0">
                                            <Col sm={12}>
                                              <Row>
                                                <Col md={10} xs={10}>
                                                  <Select
                                                    classNamePrefix="myOwnSelect"
                                                    isMulti={false}
                                                    value={optionsState.filter(
                                                      (option) =>
                                                        selectState?.includes(
                                                          option.value,
                                                        ),
                                                    )}
                                                    id="state"
                                                    name="state"
                                                    className={
                                                      AddressForm?.touched
                                                        ?.state &&
                                                      AddressForm?.errors?.state
                                                        ? "is-invalid"
                                                        : ""
                                                    }
                                                    onChange={(value) => {
                                                      onStateChange(value);
                                                    }}
                                                    options={optionsState}
                                                    placeholder={"Select state"}
                                                  />
                                                </Col>
                                                <Col md={2} xs={2}>
                                                  <Button
                                                    color="primary"
                                                    className="btn-icon"
                                                    onClick={
                                                      localityRefreshOnClick
                                                    }
                                                  >
                                                    {" "}
                                                    <i className="ri-refresh-line" />{" "}
                                                  </Button>
                                                </Col>
                                              </Row>
                                              {AddressForm?.touched?.state &&
                                                AddressForm?.errors?.state && (
                                                  <FormFeedback
                                                    type="invalid"
                                                    className={
                                                      AddressForm?.errors?.state
                                                        ? "d-block"
                                                        : ""
                                                    }
                                                  >
                                                    {AddressForm.errors.state}
                                                  </FormFeedback>
                                                )}
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="district"
                                            className="form-label"
                                          >
                                            District{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Select
                                            classNamePrefix="myOwnSelect"
                                            isMulti={false}
                                            value={optionsDistrict.filter(
                                              (option) =>
                                                selectDistrict.includes(
                                                  option.value,
                                                ),
                                            )}
                                            id="district"
                                            name="district"
                                            isDisabled={
                                              selectState.length === 0
                                            }
                                            className={
                                              AddressForm?.touched?.district &&
                                              AddressForm?.errors?.district
                                                ? "is-invalid"
                                                : ""
                                            }
                                            onChange={(value) => {
                                              setselectDistrict([value.value]);
                                              setselectTaluk([]);
                                              setOptionsTaluk([]);
                                              setselectLocality([]);
                                              setOptionsLocality([]);
                                              if (rawAddressTriggered) {
                                                dispatch(
                                                  getRawAddress(
                                                    `state=${selectState[0]}&district=${value.value}`,
                                                    "taluk",
                                                  ),
                                                );
                                              } else {
                                                setOptionsTaluk(
                                                  getCachedLocalityForDropDown(
                                                    cachedLocality,
                                                    "taluk",
                                                    selectState[0],
                                                    value.value,
                                                  ),
                                                );
                                              }
                                              AddressForm.setFieldValue(
                                                "district",
                                                value.value,
                                              );
                                            }}
                                            options={optionsDistrict}
                                            placeholder={"Select district"}
                                          />
                                          {AddressForm?.touched?.district &&
                                            AddressForm?.errors?.district && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  AddressForm?.errors?.district
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {AddressForm.errors.district}
                                              </FormFeedback>
                                            )}
                                        </Col>
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="taluk"
                                            className="form-label"
                                          >
                                            Taluk{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Select
                                            classNamePrefix="myOwnSelect"
                                            isMulti={false}
                                            value={optionsTaluk.filter(
                                              (option) =>
                                                selectTaluk?.includes(
                                                  option.value,
                                                ),
                                            )}
                                            isDisabled={
                                              selectDistrict.length === 0
                                            }
                                            id="taluk"
                                            name="taluk"
                                            className={
                                              AddressForm?.touched?.taluk &&
                                              AddressForm?.errors?.taluk
                                                ? "is-invalid"
                                                : ""
                                            }
                                            onChange={(value) => {
                                              setselectTaluk([value.value]);
                                              setselectLocality([]);
                                              setOptionsLocality([]);

                                              if (rawAddressTriggered) {
                                                dispatch(
                                                  getRawAddress(
                                                    `state=${selectState[0]}&district=${selectDistrict[0]}&taluk=${value.value}`,
                                                    "locality",
                                                  ),
                                                );
                                              } else {
                                                setOptionsLocality(
                                                  getCachedLocalityForDropDown(
                                                    cachedLocality,
                                                    "locality",
                                                    selectState[0],
                                                    selectDistrict[0],
                                                    value.value,
                                                  ),
                                                );
                                              }
                                              AddressForm.setFieldValue(
                                                "taluk",
                                                value.value,
                                              );
                                            }}
                                            options={optionsTaluk}
                                            placeholder={"Select taluk"}
                                          />
                                          {AddressForm?.touched?.taluk &&
                                            AddressForm?.errors?.taluk && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  AddressForm?.errors?.taluk
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {AddressForm.errors.taluk}
                                              </FormFeedback>
                                            )}
                                        </Col>
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="postal_id"
                                            className="form-label"
                                          >
                                            Locality, Post Office &amp; Pincode{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          <Select
                                            classNamePrefix="myOwnSelect"
                                            isMulti={false}
                                            value={optionsLocality.filter(
                                              (option) =>
                                                selectLocality?.includes(
                                                  option.value,
                                                ),
                                            )}
                                            isDisabled={
                                              selectTaluk.length === 0
                                            }
                                            id="postal_id"
                                            name="postal_id"
                                            className={
                                              AddressForm?.touched?.postal_id &&
                                              AddressForm?.errors?.postal_id
                                                ? "is-invalid"
                                                : ""
                                            }
                                            onChange={(value) => {
                                              setselectLocality([value.value]);
                                              AddressForm.setFieldValue(
                                                "postal_id",
                                                value.value,
                                              );
                                            }}
                                            options={optionsLocality}
                                            placeholder={"Select locality"}
                                          />
                                          {AddressForm?.touched?.postal_id &&
                                            AddressForm?.errors?.postal_id && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  AddressForm?.errors?.postal_id
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {AddressForm.errors.postal_id}
                                              </FormFeedback>
                                            )}
                                        </Col>
                                        <Col sm={6}>
                                          <Label
                                            htmlFor="featured_locality"
                                            className="form-label"
                                          >
                                            Featured Locality{" "}
                                          </Label>
                                          <GoogleTranslatorInputField
                                            type="text"
                                            className="form-control"
                                            id="featured_locality"
                                            placeholder="Enter featured locality"
                                            name="featured_locality"
                                            value={
                                              AddressForm.values
                                                .featured_locality || ""
                                            }
                                            autoComplete="off"
                                            onChange={AddressForm.handleChange}
                                            onBlur={AddressForm.handleBlur}
                                            invalid={
                                              AddressForm.touched
                                                .featured_locality &&
                                              AddressForm.errors
                                                .featured_locality
                                            }
                                          />
                                          {AddressForm?.touched
                                            ?.featured_locality &&
                                            AddressForm?.errors
                                              ?.featured_locality && (
                                              <FormFeedback
                                                type="invalid"
                                                className={
                                                  AddressForm?.errors
                                                    ?.featured_locality
                                                    ? "d-block"
                                                    : ""
                                                }
                                              >
                                                {
                                                  AddressForm.errors
                                                    .featured_locality
                                                }
                                              </FormFeedback>
                                            )}
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="mt-4">
                                      <Row>
                                        <Col md={4}>
                                          <button
                                            type="button"
                                            className="btn btn-light btn-label previestab w-xs-100 mt-xs-3"
                                            onClick={() => {
                                              toggleVerticalTab(
                                                activeVerticalTab - 1,
                                              );
                                            }}
                                          >
                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                            Back to Personal Info
                                          </button>
                                        </Col>
                                        <Col md={4}>
                                          <Button
                                            color="warning"
                                            className="btn-label previestab w-xs-100 mt-xs-3"
                                            onClick={goBackToDonor}
                                          >
                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                            Go back to Donor List
                                          </Button>
                                        </Col>
                                        {buttonsToShow()}
                                      </Row>
                                    </div>
                                  </Form>
                                </FormikProvider>
                              </TabPane>
                            )}
                            {currentMode !== "EDIT" && (
                              <TabPane tabId={3}>
                                <div className="text-center pt-4 pb-2">
                                  <div>
                                    <i
                                      className="ri-check-double-line"
                                      style={{ fontSize: "60px" }}
                                    ></i>
                                  </div>
                                  <h5>{`${newDonorData?.message}`}</h5>
                                  <p className="text-muted blink">
                                    {`Donor registration id is `}
                                    <span className="fw-bold fs-4 bg-info text-white p-1">{`${newDonorData?.data?.id}`}</span>
                                  </p>
                                  <Row>
                                    <Col>
                                      <Button
                                        color="primary"
                                        className="me-3"
                                        onClick={reloadDonorPage}
                                      >
                                        Create New Donor
                                      </Button>
                                      <Button
                                        color="primary"
                                        onClick={goBackToDonor}
                                      >
                                        Go back to Donor List
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </TabPane>
                            )}
                          </TabContent>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {!newDonorSuccess && newDonorData?.error && (
        <ToastifyMessage
          status=""
          message={`Error: ${newDonorData?.message}.`}
        />
      )}
      {!updateDonorSuccess && updateDonorData?.error && (
        <ToastifyMessage
          status=""
          message={`Error: ${updateDonorData?.message}.`}
        />
      )}

      {updateDonorSuccess && (
        <ToastifyMessage message={`${updateDonorData?.message}`} />
      )}
    </React.Fragment>
  );
};

export default CreateDonor;
