import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Input,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Badge,
} from "reactstrap";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PageRefresh from "../../Components/PageRefresh";
import { dashboardReport } from "../../store/actions";
import { SOFTWARE_STARTED_YEAR } from "../../helpers/constants";
import { formatINR, isAdminUser, isDonateAllowed } from "../../helpers/utility";
import InSightsLoader from "./InSightsLoader";
import { getPreferences } from "../../store/actions";
import { isEmpty } from "lodash";
import { META_TITLE } from "../../helpers/constants";

const InSights = () => {
  document.title = `Insights || ${META_TITLE}`;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isAdmin] = useState(isAdminUser());
  const [defaultCurrentYear] = useState(new Date().getFullYear());
  const [dashboardYear, setDashboardYear] = useState(defaultCurrentYear);
  const [totalSummary, setTotalSummary] = useState({
    totalAmount: "",
    totalDonors: "",
  });
  const {
    dashboardData = {},
    isDashboardDataSuccess,
    isDashboardDataLoading,
  } = useSelector((state) => ({
    dashboardData: state.Reports.dashboardReports.data,
    isDashboardDataSuccess: state.Reports.dashboardReports.success,
    isDashboardDataLoading: state.Reports.dashboardReports.loading,
  }));
  const { preferences, isPreferencesSuccess, isPreferencesLoading } =
    useSelector((state) => ({
      preferences: state.Preferences.pref?.data,
      isPreferencesLoading: state.Preferences.pref?.loading,
      isPreferencesSuccess: state.Preferences.pref?.success,
    }));
  const yearOptions = () => {
    var arr = [];
    for (let year = SOFTWARE_STARTED_YEAR; year <= defaultCurrentYear; year++) {
      arr.push(
        <option key={year} value={year}>
          {year}
        </option>,
      );
    }
    return arr;
  };

  const selectYearOnlick = (e) => {
    setDashboardYear(e.target.value);
  };

  useEffect(() => {
    !isAdmin && history.push("/dashboard");
  }, []);

  useEffect(() => {
    setTotalSummary({
      totalAmount: dashboardData?.totalAmount,
      totalDonors: dashboardData?.totalDonors,
    });
  }, [dashboardData]);
  const onPageRefresh = () => {
    const year = document.getElementById("year-select")?.value;
    if (year !== undefined && defaultCurrentYear == year) {
      setDashboardYear(year);
      dispatch(dashboardReport(year));
    }
  };
  useEffect(() => {
    dispatch(dashboardReport(dashboardYear));
  }, [dashboardYear]);

  useEffect(() => {
    !isPreferencesSuccess &&
      isEmpty(preferences) &&
      dispatch(getPreferences("preferences"));
  }, [isPreferencesSuccess]);

  return (
    <React.Fragment>
      {(isDashboardDataLoading || isPreferencesLoading) && <InSightsLoader />}
      {isDashboardDataSuccess && isPreferencesSuccess && (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col>
                {totalSummary.totalDonors === 0 && (
                  <>
                    <div className="card-animate card bg-body-secondary">
                      <div className="card-body">
                        <p className="mb-0 fs-4">
                          Payment for the year {new Date().getFullYear()} has
                          not started yet. Please check back later or contact
                          the app administrator.
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {totalSummary.totalDonors > 0 && (
                  <>
                    <div className="h-100">
                      <Row className="mb-3 pb-1 tab-padding-fix-margin">
                        <Col xs={12}>
                          <Row className="text-end">
                            <Col className="mt-xs-3">
                              <Row>
                                <Col md={1}>
                                  <Input
                                    type={"select"}
                                    id="year-select"
                                    className="form-select float-end"
                                    onChange={(e) => selectYearOnlick(e)}
                                    value={dashboardYear}
                                  >
                                    {yearOptions()}
                                  </Input>
                                </Col>
                                {defaultCurrentYear == dashboardYear &&
                                  isDonateAllowed(preferences) && (
                                    <Col>
                                      <PageRefresh
                                        cb={onPageRefresh}
                                        show={isDonateAllowed(preferences)}
                                        timerInMinutes={
                                          preferences?.insightsPageRefreshTimer
                                        }
                                      />
                                    </Col>
                                  )}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-md-6 col-xl-3">
                          <div className="card-animate card bg-primary">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-medium text-truncate mb-0 text-white">
                                    Total Earnings
                                  </p>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="fs-14 mb-0 text-white">
                                    <i className="fs-13 align-middle ri-arrow-right-up-line"></i>{" "}
                                    {totalSummary.totalDonors} நன்கொடையாளர்கள்
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  {isDashboardDataSuccess && (
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4 text-white">
                                      <CountUp
                                        start={0}
                                        prefix="&#8377;"
                                        suffix=""
                                        separator=","
                                        end={totalSummary.totalAmount}
                                        decimals={2}
                                        duration={4}
                                      />
                                    </h4>
                                  )}
                                  {isDashboardDataLoading && (
                                    <Spinner
                                      size="sm"
                                      type="grow"
                                      className="flex-shrink-0"
                                      role="status"
                                    >
                                      {" "}
                                      Loading...{" "}
                                    </Spinner>
                                  )}
                                  <a className="text-decoration-underline text-white">
                                    {`${dashboardYear}`}
                                  </a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title rounded fs-3 bg-light pt-1 px-1">
                                    <i className="text-primary ri-hand-coin-line"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {dashboardData?.productWise?.map(
                          (
                            {
                              productName_ta,
                              totalDonors,
                              productPrice,
                              totalAmount,
                            },
                            key,
                          ) => (
                            <div className="col-md-6 col-xl-3" key={key}>
                              <div className="card-animate card">
                                <div className="card-body">
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0 fw-bold">
                                        {`${productName_ta}`}
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <h5 className="fs-14 mb-0 text-primary">
                                        <i className="fs-13 align-middle ri-arrow-right-up-line"></i>{" "}
                                        {`${totalDonors}`} நன்கொடையாளர்கள்
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-end justify-content-between mt-4">
                                    <div>
                                      <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                        <CountUp
                                          start={0}
                                          prefix="&#8377;"
                                          suffix=""
                                          separator=","
                                          end={totalAmount}
                                          decimals={2}
                                          duration={2}
                                        />
                                      </h4>
                                      <a className="text-decoration-underline text-muted">
                                        தீர்மானிக்கப்பட்ட விலை:
                                        {`${formatINR(productPrice)}`}
                                      </a>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                      <span className="avatar-title rounded fs-3">
                                        <i className="text-primary ri-shopping-bag-line"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                      </Row>
                      <Row>
                        <Col xl={3}>
                          <Card>
                            <CardHeader className="align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                User Based Collection
                              </h4>
                            </CardHeader>
                            <CardBody>
                              <div className="table-responsive table-card">
                                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                                  <tbody>
                                    {dashboardData?.userWise?.map(
                                      ({ totalAmount, name }, key) => (
                                        <tr key={key}>
                                          <td>
                                            <h5 className="fs-14 my-1 fw-normal">
                                              {formatINR(totalAmount)}
                                            </h5>
                                            <span className="text-muted">
                                              Total Amount
                                            </span>
                                          </td>
                                          <td>
                                            <h5 className="fs-14 my-1 fw-normal">
                                              {name}
                                            </h5>
                                            <span className="text-muted">
                                              User Name
                                            </span>
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={9}>
                          <Card>
                            <CardHeader className="align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                Recent Receipts
                              </h4>
                            </CardHeader>
                            <CardBody>
                              <div className="table-responsive table-card">
                                <table className="table table-centered align-middle table-nowrap mb-0">
                                  <thead className="text-muted table-light fw-bold">
                                    <tr>
                                      <td>Invoice Number</td>
                                      <td>Donor Name</td>
                                      <td>Receipt Type</td>
                                      <td>Total Amount</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dashboardData?.invoiceWise?.map(
                                      (
                                        {
                                          invoiceNumber,
                                          donorName_ta,
                                          donorPhone,
                                          isReversed,
                                          totalAmount,
                                          invoiceType,
                                        },
                                        key,
                                      ) => (
                                        <tr key={key}>
                                          <td>
                                            {invoiceNumber}
                                            {isReversed == "Y" && (
                                              <Badge
                                                color="danger"
                                                pill
                                                className="fw-bold ms-2"
                                              >
                                                {" "}
                                                CANCELLED{" "}
                                              </Badge>
                                            )}
                                          </td>
                                          <td>
                                            {donorName_ta}
                                            {", "}
                                            {donorPhone}
                                          </td>
                                          <td>{invoiceType}</td>
                                          <td>{formatINR(totalAmount)}</td>
                                        </tr>
                                      ),
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default InSights;
