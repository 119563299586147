import fetch from "isomorphic-fetch";

const baseURL = process.env.REACT_APP_API_URL;
class APIClient {
  constructor() {
    this.token = null;
  }
  getToken = () => {
    return JSON.parse(localStorage.getItem("authUser"))
      ? JSON.parse(localStorage.getItem("authUser")).access_token
      : null;
  };
  fetchAuthenticated = (method, url, data = {}, ignoreToken = false) => {
    const token = this.getToken();
    let options = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    if (Object.keys(data).length > 0) {
      options.body = JSON.stringify(data);
    }
    ignoreToken && delete options?.headers?.Authorization;
    return fetch(`${baseURL}${url}`, options).then((response) => {
      return response
        .text()
        .then((text) => {
          const data = text !== "" ? JSON.parse(text) : [];
          const status = response?.status;
          if (
            status === 500 ||
            data?.message === "The token has been blacklisted"
          ) {
            localStorage.removeItem("authUser");
            window.location.href = "/login";
          }
          return { data, status };
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  get = (url, params) => {
    return this.fetchAuthenticated("GET", `${url}`, params);
  };
  /**
   * post given data to url
   */
  create = (url, data, ignoreToken) => {
    return this.fetchAuthenticated("POST", `${url}`, data, ignoreToken);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return this.fetchAuthenticated("PATCH", `${url}`, data);
  };

  put = (url, data) => {
    return this.fetchAuthenticated("PUT", `${url}`, data);
  };
  /**
   * Delete
   */
  delete = () => {
    //return axios.delete(url, { ...config });
  };
  download = (url, fileName, mimeType) => {
    fetch(`${baseURL}${url}`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + this.getToken(),
        "Content-Type": mimeType,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, getLoggedinUser };
