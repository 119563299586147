import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createDonorType, updateDonorType } from "../../store/actions";
import { useProfile } from "../../Components/Hooks/UserHooks";
import {
  Modal,
  ModalHeader,
  Label,
  Input,
  Col,
  Row,
  FormFeedback,
  Form,
  Button,
  Alert,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";

const ViewModal = ({
  openModal,
  openViewModalOnClick,
  callbackMethod,
  mode = "NEW",
  dataToFill = {},
}) => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "" || mode == "EDIT" ? dataToFill?.name : "",
      slug: "" || mode == "EDIT" ? dataToFill?.slug : "",
      edtiror: "" || mode == "EDIT" ? dataToFill?.edtiror : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter donor type"),
      slug: Yup.string().required("Please enter donor slug id"),
      edtiror: Yup.string().required("Please enter donor edtiror id"),
    }),
    onSubmit: (values, { resetForm }) => {
      mode == "NEW" &&
        dispatch(
          createDonorType({ ...values, addedBy: userProfile?.user?.id }),
        );
      mode == "EDIT" &&
        dispatch(
          updateDonorType(dataToFill?.id, {
            ...values,
            addedBy: userProfile?.user?.id,
          }),
        );
      resetForm();
    },
  });

  const { error } = useSelector((state) => ({
    error: state.DonorTypes.createDonor.error,
  }));

  const { successMessage } = useSelector((state) => ({
    successMessage: state.DonorTypes.createDonor.data.message,
  }));

  const { successEditMessage } = useSelector((state) => ({
    successEditMessage: state.DonorTypes.updateDonor.data.message,
  }));

  useEffect(() => {
    (successMessage != undefined || successEditMessage != undefined) &&
      callbackMethod();
  }, [successMessage, successEditMessage]);

  return (
    <React.Fragment>
      <Modal
        size="sm"
        isOpen={openModal}
        toggle={() => {
          openViewModalOnClick();
        }}
      >
        <ModalHeader
          toggle={() => {
            openViewModalOnClick();
          }}
        >
          {mode !== "NEW" ? `${"Update"}` : `${"Add"}`} Donor Type
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <div className="modal-body">
            <Row>
              <Col lg={12}>
                {error && error?.error ? (
                  <Alert color="danger"> {error.message} </Alert>
                ) : null}
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <Label for="name" className="form-label">
                    Type Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter donor type name"
                    autoComplete="off"
                    id="name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <Label for="slug" className="form-label">
                    Slug ID <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter slug id"
                    autoComplete="off"
                    id="slug"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.slug || ""}
                    invalid={
                      validation.touched.slug && validation.errors.slug
                        ? true
                        : false
                    }
                  />

                  {validation.touched.slug && validation.errors.slug ? (
                    <FormFeedback type="invalid">
                      {validation.errors.slug}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="primary" className="w-100" type="submit">
              {mode !== "NEW" ? `${"Update"}` : `${"Add"}`}
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

ViewModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  openViewModalOnClick: PropTypes.func.isRequired,
  callbackMethod: PropTypes.func.isRequired,
  mode: PropTypes.string,
  dataToFill: PropTypes.any.isRequired,
};

export default ViewModal;
