import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  Form,
  CardHeader,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";
import { prepareOptionsWithyears } from "../../helpers/utility";
import { getProducts, generateReports } from "../../store/actions";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import ReportPrint from "../../Prints/ReportPrint";
import { formatINR } from "../../helpers/utility";
import SimpleBar from "../../Components/SimpleBar";
import { META_TITLE } from "../../helpers/constants";

const Reports = () => {
  document.title = `Reports || ${META_TITLE}`;
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const generateRef = useRef();
  const reportReceiptRef = useRef();
  const [print, setPrint] = useState(false);

  const { productsList = [], isProductsSuccess } = useSelector((state) => ({
    productsList: state.Products.allProducts.data,
    isProductsSuccess: state.Products.allProducts.success,
    isProductsHasError: state.Products.allProducts.error,
  }));

  const { reportsData = {}, isReportsSuccess } = useSelector((state) => ({
    reportsData: state.Reports.reports.data,
    isReportsSuccess: state.Reports.reports.success,
    isReportsHasError: state.Reports.reports.error,
    isReportsLoading: state.Reports.reports.loading,
  }));

  const generateReport = useFormik({
    enableReinitialize: true,
    initialValues: {
      donors: "all",
      year: "",
      product: "",
      product_type: "",
    },
    validationSchema: Yup.object({
      donors: Yup.string().required("Please enter donor type"),
      year: Yup.string().required("Please enter donor name in english"),
      product: Yup.string().required("Please enter donor name in tamil"),
      product_type: Yup.string().required("Please enter donor name in tamil"),
    }),
    onSubmit: (values) => {
      dispatch(generateReports(values));
    },
  });

  const getAllProducts = (year) => {
    dispatch(getProducts(year));
  };
  useEffect(() => {
    let productsMap = [{ label: "All", value: "all" }];
    productsList.map(({ name, name_ta, id }) => {
      let obj = {};
      obj.label = `${name_ta} - ${name}`;
      obj.value = id;
      productsMap.push(obj);
    });
    setProducts(productsMap);
  }, [isProductsSuccess]);

  const handlePrint = useReactToPrint({
    content: () => reportReceiptRef.current,
  });
  const printNow = () => {
    setPrint(true);
    setTimeout(() => {
      handlePrint();
    }, 0);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Generate Report" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <FormikProvider value={generateReport}>
                    <Form
                      innerRef={generateRef}
                      onSubmit={(e) => {
                        e.preventDefault();
                        generateReport.handleSubmit();
                        return false;
                      }}
                      action="#"
                    >
                      <Row>
                        <Col md={3}>
                          <div>
                            <Label className="form-label">Donor(s)</Label>
                            <Select
                              classNamePrefix="myOwnSelect"
                              isMulti={false}
                              defaultValue={{ label: "All", value: "all" }}
                              id="report_donors"
                              name="donors"
                              options={[{ label: "All", value: "all" }]}
                              placeholder={"Select donor"}
                            />
                            {generateReport?.touched?.donors &&
                              generateReport?.errors?.donors && (
                                <div className="text-danger">
                                  {generateReport.errors.donors}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col md={3} className="mt-xs-3">
                          <div>
                            <Label className="form-label">Year</Label>
                            <Select
                              classNamePrefix="myOwnSelect"
                              isMulti={false}
                              id="report_year"
                              name="year"
                              options={prepareOptionsWithyears("", [], true)}
                              placeholder={"Select year"}
                              onChange={(selected) => {
                                generateReport.setFieldValue(
                                  "year",
                                  selected.value,
                                );
                                getAllProducts(selected.value);
                              }}
                            />
                            {generateReport?.touched?.year &&
                              generateReport?.errors?.year && (
                                <div className="text-danger">
                                  {generateReport.errors.year}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col md={3} className="mt-xs-3">
                          <div>
                            <Label className="form-label">Product</Label>
                            <Select
                              classNamePrefix="myOwnSelect"
                              isMulti={false}
                              id="report_product"
                              name="product"
                              options={products}
                              placeholder={"Select product type"}
                              onChange={(selected) => {
                                generateReport.setFieldValue(
                                  "product",
                                  selected.value,
                                );
                              }}
                            />
                            {generateReport?.touched?.product &&
                              generateReport?.errors?.product && (
                                <div className="text-danger">
                                  {generateReport.errors.product}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col md={3} className="mt-xs-3">
                          <div>
                            <Label className="form-label">Product Type</Label>
                            <Select
                              classNamePrefix="myOwnSelect"
                              isMulti={false}
                              id="report_product_type"
                              name="product_type"
                              options={[
                                { label: "Rupees", value: "Rupees" },
                                { label: "Products", value: "Products" },
                              ]}
                              placeholder={"Select product type"}
                              onChange={(selected) => {
                                generateReport.setFieldValue(
                                  "product_type",
                                  selected.value,
                                );
                              }}
                            />
                            {generateReport?.touched?.product_type &&
                              generateReport?.errors?.product_type && (
                                <div className="text-danger">
                                  {generateReport.errors.product_type}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col md={3}>
                          <div>
                            <Label className="form-label invisible w-xs-100">
                              Product Type
                            </Label>
                            <div>
                              <Button
                                color="primary"
                                type="submit"
                                className="w-xs-100"
                              >
                                Generate Report
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
            {isReportsSuccess && (
              <Col>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={2} className="mb-3">
                        <h4 className="card-title mb-0">Report</h4>
                      </Col>
                      <Col>
                        {reportsData?.data.length !== 0 && (
                          <h4
                            className="card-title mb-0 text-end"
                            style={{ lineHeight: "30px" }}
                          >
                            Total Donors :{" "}
                            <span className="text-primary">
                              {reportsData?.data?.length}
                            </span>
                            ,<br /> Total Amount:{" "}
                            <span className="text-primary">
                              {formatINR(reportsData.total)}
                            </span>
                            <Button
                              color="primary"
                              className="ms-2"
                              onClick={printNow}
                            >
                              Print Report
                            </Button>
                          </h4>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="mx-n3">
                      {reportsData?.data.length !== 0 && (
                        <SimpleBar
                          style={{ maxHeight: "300px" }}
                          className="px-3 overflow-auto"
                          autoHide={false}
                        >
                          <Table className="table align-middle table-nowrap table-striped-columns mb-0">
                            <thead className="table-light">
                              <tr>
                                <td>#</td>
                                <td>Registration Number</td>
                                <td>Donor Name</td>
                                <td>Product</td>
                                {reportsData?.data[0]?.amount && (
                                  <td>Amount</td>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {reportsData?.data.map(
                                (
                                  {
                                    donorId,
                                    remarks,
                                    amount,
                                    productName_ta,
                                    phone,
                                    donorName_ta,
                                  },
                                  index,
                                ) => (
                                  <tr key={index + 1}>
                                    <td>{index + 1}</td>
                                    <td>{donorId}</td>
                                    <td>{`${donorName_ta}(${phone}). ${remarks}`}</td>
                                    <td>{productName_ta}</td>
                                    {reportsData?.data[0]?.amount && (
                                      <td> &#8377; {amount}</td>
                                    )}
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </Table>
                        </SimpleBar>
                      )}
                      {reportsData?.data.length === 0 && (
                        <div className="text-center">No report found</div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          {print && (
            <div className="d-none">
              <ReportPrint
                ref={reportReceiptRef}
                dataToPrint={reportsData}
                dataOptions={generateReport.values}
              />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reports;
