import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getPincode } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import Grid from "../../Components/Grid";
import LoadingBar from "../../Components/Common/LoadingBar";
import ReactDOM from "react-dom/client";
import { META_TITLE } from "../../helpers/constants";

const Locality = () => {
  const dispatch = useDispatch();
  document.title = `Pincode finder || ${META_TITLE}`;
  const [dontShowInitialLoader, setDontShowInitialLoader] = useState(false);
  const {
    pincodeData = [],
    isPincodeSccess,
    isPincodeLoading,
  } = useSelector((state) => ({
    pincodeData: state.Locality?.pincodeList.data,
    isPincodeSccess: state.Locality?.pincodeList.success,
    isPincodeLoading: state.Locality?.pincodeList.loading,
  }));
  const searchPinFormInit = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      pincode: "",
    },
    validationSchema: Yup.object({
      pincode: Yup.string()
        .matches(/^[1-9][0-9]{5}$/, "Pincode must be 6 digits")
        .required("Pincode is required"),
    }),
    onSubmit: ({ pincode }) => {
      dispatch(getPincode(pincode));
      setDontShowInitialLoader(true);
    },
  });
  const gridColumns = [
    {
      data: "id",
      title: "Ref. ID",
    },
    {
      data: "state",
      title: "State",
    },
    {
      data: "district",
      title: "District",
    },
    {
      data: "taluk",
      title: "Taluk",
    },
    {
      data: "village",
      title: "Village",
    },
    {
      data: "postoffice",
      title: "Post Office",
    },
    {
      data: "pincode",
      title: "Pincode",
    },
    {
      data: "cached",
      title: "is Cached?",
      width: "110px",
    },
  ];
  const columnDefs = [
    {
      targets: [7],
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(
          <>
            <span
              className={
                cellData == 1
                  ? "bg-primary text-white p-1"
                  : "bg-danger text-white p-1"
              }
            >
              {cellData == 1 ? "Cached" : "Uncached"}
            </span>
          </>,
        ),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Locality Listing" pageTitle="Locality" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="position-relative">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchPinFormInit.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <Row>
                      <Col sm="2">
                        <div className="mb-3">
                          <Label htmlFor="pincode" className="form-label">
                            Pincode <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="Enter pincode"
                            autoComplete="off"
                            id="pincode"
                            name="pincode"
                            onChange={searchPinFormInit.handleChange}
                            onBlur={searchPinFormInit.handleBlur}
                            value={searchPinFormInit.values.pincode || ""}
                            invalid={
                              searchPinFormInit.touched.pincode &&
                              searchPinFormInit.errors.pincode
                            }
                          />
                          {searchPinFormInit.touched.pincode &&
                          searchPinFormInit.errors.pincode ? (
                            <FormFeedback type="invalid">
                              {searchPinFormInit.errors.pincode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label
                            htmlFor="email"
                            className="form-label invisible"
                          >
                            Search <span className="text-danger">*</span>
                          </Label>
                          <div>
                            <Button color="primary" type="submit">
                              Search
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col className="w-100 donor-grid">
                      {dontShowInitialLoader ? (
                        <>
                          {isPincodeSccess && (
                            <Grid
                              columns={gridColumns}
                              data={pincodeData}
                              sortOrder={[0, "desc"]}
                              isLoading={isPincodeLoading}
                              columnDefs={columnDefs}
                            />
                          )}
                          {isPincodeLoading && <LoadingBar />}
                        </>
                      ) : (
                        <>
                          <p>
                            Type a pincode above to display the list of matching
                            pincodes.
                          </p>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Locality;
