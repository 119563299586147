export const GET_DONORS_DATA = "GET_DONORS_DATA";
export const GET_DONORS_DATA_SUCCESS = "GET_DONORS_DATA_SUCCESS";
export const GET_DONORS_DATA_FAILED = "GET_DONORS_DATA_FAILED";
export const RESET_DONORS_DATA = "RESET_DONORS_DATA";

export const GET_SINGLE_DONOR = "GET_SINGLE_DONOR";
export const GET_SINGLE_DONOR_SUCCESS = "GET_SINGLE_DONOR_SUCCESS";
export const RESET_SINGLE_DONOR_DATA = "RESET_SINGLE_DONOR_DATA";
export const GET_SINGLE_DONOR_FAILED = "GET_SINGLE_DONOR_FAILED";

export const GET_DONOR_RECEIPTS = "GET_DONOR_RECEIPTS";
export const GET_DONOR_RECEIPTS_SUCCESS = "GET_DONOR_RECEIPTS_SUCCESS";
export const GET_DONOR_RECEIPTS_FAILED = "GET_DONOR_RECEIPTS_FAILED";

export const ADD_DONOR = "ADD_DONOR";
export const ADD_DONOR_SUCCESS = "ADD_DONOR_SUCCESS";
export const ADD_DONOR_FAILED = "ADD_DONOR_FAILED";

export const UPDATE_DONOR = "UPDATE_DONOR";
export const UPDATE_DONOR_SUCCESS = "UPDATE_DONOR_SUCCESS";
export const UPDATE_DONOR_FAILED = "UPDATE_DONOR_FAILED";
export const UPDATE_DONOR_RESET = "UPDATE_DONOR_RESET";

export const BOOK_ADDRESS_DONOR = "BOOK_ADDRESS_DONOR";
export const BOOK_ADDRESS_DONOR_SUCCESS = "BOOK_ADDRESS_DONOR_SUCCESS";
export const BOOK_ADDRESS_DONOR_FAILED = "BOOK_ADDRESS_DONOR_FAILED";
export const BOOK_ADDRESS_DONOR_RESET = "BOOK_ADDRESS_DONOR_RESET";

export const UPDATE_BOOK_ADDRESS_DONOR = "UPDATE_BOOK_ADDRESS_DONOR";
export const UPDATE_BOOK_ADDRESS_DONOR_SUCCESS =
  "UPDATE_BOOK_ADDRESS_DONOR_SUCCESS";
export const UPDATE_BOOK_ADDRESS_DONOR_FAILED =
  "UPDATE_BOOK_ADDRESS_DONOR_FAILED";
export const UPDATE_BOOK_ADDRESS_DONOR_RESET =
  "UPDATE_BOOK_ADDRESS_DONOR_RESET";

export const GENERATE_RECEIPTS = "GENERATE_RECEIPTS";
export const GENERATE_RECEIPTS_SUCCESS = "GENERATE_RECEIPTS_SUCCESS";
export const GENERATE_RECEIPTS_FAILED = "GENERATE_RECEIPTS_FAILED";
export const GENERATE_RECEIPTS_RESET = "GENERATE_RECEIPTS_RESET";

export const INVOICE_LISTS = "INVOICE_LISTS";
export const INVOICE_LISTS_SUCCESS = "INVOICE_LISTS_SUCCESS";
export const INVOICE_LISTS_FAILED = "INVOICE_LISTS_FAILED";
export const INVOICE_LISTS_RESET = "INVOICE_LISTS_RESET";

export const CANCEL_RECEIPTS = "CANCEL_RECEIPTS";
export const CANCEL_RECEIPTS_SUCCESS = "CANCEL_RECEIPTS_SUCCESS";
export const CANCEL_RECEIPTS_FAILED = "CANCEL_RECEIPTS_FAILED";
export const CANCEL_RECEIPTS_RESET = "CANCEL_RECEIPTS_RESET";

export const DELETE_DONOR = "DELETE_DONOR";
export const DELETE_DONOR_SUCCESS = "DELETE_DONOR_SUCCESS";
export const DELETE_DONOR_FAILED = "DELETE_DONOR_FAILED";
export const DELETE_DONOR_RESET = "DELETE_DONOR_RESET";

export const GET_INVOICE_RAW = "GET_INVOICE_RAW";
export const GET_INVOICE_RAW_SUCCESS = "GET_INVOICE_RAW_SUCCESS";
export const GET_INVOICE_RAW_FAILED = "GET_INVOICE_RAW_FAILED";
export const GET_INVOICE_RAW_RESET = "GET_INVOICE_RAW_RESET";

export const GET_INVOICE_NUMBER = "GET_INVOICE_NUMBER";
export const GET_INVOICE_NUMBER_SUCCESS = "GET_INVOICE_NUMBER_SUCCESS";
export const GET_INVOICE_NUMBER_FAILED = "GET_INVOICE_NUMBER_FAILED";
export const GET_INVOICE_NUMBER_RESET = "GET_INVOICE_NUMBER_RESET";

export const GET_OFFLINE_LIST = "GET_OFFLINE_LIST";
export const GET_OFFLINE_LIST_SUCCESS = "GET_OFFLINE_LIST_SUCCESS";
export const GET_OFFLINE_LIST_FAILED = "GET_OFFLINE_LIST_FAILED";
export const GET_OFFLINE_LIST_RESET = "GET_OFFLINE_LIST_RESET";

export const GET_REJECT_LIST = "GET_REJECT_LIST";
export const GET_REJECT_LIST_SUCCESS = "GET_REJECT_LIST_SUCCESS";
export const GET_REJECT_LIST_FAILED = "GET_REJECT_LIST_FAILED";
export const GET_REJECT_LIST_RESET = "GET_REJECT_LIST_RESET";

export const GET_ONETIME_DONORS_LIST = "GET_ONETIME_DONORS_LIST";
export const GET_ONETIME_DONORS_LIST_SUCCESS =
  "GET_ONETIME_DONORS_LIST_SUCCESS";
export const GET_ONETIME_DONORS_LIST_FAILED = "GET_ONETIME_DONORS_LIST_FAILED";
export const GET_ONETIME_DONORS_LIST_RESET = "GET_ONETIME_DONORS_LIST_RESET";
