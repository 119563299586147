import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Grid from "../../Components/Grid";
import { formatINR } from "../../helpers/utility";
import { find } from "lodash";
import { getProducts } from "../../store/actions";
import { SOFTWARE_STARTED_YEAR } from "../../helpers/constants";
import AddEdit from "./AddEdit";
import LoadingBar from "../../Components/Common/LoadingBar";
import moment from "moment";
import { META_TITLE } from "../../helpers/constants";

const Products = () => {
  document.title = `Products || ${META_TITLE}`;
  const dispatch = useDispatch();
  const [currentYear] = useState(new Date().getFullYear());
  const [productsYear, setProductsYear] = useState(currentYear);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [defaultCurrentYear] = useState(currentYear);
  const [mode, setMode] = useState("NEW"); //NEW EDIT COPY
  const [dataToSend, setDataToSend] = useState({}); //NEW EDIT COPY
  const {
    productsList = [],
    isProductsSuccess,
    isProductsLoading,
  } = useSelector((state) => ({
    productsList: state.Products.allProducts.data,
    isProductsSuccess: state.Products.allProducts.success,
    isProductsHasError: state.Products.allProducts.error,
    isProductsLoading: state.Products.allProducts.loading,
  }));
  const gridColumns = [
    {
      data: "id",
      title: "Product ID",
    },
    {
      data: "name",
      title: "Name (English)",
    },
    {
      data: "name_ta",
      title: "Name (Tamil)",
    },
    {
      data: "metric",
      title: "Metric",
    },
    {
      data: "rate",
      title: "Rate",
      formatter: (cell) => `${formatINR(cell)}`,
    },
    {
      title: "Actions",
      data: "id",
      width: "120px",
    },
  ];
  const columnDefs = [
    {
      targets: 4,
      createdCell: (td, cellData) =>
        ReactDOM.createRoot(td).render(`${formatINR(cellData)}`),
    },
    {
      targets: 5,
      createdCell: (td, cellData, rowData) => {
        const { totalReceipts = 0 } = find(
          productsList,
          (datum) => datum.id == rowData.id,
        );
        ReactDOM.createRoot(td).render(
          <>
            <ButtonGroup>
              <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-info">
                  Action{" "}
                  <i className="ri-arrow-drop-down-line fs-22 float-end"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => openDialogAddEdit(rowData, "COPY")}
                  >
                    Copy
                  </DropdownItem>
                  {(totalReceipts == null || totalReceipts == 0) &&
                    moment().year() === productsYear && (
                      <>
                        <DropdownItem
                          onClick={() => openDialogAddEdit(rowData, "EDIT")}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem className="bg-danger text-white">
                          Delete
                        </DropdownItem>
                      </>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </>,
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(getProducts(productsYear));
  }, [productsYear]);
  const yearOptions = () => {
    var arr = [];
    for (let year = SOFTWARE_STARTED_YEAR; year <= defaultCurrentYear; year++) {
      arr.push(
        <option key={year} value={year}>
          {year}
        </option>,
      );
    }
    return arr;
  };
  const selectYearOnlick = (e) => {
    setProductsYear(e.target.value);
  };
  const openDialogAddEdit = (row, modeOnClick) => {
    setOpenAddModal(true);
    setMode(modeOnClick);
    row && setDataToSend(row);
  };
  const onCloseClickOfModal = () => {
    setOpenAddModal(false);
  };

  useEffect(() => {
    return () => setDataToSend({});
  }, []);

  const buttons = (
    <>
      <Button
        color="primary"
        onClick={() => {
          dispatch(getProducts(productsYear));
        }}
      >
        <i className="ri-refresh-line me-3 align-middle fw-bold"></i>
        Refresh
      </Button>
      <Button color="primary" className="ms-3" onClick={openDialogAddEdit}>
        <i className="ri-add-line me-1 align-middle fw-bold"></i>
        Add New Product
      </Button>
      <label htmlFor="selectInvoiceYear" className="normal-label ms-3">
        Select Year
      </label>
      <select
        id="selectInvoiceYear"
        className="form-select ms-3"
        style={{ width: "auto", display: "inline-block" }}
        defaultValue={productsYear}
        onChange={(e) => selectYearOnlick(e)}
      >
        {yearOptions()}
      </select>
    </>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Products Listing" pageTitle="Products" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-end">
                    <Col md={12} className="full-width-table">
                      {isProductsSuccess && (
                        <Grid
                          columns={gridColumns}
                          data={productsList}
                          columnDefs={columnDefs}
                          buttons={buttons}
                        />
                      )}
                      {isProductsLoading && <LoadingBar />}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {openAddModal && (
        <AddEdit
          sortOrder={[0, "desc"]}
          onCloseClick={onCloseClickOfModal}
          openModal={openAddModal}
          productsYear={productsYear}
          mode={mode}
          dataForCopyEdit={dataToSend}
        />
      )}
    </React.Fragment>
  );
};

export default Products;
