import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Form, FieldArray, Formik, Field } from "formik";
import * as Yup from "yup";
import { find, has, sumBy, filter, map, size } from "lodash";
import GoogleTranslatorInputField from "../../Components/Common/GoogleTranslatorInputField";
import Cleave from "cleave.js/react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getPreferences,
  generateReceipt,
  generateReceiptReset,
} from "../../store/actions";
import { useProfile } from "../../Components/Hooks/UserHooks";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import SingleReceipt from "../../Prints/SingleReceipt";
import NotificationModal from "./NotificationModal";
import { formatINR, isAdminUser } from "../../helpers/utility";

const GenerateReceipts = () => {
  const [typesList] = useState([
    { label: "Select Type", value: "" },
    { label: "Rupees", value: "Rupees" },
    ...(isAdminUser() ? [{ label: "Products", value: "Products" }] : []),
  ]);
  const [SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR, setSandhaProductIdForCurrentYear] =
    useState("");
  const [netTotal, setNetTotal] = useState(0);
  const [formikDataOutSide, setFormikDataOutSide] = useState([]);
  const [multipleProductsList, setMultipleProductsList] = useState([]);
  const [dataToPrint, setDataToPrint] = useState({});
  const [print, setPrint] = useState(false);
  const [totalRowsAllowed] = useState(4);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [filledProductsDataForBilling, setFilledProductsDataForBilling] =
    useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const SingleReceiptRef = useRef();
  const history = useHistory();
  const { userProfile } = useProfile();
  const {
    donorsDetails: {
      donorName_ta,
      donorId,
      donorPrintNameInBook_ta,
      donorPhone,
    },
    isSandhaOptionRequired,
    donorType,
  } = { ...location?.state };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [phoneNumber, setPhoneNumber] = useState(
    donorPhone == null ? "" : donorPhone,
  );
  const { productsList = [], isProductsSuccess } = useSelector((state) => ({
    productsList: state.Products.allProducts.data,
    isProductsSuccess: state.Products.allProducts.success,
    isProductsHasError: state.Products.allProducts.error,
  }));
  const {
    createReceiptsData,
    isReceiptCreationSuccess,
    isReceiptCreationHasError,
    isReceiptCreationloading,
  } = useSelector((state) => ({
    createReceiptsData: state.Donors.createReceipts.data,
    isReceiptCreationSuccess: state.Donors.createReceipts.success,
    isReceiptCreationloading: state.Donors.createReceipts.loading,
    isReceiptCreationHasError: state.Donors.createReceipts.error,
  }));
  const { preferences } = useSelector((state) => ({
    preferences: state?.Preferences?.pref?.data,
  }));
  const initialValues = {
    invoicesDetails: {
      phone: phoneNumber == null ? "" : phoneNumber,
      invoices: [
        { type: "", product: "", remarks: "", quantity: "", amount: "" },
      ],
    },
  };
  const onSubmit = (data) => {
    const additionalInfo = { addedBy: userProfile?.user.id, donorId: donorId };
    const invoiceInfo = { ...data, ...additionalInfo };
    setFilledProductsDataForBilling(invoiceInfo);
    const totalRows = size(data?.invoice);
    const allowSubmission = totalRows === 1 && netTotal == "0.00";
    (data?.invoice || allowSubmission) && setOpenConfirmDialog(true);
  };
  const invoiceValidationSchema = Yup.object().shape({
    phone: Yup.string()
      //.nullable(!preferences?.enablePhoneNumber)
      .test(
        "phone-required",
        "Please enter donor phone number",
        (value = "") => {
          return preferences?.enablePhoneNumber ? value != "" : true;
        },
      )
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is not valid. Enter 10 digit valid number"),
    invoice: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Please enter donor type"),
        product: Yup.string().required("Please enter donor product"),
        quantity: Yup.string().required("Please enter donor quantity").min(1),
        amount: Yup.number()
          .min(
            preferences?.INVOICE_MIN_VALUE,
            `Amount should greater than ${preferences?.INVOICE_MIN_VALUE}`,
          )
          .required("Please enter amount"),
      }),
    ),
  });
  const getProductInfo = (productId) => {
    return find(productsList, (product) => product.id == productId);
  };
  const checkIfAllObjValuesAreExists = (obj) => {
    return !Object.values(obj).some((v) => v);
  };
  const hasValueInObj = (array, index, value) => {
    return (
      has(array, "invoice") &&
      typeof array?.invoice[index] !== "undefined" &&
      array?.invoice[index][value]
    );
  };
  const prepareProductsSet = (products = [], index) => {
    const productsIds = map(products, (product) => parseInt(product?.product));
    const out = productsList.filter((item) => !productsIds.includes(item?.id));
    setMultipleProductsList(prepareMultipleProducts(index, out));
  };

  const prepareMultipleProducts = (
    startFrom = 0,
    productsSet = [],
    sandhaProductID = SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR,
  ) => {
    let arr = [];
    const productsListFilterSandha = !isSandhaOptionRequired
      ? productsList.filter((item) => {
          return item?.id != parseInt(sandhaProductID);
        })
      : productsList;
    let list =
      productsSet.length === 0 ? productsListFilterSandha : productsSet;
    list = !isSandhaOptionRequired
      ? list.filter((item) => {
          return item?.id != parseInt(sandhaProductID);
        })
      : list;
    for (let i = 0; i < totalRowsAllowed; i++) {
      let obj = {};
      obj[`products`] = i >= startFrom ? list : productsListFilterSandha;
      arr.push(obj);
    }
    return arr;
  };
  useEffect(() => {
    const getRupeesProducts = filter(
      formikDataOutSide,
      (o) => o?.type === "Rupees",
    );
    const total = parseFloat(sumBy(getRupeesProducts, (o) => o?.amount));
    setNetTotal(isNaN(total) ? 0 : total);
  }, [formikDataOutSide]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSandhaProductIdForCurrentYear(preferences?.SANDHA_ID?.[currentYear]);
    setMultipleProductsList(
      prepareMultipleProducts(0, [], preferences?.SANDHA_ID?.[currentYear]),
    );
  }, [isProductsSuccess, preferences]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    dispatch(getProducts(currentYear));
    dispatch(getPreferences("preferences"));
  }, []);
  useEffect(() => {
    if (isReceiptCreationSuccess) {
      const invoiceNumber = Object.keys(createReceiptsData?.data)[0];
      const products = createReceiptsData?.data[invoiceNumber];
      const invoiceDate = products[0].invoiceDate;
      const dataToPrint = {
        donorid: donorId,
        invoiceNumber,
        billingAddress: `${donorName_ta}, ${donorPhone}`,
        products,
        totalAmount: parseInt(netTotal),
        invoiceDate,
        donorName_ta: donorName_ta,
      };
      setDataToPrint(dataToPrint);
    }
  }, [isReceiptCreationSuccess]);
  const handlePrint = useReactToPrint({
    content: () => SingleReceiptRef.current,
    onAfterPrint: () => {
      dispatch(generateReceiptReset());
      history.push("/donors-list", {
        donorType: donorType,
      });
    },
  });

  const onSubmitClick = () => {
    dispatch(generateReceipt(filledProductsDataForBilling));
  };
  const onCloseClick = () => {
    setOpenConfirmDialog(false);
  };

  const onCloseClickWithPageRefresh = () => {
    history.push("/donors-list");
  };
  const onClickOfPrintButton = () => {
    setPrint(true);
    setTimeout(() => {
      handlePrint();
    }, 0);
  };
  useEffect(() => {
    return () => dispatch(generateReceiptReset());
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Generate Invoice" pageTitle="Donors" />
          {openConfirmDialog && (
            <NotificationModal
              show={openConfirmDialog}
              showPrintButton={isReceiptCreationSuccess}
              onSubmitClick={() => onSubmitClick()}
              onCloseClick={() => onCloseClick()}
              onClickOfPrintButton={() => onClickOfPrintButton()}
              onCloseClickWithPageRefresh={onCloseClickWithPageRefresh}
              receiptData={dataToPrint}
              disableSubmitButton={isReceiptCreationloading}
              netTotal={netTotal}
            />
          )}
          <Row>
            <Col lg={12}>
              <Card>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={invoiceValidationSchema}
                >
                  {(formik) => {
                    const { values, errors, setFieldValue } = formik;
                    setFormikDataOutSide(values?.invoice);
                    return (
                      <CardBody className="position-relative">
                        <Form className="formContainer">
                          <Col lg={12}>
                            <div className="table-responsive">
                              <Row className="p-3 mb-3 bg-soft-warning d-flex align-items-center">
                                <Col lg={3}>
                                  <Row className="mb-3">
                                    <label
                                      htmlFor="invoice-phone"
                                      className="col-sm-5 col-form-label fw-bold"
                                    >
                                      Mobile Number{" "}
                                      {preferences?.enablePhoneNumber && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </label>
                                    <div className="col-sm-7">
                                      <Cleave
                                        placeholder="xxxx xxx xxx"
                                        className="form-control"
                                        value={phoneNumber}
                                        name="phone"
                                        onInit={() =>
                                          setFieldValue("phone", phoneNumber)
                                        }
                                        onChange={(e) => {
                                          setPhoneNumber(e.target.rawValue);
                                          setFieldValue(
                                            "phone",
                                            e.target.rawValue,
                                          );
                                        }}
                                        id="invoice-phone"
                                        options={{
                                          phone: true,
                                          phoneRegionCode: "IN",
                                        }}
                                      />
                                      {phoneNumber == "" &&
                                        preferences?.enablePhoneNumber && (
                                          <>
                                            <div
                                              className="text-decoration-underline fst-italic text-muted"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setPhoneNumber(
                                                  preferences?.defaultPhoneNumber,
                                                );
                                                setFieldValue(
                                                  "phone",
                                                  preferences?.defaultPhoneNumber,
                                                );
                                              }}
                                            >
                                              <small>
                                                Default phone number{" "}
                                                {`${preferences?.defaultPhoneNumber}`}
                                              </small>
                                            </div>
                                          </>
                                        )}
                                    </div>
                                    <div className="text-danger text-end">
                                      {errors?.phone ? `${errors?.phone}` : ""}
                                    </div>
                                  </Row>
                                </Col>
                                <Col lg={4}>
                                  <Row className="mb-3">
                                    <label className="col-sm-2 col-form-label fw-bold">
                                      Donor{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-7">
                                      <strong>Donor billing name:</strong>{" "}
                                      {`${donorName_ta},`}
                                      <br />
                                      <strong>Donor ID:</strong> {`${donorId}`}
                                    </div>
                                  </Row>
                                </Col>
                                <Col>
                                  <strong>
                                    Donor details printed in book:
                                  </strong>{" "}
                                  {`${donorPrintNameInBook_ta},`}
                                </Col>
                              </Row>
                              <Row className="g-0">
                                <Col>
                                  <div className="table-responsive">
                                    <Table className="align-middle table-nowrap mb-0 invoice-table">
                                      <thead className="table-light">
                                        <tr>
                                          <th>S. No.</th>
                                          <th>Payment Type</th>
                                          <th>Product</th>
                                          <th>Remarks</th>
                                          <th>Quantity</th>
                                          <th>Amount</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <FieldArray
                                          type="invoicesDetails.invoices"
                                          name="invoicesDetails.invoices"
                                          id="invoicesDetails.invoices"
                                          value={
                                            values.invoicesDetails.invoices
                                          }
                                          render={(arrayHelpers) => (
                                            <>
                                              {values.invoicesDetails.invoices.map(
                                                (singleInvoice, index) => (
                                                  <>
                                                    <tr key={index}>
                                                      <td className="sno">
                                                        {index + 1}
                                                      </td>
                                                      <td className="type">
                                                        <Field
                                                          component="select"
                                                          className="form-select"
                                                          name={`invoice.${index}.type`}
                                                          onChange={(e) => {
                                                            const hasProductInProps =
                                                              hasValueInObj(
                                                                values,
                                                                index,
                                                                "product",
                                                              ) ==
                                                              SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR;
                                                            formik.setFieldValue(
                                                              `invoice.${index}.type`,
                                                              hasProductInProps
                                                                ? "Rupees"
                                                                : e.target
                                                                    .value,
                                                            );
                                                          }}
                                                        >
                                                          {typesList.map(
                                                            (type, index) => (
                                                              <option
                                                                value={`${type.value}`}
                                                                key={index}
                                                              >
                                                                {`${type.label}`}
                                                              </option>
                                                            ),
                                                          )}
                                                        </Field>
                                                        <div className="text-danger">
                                                          {hasValueInObj(
                                                            errors,
                                                            index,
                                                            "type",
                                                          )
                                                            ? `${errors?.invoice[index].type}`
                                                            : ""}
                                                        </div>
                                                      </td>
                                                      <td className="product">
                                                        <Field
                                                          component="select"
                                                          className="form-select"
                                                          name={`invoice.${index}.product`}
                                                          onChange={(e) => {
                                                            const productInfo =
                                                              getProductInfo(
                                                                e.target.value,
                                                              );
                                                            formik.setFieldValue(
                                                              `invoice.${index}.amount`,
                                                              e.target.value !=
                                                                ""
                                                                ? productInfo?.rate
                                                                : "",
                                                            );
                                                            formik.setFieldValue(
                                                              `invoice.${index}.product`,
                                                              e.target.value,
                                                            );
                                                            formik.setFieldValue(
                                                              `invoice.${index}.quantity`,
                                                              1,
                                                            );
                                                            e.target.value ==
                                                              SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR &&
                                                              formik.setFieldValue(
                                                                `invoice.${index}.type`,
                                                                "Rupees",
                                                              );
                                                            prepareProductsSet(
                                                              values.invoice,
                                                              index + 1,
                                                            );
                                                          }}
                                                        >
                                                          <option value="">
                                                            Select product
                                                          </option>
                                                          {multipleProductsList[
                                                            index
                                                          ]?.products.map(
                                                            (
                                                              product,
                                                              index,
                                                            ) => (
                                                              <option
                                                                value={`${product.id}`}
                                                                key={index}
                                                              >
                                                                {`${product.name}(${product.name_ta})`}
                                                              </option>
                                                            ),
                                                          )}
                                                        </Field>
                                                        <div className="text-danger">
                                                          {hasValueInObj(
                                                            errors,
                                                            index,
                                                            "product",
                                                          )
                                                            ? `${errors?.invoice[index].product}`
                                                            : ""}
                                                        </div>
                                                      </td>
                                                      <td className="remarks">
                                                        <GoogleTranslatorInputField
                                                          name={`invoice.${index}.remarks`}
                                                          placeholder="Remarks (any)"
                                                          type="text"
                                                          id={`invoice.${index}.remarks`}
                                                          className="form-control"
                                                        />
                                                        <div className="text-danger">
                                                          {hasValueInObj(
                                                            errors,
                                                            index,
                                                            "remarks",
                                                          )
                                                            ? `${errors?.invoice[index].remarks}`
                                                            : ""}
                                                        </div>
                                                      </td>
                                                      <td className="quantity">
                                                        <Field
                                                          name={`invoice[${index}].quantity`}
                                                          placeholder="Enter quantity"
                                                          type="number"
                                                          className="form-control"
                                                          autoComplete="off"
                                                          readOnly={
                                                            hasValueInObj(
                                                              values,
                                                              index,
                                                              "product",
                                                            ) ==
                                                            SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR
                                                          }
                                                          onChange={(e) => {
                                                            const productInfo =
                                                              getProductInfo(
                                                                values.invoice[
                                                                  index
                                                                ].product,
                                                              );
                                                            formik.setFieldValue(
                                                              `invoice.${index}.quantity`,
                                                              e.target.value,
                                                            );
                                                            const totalAmount =
                                                              e.target.value *
                                                              productInfo?.rate;
                                                            formik.setFieldValue(
                                                              `invoice.${index}.amount`,
                                                              !isNaN(
                                                                totalAmount,
                                                              )
                                                                ? totalAmount
                                                                : "",
                                                            );
                                                          }}
                                                        />
                                                        <div className="text-danger">
                                                          {hasValueInObj(
                                                            errors,
                                                            index,
                                                            "quantity",
                                                          )
                                                            ? `${errors?.invoice[index].quantity}`
                                                            : ""}
                                                        </div>
                                                      </td>
                                                      <td
                                                        className={
                                                          hasValueInObj(
                                                            values,
                                                            index,
                                                            "type",
                                                          ) == "Rupees"
                                                            ? "bg-soft-success amount"
                                                            : "bg-soft-danger amount"
                                                        }
                                                      >
                                                        <div className="input-group">
                                                          <span className="input-group-text">
                                                            &#8377;
                                                          </span>
                                                          <Field
                                                            name={`invoice[${index}].amount`}
                                                            placeholder="Enter amount"
                                                            type="number"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            readOnly={
                                                              hasValueInObj(
                                                                values,
                                                                index,
                                                                "product",
                                                              ) ==
                                                              SANDHA_PRODUCT_ID_FOR_CURRENT_YEAR
                                                            }
                                                          />
                                                        </div>
                                                        <div className="text-danger">
                                                          {hasValueInObj(
                                                            errors,
                                                            index,
                                                            "amount",
                                                          )
                                                            ? `${errors?.invoice[index].amount}`
                                                            : ""}
                                                        </div>
                                                      </td>
                                                      <td className="actions">
                                                        {values.invoicesDetails
                                                          .invoices.length -
                                                          1 ===
                                                          index &&
                                                          has(
                                                            values,
                                                            "invoice",
                                                          ) &&
                                                          typeof values
                                                            ?.invoice[index] !==
                                                            "undefined" &&
                                                          !checkIfAllObjValuesAreExists(
                                                            values?.invoice[
                                                              index
                                                            ],
                                                          ) && (
                                                            <Button
                                                              color="danger"
                                                              className="me-3"
                                                              onClick={() => {
                                                                prepareProductsSet(
                                                                  values.invoice,
                                                                  index,
                                                                );
                                                                arrayHelpers.remove(
                                                                  index,
                                                                );
                                                                const removedIndex =
                                                                  values?.invoice.splice(
                                                                    index,
                                                                    1,
                                                                  );
                                                                setFormikDataOutSide(
                                                                  removedIndex,
                                                                );
                                                              }}
                                                            >
                                                              {" "}
                                                              -{" "}
                                                              <span className="d-xs-none">
                                                                Remove
                                                              </span>{" "}
                                                            </Button>
                                                          )}
                                                        {values.invoicesDetails
                                                          .invoices.length -
                                                          1 ===
                                                          index &&
                                                          has(
                                                            values,
                                                            "invoice",
                                                          ) &&
                                                          typeof values
                                                            ?.invoice[index] !==
                                                            "undefined" &&
                                                          !checkIfAllObjValuesAreExists(
                                                            values?.invoice[
                                                              index
                                                            ],
                                                          ) &&
                                                          values.invoicesDetails
                                                            .invoices.length <
                                                            totalRowsAllowed && (
                                                            <Button
                                                              color="primary"
                                                              onClick={() => {
                                                                prepareProductsSet(
                                                                  values.invoice,
                                                                  index + 1,
                                                                );
                                                                arrayHelpers.push(
                                                                  initialValues
                                                                    .invoicesDetails
                                                                    .invoices[0],
                                                                );
                                                              }}
                                                            >
                                                              {" "}
                                                              +{" "}
                                                              <span className="d-xs-none">
                                                                Add
                                                              </span>{" "}
                                                            </Button>
                                                          )}
                                                      </td>
                                                    </tr>
                                                  </>
                                                ),
                                              )}
                                            </>
                                          )}
                                        />
                                      </tbody>
                                      <tfoot className="table-light">
                                        <tr>
                                          <td colSpan={6} className="text-end">
                                            <h4 className="mb-0 text-primary">
                                              Net Total:{" "}
                                              <span className="blink_me">
                                                {formatINR(netTotal)}
                                              </span>
                                            </h4>
                                          </td>
                                          <td className="text-end">&nbsp;</td>
                                        </tr>
                                      </tfoot>
                                    </Table>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            lg={12}
                            className="mt-3 mx-0 d-flex justify-content-end"
                          >
                            <Button
                              color="primary"
                              className="me-3"
                              data-text="Collect Cash"
                              size="md"
                            >
                              {" "}
                              <span>
                                <i className="ri-secure-payment-line"></i>{" "}
                                Collect Cash
                              </span>{" "}
                            </Button>
                            <Button
                              color="danger"
                              type="button"
                              size="md"
                              onClick={() => {
                                history.push("/donors-list", {
                                  donorType: donorType,
                                });
                              }}
                            >
                              {" "}
                              <span>
                                <i className="ri-close-line"></i> Cancel
                              </span>{" "}
                            </Button>
                          </Col>
                        </Form>
                      </CardBody>
                    );
                  }}
                </Formik>
              </Card>
            </Col>
          </Row>
          {isReceiptCreationSuccess && (
            <ToastifyMessage message={`${createReceiptsData?.message}`} />
          )}
          {!isReceiptCreationSuccess && isReceiptCreationHasError && (
            <ToastifyMessage
              status=""
              message={`Error: ${createReceiptsData?.message}.`}
            />
          )}
        </Container>
      </div>
      {print && (
        <div className="d-none">
          <SingleReceipt ref={SingleReceiptRef} dataToPrint={dataToPrint} />
        </div>
      )}
    </React.Fragment>
  );
};

export default GenerateReceipts;
