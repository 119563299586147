import React from "react";

const SimpleBar = ({ children, style, className }) => {
  return (
    <div style={{ overflowY: "scroll", ...style }} className={className}>
      {children}
    </div>
  );
};
export default SimpleBar;
