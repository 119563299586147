import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../../helpers/api_helper";
import { GET_USERS_DATA, CREATE_NEW_USER, DO_ACTION } from "./actionTypes";
import {
  getUsersSuccess,
  getUsersFailed,
  createUserSuccess,
  createUserFailed,
  doUserActionSuccess,
  doUserActionFailed,
} from "./actions";

const api = new APIClient();

function* getUsersSaga() {
  try {
    const getNotes = () => api.get(`${"/auth/users"}`);
    const response = yield call(getNotes);
    const { data, status } = response;
    if (status === 200) {
      yield put(getUsersSuccess(data));
    } else {
      yield put(getUsersFailed(response));
    }
  } catch (error) {
    yield put(getUsersFailed(error));
  }
}

function* createNewuserSaga(action) {
  const { payload } = action;
  try {
    const getNotes = (data) => api.create(`${"/auth/register"}`, data);
    const response = yield call(getNotes, payload);
    const { data, status } = response;
    if (status === 201) {
      yield put(createUserSuccess(data));
    } else {
      yield put(createUserFailed(response));
    }
  } catch (error) {
    yield put(createUserFailed(error));
  }
}

function* userActionSaga(action) {
  const { payload, userId } = action;
  try {
    const updateUser = (data) => api.put(`${"/users/actions"}/${userId}`, data);
    const response = yield call(updateUser, payload);
    const { data, status } = response;
    if (status === 201) {
      yield put(doUserActionSuccess(data));
    } else {
      yield put(doUserActionFailed(response));
    }
  } catch (error) {
    yield put(doUserActionFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(GET_USERS_DATA, getUsersSaga);
  yield takeEvery(CREATE_NEW_USER, createNewuserSaga);
  yield takeEvery(DO_ACTION, userActionSaga);
}

function* usersSagaOutput() {
  yield all([fork(watchUserRegister)]);
}

export default usersSagaOutput;
