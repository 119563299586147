import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { useSelector, useDispatch } from "react-redux";
import Grid from "../../Components/Grid";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  ButtonGroup,
  Badge,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import moment from "moment";
import SingleReceipt from "../../Prints/SingleReceipt";
import { useReactToPrint } from "react-to-print";
import {
  getInvoiceList,
  cancelInvoice,
  cancelInvoiceReset,
  getSingleInvoice,
  getPreferences,
} from "../../store/actions";
import { SOFTWARE_STARTED_YEAR } from "../../helpers/constants";
import ToastifyMessage from "../../Components/Common/ToastifyMessage";
import ConfirmModal from "../../Components/Common/ConfirmModal";
import { formatINR, isAdminUser, isDonateAllowed } from "../../helpers/utility";
import LoadingBar from "../../Components/Common/LoadingBar";
import { isEmpty } from "lodash";
import { META_TITLE } from "../../helpers/constants";

const InvoiceList = () => {
  document.title = `Receipts || ${META_TITLE}`;
  const InvoicePrintRef = useRef();
  const dispatch = useDispatch();
  const [print, setPrint] = useState(false);
  const [dataToPrint, setDataToPrint] = useState({});
  const [openCancelInvoice, setOpenCancelInvoice] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [defaultCurrentYear] = useState(new Date().getFullYear());
  const [isAdminUserVar] = useState(isAdminUser());
  //const [searchGridValue, setSearchGridValue] = useState("");
  const {
    invoiceData = [],
    isInvoiceSucess,
    isInvoiceLoading,
  } = useSelector((state) => ({
    invoiceData: state.Donors.invoiceList.data,
    isInvoiceSucess: state.Donors.invoiceList.success,
    isInvoiceLoading: state.Donors.invoiceList.loading,
  }));
  const {
    cancelInvoiceData,
    isCancelInvoiceSuccess,
    isCancelInvoiceLoading,
    isCancelInvoiceHasError,
    invoiceSingleData,
    isInvoiceSingleSuccess,
    preferences,
    isPreferencesSuccess,
  } = useSelector((state) => ({
    cancelInvoiceData: state.Donors.cancelInvoice.data,
    isCancelInvoiceSuccess: state.Donors.cancelInvoice.success,
    isCancelInvoiceLoading: state.Donors.cancelInvoice.loading,
    isCancelInvoiceHasError: state.Donors.cancelInvoice.error,
    invoiceSingleData: state.Donors.invoiceSingle.data,
    isInvoiceSingleSuccess: state.Donors.invoiceSingle.success,
    isInvoiceSingleError: state.Donors.invoiceSingle.error,
    preferences: state.Preferences.pref?.data,
    isPreferencesSuccess: state.Preferences.pref?.success,
  }));
  const handlePrint = useReactToPrint({
    content: () => InvoicePrintRef.current,
  });
  const printReceipt = (row) => {
    const invoiceNo = row?.invoiceNumber;
    dispatch(getSingleInvoice(invoiceNo));
  };
  useEffect(() => {
    dispatch(getInvoiceList(currentYear));
  }, [currentYear, isCancelInvoiceSuccess]);

  useEffect(() => {
    if (isInvoiceSingleSuccess) {
      setDataToPrint(invoiceSingleData);
      setPrint(true);
      setTimeout(() => {
        handlePrint();
      }, 0);
    }
  }, [isInvoiceSingleSuccess]);

  const yearOptions = () => {
    var arr = [];
    for (let year = SOFTWARE_STARTED_YEAR; year <= defaultCurrentYear; year++) {
      arr.push(
        <option key={year} value={year}>
          {year}
        </option>,
      );
    }
    return arr;
  };
  const selectYearOnlick = (e) => {
    setCurrentYear(e.target.value);
  };
  const cancelInvoiceOnClick = (invoiceNumber) => {
    dispatch(cancelInvoice({ invoiceNumber }));
    dispatch(getInvoiceList(currentYear));
  };
  const onOkClick = () => {
    cancelInvoiceOnClick(invoiceToDelete);
  };
  const onCloseClick = () => {
    setOpenCancelInvoice(false);
  };
  const onClickOfCancelButton = (invoiceNo) => {
    setOpenCancelInvoice(true);
    setInvoiceToDelete(invoiceNo);
  };

  useEffect(() => {
    setInvoiceToDelete("");
    setOpenCancelInvoice(false);
  }, [isCancelInvoiceSuccess]);

  useEffect(() => {
    return () => dispatch(cancelInvoiceReset());
  }, []);

  useEffect(() => {
    !isPreferencesSuccess &&
      isEmpty(preferences) &&
      dispatch(getPreferences("preferences"));
  }, [isPreferencesSuccess]);

  const gridColumns = [
    {
      title: "Invoice Number",
      data: "invoiceNumber",
    },
    {
      title: "Donate By",
      data: "nameToPrint",
    },
    {
      title: "Amount",
      width: "200px",
      data: "totalPrice",
    },
    {
      title: "Invoice Date",
      data: "invoiceDate",
    },
    {
      title: "Actions",
      width: "120px",
      data: "invoiceNumber",
    },
  ];

  const columnDefs = [
    {
      targets: 0,
      createdCell: (td, cell, { isInvoiceReversed }) =>
        ReactDOM.createRoot(td).render(
          <>
            {`${cell}`}
            {isInvoiceReversed == "Y" && (
              <Badge color="danger" pill className="ms-2">
                {" "}
                CANCELLED{" "}
              </Badge>
            )}
          </>,
        ),
    },
    {
      targets: 2,
      createdCell: (td, cell, { hasProducts = 0 }) => {
        ReactDOM.createRoot(td).render(
          <>
            {formatINR(cell)}{" "}
            {hasProducts >= 1 ? `+ ${hasProducts} as Product(s)` : ""}
          </>,
        );
      },
    },

    {
      targets: 3,
      createdCell: (td, cell) =>
        ReactDOM.createRoot(td).render(<>{moment(cell).format("lll")}</>),
    },

    {
      targets: 4,
      createdCell: (td, cell, row) => {
        const { isInvoiceReversed, invoiceDate, invoiceNumber } = row;
        const receiptYear = invoiceDate.split("-")[0];
        ReactDOM.createRoot(td).render(
          <>
            {isInvoiceReversed != "Y" && (
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-info">
                    Action{" "}
                    <i className="ri-arrow-drop-down-line fs-22 float-end"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        printReceipt(row);
                      }}
                    >
                      Print Receipt
                    </DropdownItem>
                    {isAdminUserVar &&
                      receiptYear == new Date().getFullYear() &&
                      isDonateAllowed(preferences) && (
                        <>
                          <DropdownItem divider />
                          <DropdownItem
                            className="bg-danger text-white"
                            onClick={() => {
                              onClickOfCancelButton(invoiceNumber);
                            }}
                          >
                            Cancel Receipt
                          </DropdownItem>
                        </>
                      )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            )}
            {isInvoiceReversed == "Y" && (
              <Button color="info" disabled={true}>
                Actions
              </Button>
            )}
          </>,
        );
      },
    },
  ];
  const buttons = (
    <>
      <Button
        color="primary"
        onClick={() => {
          dispatch(getInvoiceList(currentYear));
        }}
      >
        <i className="ri-refresh-line me-1 align-middle fw-bold"></i>
        Refresh
      </Button>
      <label htmlFor="selectInvoiceYear" className="normal-label ms-3">
        Select Year
      </label>
      <select
        id="selectInvoiceYear"
        className="form-select mx-3"
        style={{ width: "auto", display: "inline-block" }}
        defaultValue={currentYear}
        onChange={(e) => selectYearOnlick(e)}
      >
        {yearOptions()}
      </select>
    </>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        {openCancelInvoice && (
          <ConfirmModal
            onOkClick={onOkClick}
            showSpinner={isCancelInvoiceLoading}
            show={openCancelInvoice}
            onCloseClick={onCloseClick}
            icon="ri-close-circle-fill"
            title="Are your sure?"
            content={`Are you sure you want to cancel the receipt ${invoiceToDelete}?`}
          />
        )}
        <Container fluid>
          <BreadCrumb title="Receipts" pageTitle="Donors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="InvoiceTable">
                    {isInvoiceSucess && (
                      <Grid
                        data={invoiceData}
                        columns={gridColumns}
                        isDataReady={isInvoiceSucess}
                        columnDefs={columnDefs}
                        sortOrder={[0, "desc"]}
                        buttons={buttons}
                      />
                    )}
                    {isInvoiceLoading && <LoadingBar />}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {print && (
        <div className="d-none">
          <SingleReceipt ref={InvoicePrintRef} dataToPrint={dataToPrint[0]} />
        </div>
      )}
      {isCancelInvoiceSuccess && (
        <ToastifyMessage message={`${cancelInvoiceData?.message}`} />
      )}
      {!isCancelInvoiceSuccess && isCancelInvoiceHasError && (
        <ToastifyMessage
          status=""
          message={`Error: ${cancelInvoiceData?.message}.`}
        />
      )}
    </React.Fragment>
  );
};

export default InvoiceList;
