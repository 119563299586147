import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import {
  GET_DONOR_TYPES,
  CREATE_DONOR_TYPES,
  UPDATE_DONOR_TYPES,
} from "./actionTypes";
import {
  getDonorTypesSuccess,
  donorTypesFailed,
  createDonorTypeSuccess,
  createDonorTypeFailed,
  updateDonorTypeSuccess,
  updateDonorTypeFailed,
} from "./actions";

const api = new APIClient();

function* donorsTypesApis(action) {
  const { queryParams = "" } = action;
  const url = `/donor/type?${queryParams}`;
  try {
    const getDonorTypes = () => api.get(url);
    const response = yield call(getDonorTypes);
    const { data, status } = response;
    status === 200
      ? yield put(getDonorTypesSuccess(data))
      : put(donorTypesFailed(response));
  } catch (error) {
    yield put(donorTypesFailed(error));
  }
}

function* createDonorTypes(action) {
  const { payload } = action;
  try {
    const getDonorTypes = (data) => api.create("/donor/type", data);
    const response = yield call(getDonorTypes, payload);
    const { data, status } = response;
    status === 201
      ? yield put(createDonorTypeSuccess(data))
      : yield put(createDonorTypeFailed(data));
  } catch (error) {
    yield put(createDonorTypeFailed(error));
  }
}

function* updateDonorTypes(action) {
  const { payload, donortypeid } = action;
  try {
    const getDonorTypes = (data) => api.put(`/donor/type/${donortypeid}`, data);
    const response = yield call(getDonorTypes, payload);
    const { data, status } = response;
    status === 200
      ? yield put(updateDonorTypeSuccess(data))
      : yield put(updateDonorTypeFailed(response));
  } catch (error) {
    yield put(updateDonorTypeFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(GET_DONOR_TYPES, donorsTypesApis);
  yield takeEvery(CREATE_DONOR_TYPES, createDonorTypes);
  yield takeEvery(UPDATE_DONOR_TYPES, updateDonorTypes);
}

function* donorTypesSaga() {
  yield all([fork(watchUserRegister)]);
}

export default donorTypesSaga;
