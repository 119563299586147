import {
  GENERATE_REPORTS,
  GENERATE_REPORTS_SUCCESS,
  GENERATE_REPORTS_FAILED,
  DASHBOARD_REPORTS,
  DASHBOARD_REPORTS_SUCCESS,
  DASHBOARD_REPORTS_FAILED,
  GENERATE_PRINTS,
  GENERATE_PRINTS_SUCCESS,
  GENERATE_PRINTS_FAILED,
  GENERATE_PRINTS_RESET,
} from "./actionTypes";

export const generateReports = (payload) => {
  return {
    type: GENERATE_REPORTS,
    payload,
  };
};

export const generateReportSuccess = (data) => {
  return {
    type: GENERATE_REPORTS_SUCCESS,
    data,
  };
};

export const generateReportFailed = (error) => {
  return {
    type: GENERATE_REPORTS_FAILED,
    error,
  };
};

export const dashboardReport = (year) => {
  return {
    type: DASHBOARD_REPORTS,
    year,
  };
};

export const dashboardReportSuccess = (data) => {
  return {
    type: DASHBOARD_REPORTS_SUCCESS,
    data,
  };
};

export const dashboardReportFailed = (error) => {
  return {
    type: DASHBOARD_REPORTS_FAILED,
    error,
  };
};

export const generatePrints = (params) => {
  return {
    type: GENERATE_PRINTS,
    params,
  };
};

export const generatePrintsSuccess = (data) => {
  return {
    type: GENERATE_PRINTS_SUCCESS,
    data,
  };
};

export const generatePrintsFailed = (error) => {
  return {
    type: GENERATE_PRINTS_FAILED,
    error,
  };
};

export const generatePrintsReset = () => {
  return {
    type: GENERATE_PRINTS_RESET,
  };
};
