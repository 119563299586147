import React from "react";
import { Redirect } from "react-router-dom";

import GridTable from "../pages/Donors/GridTable";
import DonorTypes from "../pages/DonorTypes";
import CreateDonor from "../pages/Donors/CreateDonor";
import Prints from "../Prints";
import PrepareDonorBook from "../pages/PrepareDonorBook";
import GenerateReceipts from "../pages/Donors/GenerateReceipts";
import InvoiceList from "../pages/Donors/InvoiceList";
import Reports from "../pages/Reports";
import Dashboard from "../pages/Dashboard";
import InSights from "../pages/Dashboard/InSights";
import Products from "../pages/Products";
import Preferences from "../pages/Preferences";
import InvoiceListRaw from "../pages/Donors/InvoiceListRaw";
import Notes from "../pages/Notes";
import AddNotes from "../pages/Notes/Add";
import PrintsToDownload from "../pages/Reports/PrintsToDownload";
import Users from "../pages/Users";
import Locality from "../pages/Locality";
import FileManager from "../pages/FileManager";
import RejectListing from "../pages/Donors/RejectListing";
import OneTimeDonors from "../pages/Donors/OneTimeDonors";

import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Available Roles: 0 => Donors, 1 => Super Admin, 2 => Print
const authProtectedRoutes = [
  { path: "/donors-list", component: GridTable, role: [0, 1] },
  { path: "/add-donor", component: CreateDonor, role: [0, 1] },
  { path: "/edit-donor", component: CreateDonor, role: [0, 1] },
  { path: "/donors-types", component: DonorTypes, role: [0, 1] },
  { path: "/prepare-donor-book", component: PrepareDonorBook, role: [1] },
  { path: "/generate-invoice", component: GenerateReceipts, role: [0, 1] },
  { path: "/invoice-list", component: InvoiceList, role: [0, 1] },
  { path: "/generate-report", component: Reports, role: [1] },
  { path: "/dashboard", component: Dashboard, role: [0, 1, 2] },
  { path: "/insights", component: InSights, role: [1] },
  { path: "/products", component: Products, role: [1] },
  { path: "/preferences", component: Preferences, role: [1] },
  { path: "/invoice-raw", component: InvoiceListRaw, role: [1] },
  { path: "/note-book", component: Notes, role: [1] },
  { path: "/add-note", component: AddNotes, role: [1] },
  { path: "/print-outs", component: PrintsToDownload, role: [0, 1, 2] },
  { path: "/users", component: Users, role: [1] },
  { path: "/reject-list", component: RejectListing, role: [1] },
  { path: "/locality-list", component: Locality, role: [0, 1, 2] },
  { path: "/downloads", component: FileManager, role: [0, 1, 2] },
  { path: "/one-time-donors", component: OneTimeDonors, role: [0, 1, 2] },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  { path: "*", exact: true, component: Basic404 },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
];

const printRoutes = [{ path: "/prints", component: Prints }];

export { authProtectedRoutes, publicRoutes, printRoutes };
