import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const AreYouSure = ({
  show,
  onSubmitClick,
  onCloseClick,
  closeLabel = "Close",
  proceedLabel = "Proceed",
  dialogTitle = "",
  dialogDesc = "",
  disableSubmitButton = false,
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="animatedIcon">
            <i className="ri-mail-send-line" style={{ fontSize: "50px" }}></i>
          </div>
          <div className="pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{`${dialogTitle}`}</h4>
            <p className="text-muted mx-4 mb-0">{`${dialogDesc}`}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            {`${closeLabel}`}
          </button>
          <button
            type="button"
            className="btn w-sm btn-primary"
            onClick={onSubmitClick}
            disabled={disableSubmitButton}
          >
            {`${
              !disableSubmitButton ? proceedLabel : "Proceesing, Please wait..."
            }`}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

AreYouSure.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.bool.isRequired,
  onSubmitClick: PropTypes.func,
  closeLabel: PropTypes.string,
  proceedLabel: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogDesc: PropTypes.string,
};

export default AreYouSure;
