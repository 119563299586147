import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import { GET_PRODUCTS, CREATE_PRODUCT, UPDATE_PRODUCT } from "./actionTypes";
import {
  getProductsSuccess,
  getProductsFailed,
  createProductSuccess,
  createProductFailed,
  updateProductSuccess,
  updateProductFailed,
} from "./actions";

const api = new APIClient();

function* productsApi(action) {
  const { year } = action;
  const url = `/products/${year}`;
  try {
    const getProducts = () => api.get(url);
    const response = yield call(getProducts);
    const { data, status } = response;
    status === 200
      ? yield put(getProductsSuccess(data))
      : put(getProductsFailed(response));
  } catch (error) {
    yield put(getProductsFailed(error));
  }
}

function* createProductSaga(action) {
  const { dataToPass } = action;
  try {
    const createProdcuts = (data) => api.create(`${"/products/save"}`, data);
    const response = yield call(createProdcuts, dataToPass);
    const { data, status } = response;
    status === 201
      ? yield put(createProductSuccess(data))
      : yield put(createProductFailed(data));
  } catch (error) {
    yield put(createProductFailed(error));
  }
}

function* updateProductSaga(action) {
  const { dataToPass } = action;
  try {
    const updateProdcuts = (data) => api.put(`${"/products/save"}`, data);
    const response = yield call(updateProdcuts, dataToPass);
    const { data, status } = response;
    status === 201
      ? yield put(updateProductSuccess(data))
      : yield put(updateProductFailed(data));
  } catch (error) {
    yield put(updateProductFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(GET_PRODUCTS, productsApi);
  yield takeEvery(CREATE_PRODUCT, createProductSaga);
  yield takeEvery(UPDATE_PRODUCT, updateProductSaga);
}

function* productsSaga() {
  yield all([fork(watchUserRegister)]);
}

export default productsSaga;
