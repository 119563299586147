import {
  GET_DONORS_DATA,
  GET_DONORS_DATA_SUCCESS,
  GET_DONORS_DATA_FAILED,
  RESET_DONORS_DATA,
  GET_SINGLE_DONOR,
  GET_SINGLE_DONOR_SUCCESS,
  GET_SINGLE_DONOR_FAILED,
  RESET_SINGLE_DONOR_DATA,
  GET_DONOR_RECEIPTS,
  GET_DONOR_RECEIPTS_SUCCESS,
  GET_DONOR_RECEIPTS_FAILED,
  ADD_DONOR,
  ADD_DONOR_SUCCESS,
  ADD_DONOR_FAILED,
  UPDATE_DONOR,
  UPDATE_DONOR_SUCCESS,
  UPDATE_DONOR_FAILED,
  UPDATE_DONOR_RESET,
  BOOK_ADDRESS_DONOR,
  BOOK_ADDRESS_DONOR_SUCCESS,
  BOOK_ADDRESS_DONOR_FAILED,
  BOOK_ADDRESS_DONOR_RESET,
  UPDATE_BOOK_ADDRESS_DONOR,
  UPDATE_BOOK_ADDRESS_DONOR_SUCCESS,
  UPDATE_BOOK_ADDRESS_DONOR_FAILED,
  UPDATE_BOOK_ADDRESS_DONOR_RESET,
  GENERATE_RECEIPTS,
  GENERATE_RECEIPTS_SUCCESS,
  GENERATE_RECEIPTS_FAILED,
  GENERATE_RECEIPTS_RESET,
  INVOICE_LISTS,
  INVOICE_LISTS_SUCCESS,
  INVOICE_LISTS_FAILED,
  INVOICE_LISTS_RESET,
  CANCEL_RECEIPTS,
  CANCEL_RECEIPTS_SUCCESS,
  CANCEL_RECEIPTS_FAILED,
  CANCEL_RECEIPTS_RESET,
  DELETE_DONOR,
  DELETE_DONOR_SUCCESS,
  DELETE_DONOR_FAILED,
  DELETE_DONOR_RESET,
  GET_INVOICE_RAW,
  GET_INVOICE_RAW_SUCCESS,
  GET_INVOICE_RAW_FAILED,
  GET_INVOICE_RAW_RESET,
  GET_INVOICE_NUMBER,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICE_NUMBER_FAILED,
  GET_INVOICE_NUMBER_RESET,
  GET_OFFLINE_LIST,
  GET_OFFLINE_LIST_SUCCESS,
  GET_OFFLINE_LIST_FAILED,
  GET_OFFLINE_LIST_RESET,
  GET_REJECT_LIST,
  GET_REJECT_LIST_SUCCESS,
  GET_REJECT_LIST_FAILED,
  GET_REJECT_LIST_RESET,
  GET_ONETIME_DONORS_LIST,
  GET_ONETIME_DONORS_LIST_SUCCESS,
  GET_ONETIME_DONORS_LIST_FAILED,
  GET_ONETIME_DONORS_LIST_RESET,
} from "./actionTypes";

export const getDonorsList = (donorType, params) => {
  return {
    type: GET_DONORS_DATA,
    donorType,
    params,
  };
};

export const getDonorsListSuccess = (donors) => {
  return {
    type: GET_DONORS_DATA_SUCCESS,
    payload: donors,
  };
};

export const getDonorsListFailed = (donors) => {
  return {
    type: GET_DONORS_DATA_FAILED,
    payload: donors,
  };
};

export const resetDonorsList = () => {
  return {
    type: RESET_DONORS_DATA,
  };
};

export const getSingleDonor = (donorid, donorType) => {
  return {
    type: GET_SINGLE_DONOR,
    donorid,
    donorType,
  };
};

export const getSingleDonorSuccess = (data) => {
  return {
    type: GET_SINGLE_DONOR_SUCCESS,
    payload: data,
  };
};

export const getSingleDonorFailed = (donors) => {
  return {
    type: GET_SINGLE_DONOR_FAILED,
    payload: donors,
  };
};

export const resetSingleDonorData = () => {
  return {
    type: RESET_SINGLE_DONOR_DATA,
  };
};

export const getDonorReceipts = (donorid) => {
  return {
    type: GET_DONOR_RECEIPTS,
    donorid,
  };
};

export const getDonorReceiptsSuccess = (data) => {
  return {
    type: GET_DONOR_RECEIPTS_SUCCESS,
    payload: data,
  };
};

export const getDonorReceiptsFailed = (receipts) => {
  return {
    type: GET_DONOR_RECEIPTS_FAILED,
    payload: receipts,
  };
};

export const addNewDonor = (payload) => {
  return {
    type: ADD_DONOR,
    payload,
  };
};

export const addNewDonorSuccess = (data) => {
  return {
    type: ADD_DONOR_SUCCESS,
    data,
  };
};

export const addNewDonorFailed = (failed) => {
  return {
    type: ADD_DONOR_FAILED,
    failed,
  };
};

export const updateNewDonor = (payload, donorid) => {
  return {
    type: UPDATE_DONOR,
    payload,
    donorid,
  };
};

export const updateDonorSuccess = (data) => {
  return {
    type: UPDATE_DONOR_SUCCESS,
    data,
  };
};

export const updateDonorFailed = (failed) => {
  return {
    type: UPDATE_DONOR_FAILED,
    failed,
  };
};

export const updateDonorReset = () => {
  return {
    type: UPDATE_DONOR_RESET,
  };
};

export const getBookPrintDonor = (year) => {
  return {
    type: BOOK_ADDRESS_DONOR,
    year,
  };
};

export const getBookPrintDonorSuccess = (data) => {
  return {
    type: BOOK_ADDRESS_DONOR_SUCCESS,
    data,
  };
};

export const getBookPrintDonorFailed = (failed) => {
  return {
    type: BOOK_ADDRESS_DONOR_FAILED,
    failed,
  };
};

export const getBookPrintDonorReset = () => {
  return {
    type: BOOK_ADDRESS_DONOR_RESET,
  };
};

export const updateBookPrintDonor = (year, payload) => {
  return {
    type: UPDATE_BOOK_ADDRESS_DONOR,
    year,
    payload,
  };
};

export const updateBookPrintDonorSuccess = (data) => {
  return {
    type: UPDATE_BOOK_ADDRESS_DONOR_SUCCESS,
    data,
  };
};

export const updateBookPrintDonorFailed = (failed) => {
  return {
    type: UPDATE_BOOK_ADDRESS_DONOR_FAILED,
    failed,
  };
};

export const updateBookPrintDonorReset = () => {
  return {
    type: UPDATE_BOOK_ADDRESS_DONOR_RESET,
  };
};

export const generateReceipt = (payload) => {
  return {
    type: GENERATE_RECEIPTS,
    payload,
  };
};

export const generateReceiptSuccess = (data) => {
  return {
    type: GENERATE_RECEIPTS_SUCCESS,
    data,
  };
};

export const generateReceiptFailed = (failed) => {
  return {
    type: GENERATE_RECEIPTS_FAILED,
    failed,
  };
};

export const generateReceiptReset = () => {
  return {
    type: GENERATE_RECEIPTS_RESET,
  };
};

export const getInvoiceList = (year) => {
  return {
    type: INVOICE_LISTS,
    year,
  };
};

export const getInvoiceListSuccess = (data) => {
  return {
    type: INVOICE_LISTS_SUCCESS,
    data,
  };
};

export const getInvoiceListFailed = (failed) => {
  return {
    type: INVOICE_LISTS_FAILED,
    failed,
  };
};

export const getInvoiceListReset = () => {
  return {
    type: INVOICE_LISTS_RESET,
  };
};

export const cancelInvoice = (invoiceNumber) => {
  return {
    type: CANCEL_RECEIPTS,
    invoiceNumber,
  };
};

export const cancelInvoiceSuccess = (data) => {
  return {
    type: CANCEL_RECEIPTS_SUCCESS,
    data,
  };
};

export const cancelInvoiceFailed = (failed) => {
  return {
    type: CANCEL_RECEIPTS_FAILED,
    failed,
  };
};

export const cancelInvoiceReset = () => {
  return {
    type: CANCEL_RECEIPTS_RESET,
  };
};

export const deleteDonor = (deleteData) => {
  return {
    type: DELETE_DONOR,
    deleteData,
  };
};

export const deleteDonorSuccess = (data) => {
  return {
    type: DELETE_DONOR_SUCCESS,
    data,
  };
};

export const deleteDonorFailed = (failed) => {
  return {
    type: DELETE_DONOR_FAILED,
    failed,
  };
};

export const deleteDonorReset = () => {
  return {
    type: DELETE_DONOR_RESET,
  };
};

export const getRawInvoice = (year) => {
  return {
    type: GET_INVOICE_RAW,
    year,
  };
};

export const getRawInvoiceSuccess = (data) => {
  return {
    type: GET_INVOICE_RAW_SUCCESS,
    data,
  };
};

export const getRawInvoiceFailed = (failed) => {
  return {
    type: GET_INVOICE_RAW_FAILED,
    failed,
  };
};

export const getRawInvoiceReset = () => {
  return {
    type: GET_INVOICE_RAW_RESET,
  };
};

export const getSingleInvoice = (invoiceNumber) => {
  return {
    type: GET_INVOICE_NUMBER,
    invoiceNumber,
  };
};

export const getSingleInvoiceSuccess = (data) => {
  return {
    type: GET_INVOICE_NUMBER_SUCCESS,
    data,
  };
};

export const getSingleInvoiceFailed = (failed) => {
  return {
    type: GET_INVOICE_NUMBER_FAILED,
    failed,
  };
};

export const getSingleInvoiceReset = () => {
  return {
    type: GET_INVOICE_NUMBER_RESET,
  };
};

export const getOfflineList = (filename) => {
  return {
    type: GET_OFFLINE_LIST,
    filename,
  };
};

export const getOfflineListSuccess = (data) => {
  return {
    type: GET_OFFLINE_LIST_SUCCESS,
    data,
  };
};

export const getOfflineListFailed = (failed) => {
  return {
    type: GET_OFFLINE_LIST_FAILED,
    failed,
  };
};

export const getOfflineListReset = () => {
  return {
    type: GET_OFFLINE_LIST_RESET,
  };
};

export const getRejectList = () => {
  return {
    type: GET_REJECT_LIST,
  };
};

export const getRejectListSuccess = (data) => {
  return {
    type: GET_REJECT_LIST_SUCCESS,
    data,
  };
};

export const getRejectListFailed = (failed) => {
  return {
    type: GET_REJECT_LIST_FAILED,
    failed,
  };
};

export const getRejectListReset = () => {
  return {
    type: GET_REJECT_LIST_RESET,
  };
};
export const getOneTimeDonorsList = () => {
  return {
    type: GET_ONETIME_DONORS_LIST,
  };
};

export const getOneTimeDonorsListSuccess = (data) => {
  return {
    type: GET_ONETIME_DONORS_LIST_SUCCESS,
    data,
  };
};

export const getOneTimeDonorsListFailed = (failed) => {
  return {
    type: GET_ONETIME_DONORS_LIST_FAILED,
    failed,
  };
};

export const getOneTimeDonorsListReset = () => {
  return {
    type: GET_ONETIME_DONORS_LIST_RESET,
  };
};
