import {
  GET_CACHED_ADDRESS,
  GET_CACHED_ADDRESS_SUCCESS,
  GET_CACHED_ADDRESS_FAILED,
  GET_RAW_ADDRESS,
  GET_RAW_ADDRESS_SUCCESS,
  GET_RAW_ADDRESS_FAILED,
  GET_PINCODE,
  GET_PINCODE_SUCCESS,
  GET_PINCODE_FAILED,
} from "./actionTypes";

const initialState = {
  cached_address: {
    data: [],
    loading: true,
    success: false,
    error: null,
  },
  raw_address: {
    loading: true,
    success: false,
    error: null,
  },
  pincodeList: {
    loading: true,
    success: false,
    error: null,
    data: [],
  },

  raw_state: [],
  raw_district: [],
  raw_taluk: [],
  raw_locality: [],
};

const Locality = (state = initialState, action) => {
  switch (action.type) {
    case GET_CACHED_ADDRESS:
      state = {
        ...state,
        cached_address: {
          data: [],
          loading: true,
          success: false,
          error: false,
        },
      };
      break;
    case GET_CACHED_ADDRESS_SUCCESS:
      state = {
        ...state,
        cached_address: {
          data: action.cachedAddress,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_CACHED_ADDRESS_FAILED:
      state = {
        ...state,
        cached_address: {
          data: [],
          loading: false,
          success: false,
          error: action.error,
        },
      };
      break;
    case GET_RAW_ADDRESS:
      state = {
        ...state,
        raw_address: {
          loading: true,
          success: false,
          error: null,
        },
      };
      break;
    case GET_RAW_ADDRESS_SUCCESS: {
      const { requestType, rawAddress } = action;
      state = {
        ...state,
        raw_address: {
          loading: false,
          success: true,
          error: null,
        },
      };
      state["raw_" + requestType] = rawAddress;
      break;
    }

    case GET_RAW_ADDRESS_FAILED:
      state = {
        ...state,
        raw_address: {
          loading: false,
          success: false,
          error: null,
        },
      };
      break;
    case GET_PINCODE:
      state = {
        ...state,
        pincodeList: {
          loading: true,
          success: false,
          error: null,
          data: [],
        },
      };
      break;
    case GET_PINCODE_SUCCESS: {
      const { pincodeList } = action;
      state = {
        ...state,
        pincodeList: {
          loading: false,
          success: true,
          error: null,
          data: pincodeList,
        },
      };
      break;
    }

    case GET_PINCODE_FAILED:
      state = {
        ...state,
        pincodeList: {
          loading: false,
          success: false,
          error: null,
          data: [],
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Locality;
