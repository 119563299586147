import {
  GET_PREFERENCES,
  PREFERENCES_SUCCESS,
  PREFERENCES_FAILED,
  RESET_PREFERENCES,
  SAVE_PREFERENCES,
  SAVE_PREFERENCES_SUCCESS,
  SAVE_PREFERENCES_FAILED,
  SAVE_RESET_PREFERENCES,
} from "./actionTypes";

export const getPreferences = (filterBy) => {
  return {
    type: GET_PREFERENCES,
    filterBy,
  };
};

export const preferencesSuccess = (data) => {
  return {
    type: PREFERENCES_SUCCESS,
    data,
  };
};

export const preferencesFailed = (failed) => {
  return {
    type: PREFERENCES_FAILED,
    failed,
  };
};

export const preferencesReset = () => {
  return {
    type: RESET_PREFERENCES,
  };
};

export const savePreferences = (ObjToPass) => {
  return {
    type: SAVE_PREFERENCES,
    ObjToPass,
  };
};

export const savePreferencesSuccess = (data) => {
  return {
    type: SAVE_PREFERENCES_SUCCESS,
    data,
  };
};

export const savePreferencesFailed = (failed) => {
  return {
    type: SAVE_PREFERENCES_FAILED,
    failed,
  };
};

export const savePreferencesReset = () => {
  return {
    type: SAVE_RESET_PREFERENCES,
  };
};
