import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { Spinner } from "reactstrap";

const ConfirmModal = ({
  show,
  onOkClick,
  onCloseClick,
  icon,
  title,
  content,
  showSpinner = false,
}) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      data-testid="modal"
    >
      <ModalBody className="py-3 px-5" data-testid="modal-body">
        <div className="mt-2 text-center">
          <i className={icon} style={{ fontSize: "60px" }}></i>
          <div className="pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{`${title}`}</h4>
            <p className="text-muted mx-4 mb-0">{`${content}`}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button
            type="button"
            className="btn w-sm btn-light"
            onClick={onCloseClick}
            data-testid="button-close"
          >
            Close
          </Button>
          <Button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={onOkClick}
            data-testid="button-cancel"
          >
            {showSpinner && (
              <Spinner
                size="sm"
                type="grow"
                className="flex-shrink-0 ms-2"
                role="status"
                data-testid="spinner-cancel"
              ></Spinner>
            )}
            {showSpinner ? "Canceling.. Please wait." : "Yes"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onOkClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  showSpinner: PropTypes.bool.isRequired,
};

export default ConfirmModal;
