import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

const PageRefresh = ({ cb, show = false, timerInMinutes = 1 }) => {
  const [timerId, setTimerId] = useState(0);
  const [intervalCus, setIntervalCus] = useState(0);
  const logicToExecute = () => {
    cb();
  };
  const startTimer = () => {
    setTimerId(setInterval(logicToExecute, timerInMinutes * 60000));
    displayTimer();
  };
  const resetTimer = () => {
    clearInterval(timerId);
    setTimerId(0);
    clearInterval(intervalCus);
    setIntervalCus(0);
  };
  const displayTimer = () => {
    let timerCount = timerInMinutes * 60;
    clearInterval(intervalCus);
    setIntervalCus(
      setInterval(function () {
        setTimerDisplay(convertTimerToDisplayFormatUsingMoment(--timerCount));
      }, 1000),
    );
  };

  const convertTimerToDisplayFormatUsingMoment = (timeInSeconds) => {
    const duration = moment.duration(--timeInSeconds, "seconds");
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());
    return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  };

  const [timerDisplay, setTimerDisplay] = useState(
    convertTimerToDisplayFormatUsingMoment(timerInMinutes * 60),
  );

  useEffect(() => {
    if (show) {
      startTimer();
      resetTimer();
    }
  }, [show]);

  useEffect(() => {
    resetTimer();
  }, []);

  return (
    <>
      {show && (
        <p className="page-refresh mt-xs-3">
          Page auto refresh in {timerDisplay}
          <Link
            to="#"
            className="btn btn-soft-primary ms-2"
            onClick={() => {
              cb();
              resetTimer();
            }}
          >
            Refresh
          </Link>
        </p>
      )}
    </>
  );
};

PageRefresh.propTypes = {
  cb: PropTypes.func.isRequired,
  show: PropTypes.bool,
  timerInMinutes: PropTypes.number,
};
export default PageRefresh;
