import {
  GET_NOTES,
  NOTES_SUCCESS,
  NOTES_FAILED,
  NOTES_RESET,
  SAVE_NOTES,
  SAVE_NOTES_SUCCESS,
  SAVE_NOTES_FAILED,
  SAVE_NOTES_RESET,
  GET_SINGLE_NOTES,
  GET_SINGLE_NOTES_SUCCESS,
  GET_SINGLE_NOTES_FAILED,
  GET_SINGLE_NOTES_RESET,
  UPDATE_NOTES,
  UPDATE_NOTES_SUCCESS,
  UPDATE_NOTES_FAILED,
  UPDATE_NOTES_RESET,
} from "./actionTypes";

const initialState = {
  notes: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  saveNotes: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  singleNote: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  Note: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
  updateNote: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
};

const Notes = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTES:
      state = {
        ...state,
        notes: {
          loading: true,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case NOTES_SUCCESS:
      state = {
        ...state,
        notes: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case NOTES_FAILED:
      state = {
        ...state,
        notes: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case NOTES_RESET:
      state = {
        ...state,
        notes: {
          loading: false,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case SAVE_NOTES:
      state = {
        ...state,
        saveNotes: {
          loading: true,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case SAVE_NOTES_SUCCESS:
      state = {
        ...state,
        saveNotes: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case SAVE_NOTES_FAILED:
      state = {
        ...state,
        saveNotes: {
          loading: false,
          success: false,
          error: true,
          data: action?.data,
        },
      };
      break;
    case SAVE_NOTES_RESET:
      state = {
        ...state,
        saveNotes: {
          loading: false,
          success: false,
          error: false,
          data: {},
        },
      };
      break;
    case GET_SINGLE_NOTES:
      state = {
        ...state,
        singleNote: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case GET_SINGLE_NOTES_SUCCESS:
      state = {
        ...state,
        singleNote: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case GET_SINGLE_NOTES_FAILED:
      state = {
        ...state,
        singleNote: {
          loading: false,
          success: false,
          error: true,
          data: action.failed,
        },
      };
      break;
    case GET_SINGLE_NOTES_RESET:
      state = {
        ...state,
        singleNote: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case UPDATE_NOTES:
      state = {
        ...state,
        updateNote: {
          loading: true,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    case UPDATE_NOTES_SUCCESS:
      state = {
        ...state,
        updateNote: {
          data: action.data,
          loading: false,
          success: true,
          error: false,
        },
      };
      break;
    case UPDATE_NOTES_FAILED:
      state = {
        ...state,
        updateNote: {
          loading: false,
          success: false,
          error: true,
          data: action?.data,
        },
      };
      break;
    case UPDATE_NOTES_RESET:
      state = {
        ...state,
        updateNote: {
          loading: false,
          success: false,
          error: false,
          data: [],
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Notes;
